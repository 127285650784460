import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import axios from "../../../../../../../../api/axios";
import { useDebouncedEffect } from "../../../../../../../../hooks/useDebounceEffect";
import ShowMoreButton from "../../../../../../../../global/ShowMoreButton";

export default function AutoCompleteSearch({
  apiUrl,
  keyVal,
  title,
  setVal,
  activeEditObj,
  customLimit,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useDebouncedEffect(
    () => {
      if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
        axios
          .get(
            apiUrl +
              `&limit=${!customLimit ? resLimit : customLimit}` +
              `${search ? `&search=${search}` : ""}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(response.data.results);
            if (
              response.data.count <= resLimit ||
              response.data.count <= customLimit
            ) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
          });
      } else {
        return;
      }
    },
    [userRoles.permissions, accessToken, setOptions, search, resLimit],
    250
  );

  return (
    <Autocomplete
      className="w-full"
      disablePortal
      freeSolo
      size="small"
      clearOnBlur={true}
      options={!options ? [] : options}
      getOptionLabel={(option) => option.label}
      noOptionsText="No Results"
      onChange={(e, value) => {
        setVal(value, keyVal);
        setSearch("");
      }}
      defaultValue={activeEditObj}
      renderOption={(props, option) => {
        return (
          <li {...props} id={option.label} key={option.label}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <div className="flex items-center">
          <TextField
            {...params}
            label={title}
            onChange={(e) => setSearch(e.target.value)}
          />
          <ShowMoreButton
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            showMoreAmt={10}
          />
        </div>
      )}
    />
  );
}
