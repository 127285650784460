import { createContext, useState } from "react";
import Cookies from "js-cookie";
const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [accessToken, getAccessToken] = useState(
    !Cookies.get("pnAccessToken")
      ? null
      : JSON.parse(Cookies.get("pnAccessToken"))
  );
  const [isLoading, setLoading] = useState(true);

  const [loggedInUser, setLoggedInUser] = useState();

  const [caseManagerPk, setCaseManagerPk] = useState(
    !Cookies.get("pnCmPk") ? null : JSON.parse(Cookies.get("pnCmPk"))
  );
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  //All used for the map/assign provider page
  const [selectedProvider, setSelectedProvider] = useState("");
  const [activeProvider, setActiveProvider] = useState();
  const [activeClientMap, setActiveClientMap] = useState(null);
  const [altAddress, getAltAddress] = useState(null);
  const [clientCoords, getClientCoords] = useState();
  const [nearestProviders, getNearestProviders] = useState();
  const [newNonNetProv, getNewNonNetProv] = useState();
  //End of  assign prov context

  const [trigger, setTrigger] = useState(false);
  const [activeFilterButton, setActiveFilterButton] = useState("");
  const [casePk, getCasePk] = useState();
  const [caseManagerName, getCaseManagerName] = useState();
  const [searchProvPk, setSearchProvPk] = useState("");
  const [activeBills, setActiveBills] = useState();
  const [lostCodes, setLostCodes] = useState([]);
  const [activePath, setActivePath] = useState("search");
  const [q4LawfirmPath, setQ4LawfirmPath] = useState("liabilityRequested");
  const [tabValue, setTabValue] = useState(0);
  const [prevCL, setPrevCL] = useState([]);
  const [activeLawfirm, setActiveLawfirm] = useState("");
  const [lawFirmLink, setLawFirmLink] = useState("");
  const [userType, setUserType] = useState("");
  const [patchNotif, setPatchNotifs] = useState();
  const [refreshDialog, setRefreshDialog] = useState(false);
  const [currentPatchV, setCurrentPatchV] = useState();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [selectedLead, setSelectedLead] = useState("");
  const [caseCountPk, setCaseCountPk] = useState("");
  //below state used for action items routing for admins
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLawFirms, setSelectedLawFirms] = useState([]);
  const [activeLawFirms, setActiveLawFirms] = useState([]);
  const [selectedCases, setSelectedCases] = useState([]);
  const [activeCases, setActiveCases] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [activeProviders, setActiveProviders] = useState([]);
  const [userRoles, setUserRoles] = useState("");
  const [userPaths, setUserPaths] = useState([]);
  const [dashCompLimit, setDashCompLimit] = useState(5);
  const [errorMessage, setErrorMessage] = useState("");
  const [languageObj, setLanguageObj] = useState("");

  const [dashboardV, setDashboardV] = useState(
    !Cookies.get("dashVersion") ? "new" : Cookies.get("dashVersion")
  );

  return (
    <DataContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        isLoggedIn,
        setIsLoggedIn,
        selectedProvider,
        setSelectedProvider,
        caseManagerPk,
        setCaseManagerPk,
        accessToken,
        getAccessToken,
        activeClientMap,
        setActiveClientMap,
        clientCoords,
        getClientCoords,
        activeProvider,
        setActiveProvider,
        nearestProviders,
        getNearestProviders,
        newNonNetProv,
        getNewNonNetProv,
        isLoading,
        setLoading,
        altAddress,
        getAltAddress,
        trigger,
        setTrigger,
        activeFilterButton,
        setActiveFilterButton,
        casePk,
        getCasePk,
        searchProvPk,
        setSearchProvPk,
        caseManagerName,
        getCaseManagerName,
        lostCodes,
        setLostCodes,
        activeBills,
        setActiveBills,
        activePath,
        setActivePath,
        q4LawfirmPath,
        setQ4LawfirmPath,
        tabValue,
        setTabValue,
        prevCL,
        setPrevCL,
        activeLawfirm,
        setActiveLawfirm,
        lawFirmLink,
        setLawFirmLink,
        userType,
        setUserType,
        patchNotif,
        setPatchNotifs,
        refreshDialog,
        setRefreshDialog,
        currentPatchV,
        setCurrentPatchV,
        needsUpdate,
        setNeedsUpdate,
        dashboardV,
        setDashboardV,
        selectedLead,
        setSelectedLead,
        caseCountPk,
        setCaseCountPk,
        activeUsers,
        setActiveUsers,
        selectedUsers,
        setSelectedUsers,
        selectedLawFirms,
        setSelectedLawFirms,
        activeLawFirms,
        setActiveLawFirms,
        selectedCases,
        setSelectedCases,
        activeCases,
        setActiveCases,
        selectedProviders,
        setSelectedProviders,
        activeProviders,
        setActiveProviders,
        userRoles,
        setUserRoles,
        userPaths,
        setUserPaths,
        dashCompLimit,
        setDashCompLimit,
        errorMessage,
        setErrorMessage,
        loggedInUser,
        setLoggedInUser,
        languageObj,
        setLanguageObj,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
