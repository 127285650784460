import {
  AnalyticsOutlined,
  ArrowForwardIos,
  AspectRatio,
  ControlPoint,
} from "@mui/icons-material";
import {
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import qs from "query-string";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import { CustomChip } from "../../../../global/CustomQueryChip";
import DateRange from "../../../../global/DateRange";
import MultiSelectSearch from "../../../../global/MultiSelectSearch";
import OrderingArrow from "../../../../global/OrderingArrow";
import PaginationControls from "../../../../global/PaginationControls";
import ActionItemAdd from "./Modals/ActionItemAdd";
import ResponseModal from "../../../../global/ResponseModal";
import ActionItemDelete from "./Modals/ActionItemDelete";
import ActionItemDetail from "./Modals/ActionItemDetail";
import ActionListItem from "./ActionListItem";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";

export default function ActionItems({ isDashboardVersion }) {
  const {
    accessToken,
    setSelectedClient,
    activeUsers,
    setActiveUsers,
    selectedUsers,
    setSelectedUsers,
    selectedLawFirms,
    setSelectedLawFirms,
    activeLawFirms,
    setActiveLawFirms,
    selectedCases,
    setSelectedCases,
    activeCases,
    setActiveCases,
    selectedProviders,
    setSelectedProviders,
    activeProviders,
    setActiveProviders,
    userRoles,
    dashboardV,
  } = useContext(DataContext);

  const [actItems, setActItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
  const [activeFilters, setActiveFilters] = useState();
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [search, setSearch] = useState("");
  const [orderingVal, setOrderingVal] = useState("-created_at");

  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [activeCreatedBy, setActiveCreatedBy] = useState([]);

  const [dateCreatedTo, setDateCreatedTo] = useState();
  const [dateCreatedFrom, setDateCreatedFrom] = useState();
  const [dateDueTo, setDateDueTo] = useState();
  const [dateDueFrom, setDateDueFrom] = useState();

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [activeActionItem, setActiveActionItem] = useState(null);
  const [actionStatusOptions, setActionStatusOptions] = useState([]);
  const [actionStatus, setActionStatus] = useState("pending");

  useEffect(() => {
    axios
      .get(`/api/field_options/?content_type=actionableitems`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        setActionStatusOptions(data);
      })
      .catch((e) => {
        console.log("Action Items Status Options Error");
      });
  }, [accessToken, setActionStatusOptions]);

  const navigate = useNavigate();

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setAddOpen(false);
    setTrigger(!trigger);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const navigateToDetail = (client) => {
    setSelectedClient(client.pk);
    navigate(`/client-detail/${client.pk}`);
  };

  const filterBgColor = (val) => {
    if (val === "status" && actionStatus === "pending") {
      return "bg-orange-500 text-white";
    } else if (val === "status" && actionStatus === "done") {
      return "bg-green-600 text-white";
    } else {
      return "hidden";
    }
  };

  const clearFilter = (val) => {
    if (val === "search") {
      setSearch("");
    } else if (val === "created_at__lte") {
      setDateCreatedTo(null);
    } else if (val === "created_at__gte") {
      setDateCreatedFrom(null);
    } else if (val === "due_date__lte") {
      setDateDueTo(null);
    } else if (val === "due_date__gte") {
      setDateDueFrom(null);
    } else if (val === "status") {
      setActionStatus(null);
    } else {
      return;
    }
  };

  const actionItemSearch = () => {
    let query = qs.stringify(
      {
        search: !search ? null : search,
        created_at__gte: !dateCreatedFrom
          ? null
          : new Date(dateCreatedFrom).toLocaleDateString("en-CA"),
        created_at__lte: !dateCreatedTo
          ? null
          : new Date(dateCreatedTo).toLocaleDateString("en-CA"),
        due_date__gte: !dateDueFrom
          ? null
          : new Date(dateDueFrom).toLocaleDateString("en-CA"),
        due_date__lte: !dateDueTo
          ? null
          : new Date(dateDueTo).toLocaleDateString("en-CA"),
        status: actionStatus === "All" ? null : actionStatus,
      },
      {
        skipNull: true,
      }
    );

    const assignedUserQuery =
      selectedUsers.length === 0 ? "" : `&acting_user__in=${selectedUsers}`;
    const createdByQuery =
      selectedCreatedBy.length === 0
        ? ""
        : `&created_by__in=${selectedCreatedBy}`;
    const lawFirmQuery =
      selectedLawFirms.length === 0 ? "" : `&on_firm__in=${selectedLawFirms}`;

    const caseQuery =
      selectedCases.length === 0 ? "" : `&on_case__in=${selectedCases}`;

    const providerQuery =
      selectedProviders.length === 0
        ? ""
        : `&on_provider__in=${selectedProviders}`;

    axios
      .get(
        `/api/actionable_items/?ordering=${orderingVal}&` +
          paginationUrl +
          query +
          assignedUserQuery +
          lawFirmQuery +
          caseQuery +
          providerQuery +
          createdByQuery,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        const data = response.data.results;
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
        setActItems(data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
    query = qs.parse(query);
    setActiveFilters(Object.keys(query));
  };

  useDebouncedEffect(
    () => actionItemSearch(),
    [
      search,
      paginationUrl,
      orderingVal,
      selectedUsers,
      selectedCreatedBy,
      selectedLawFirms,
      selectedCases,
      selectedProviders,
      dateCreatedFrom,
      dateCreatedTo,
      dateDueFrom,
      dateDueTo,
      actionStatus,
      trigger,
    ],
    250
  );

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const handleEditAction = (activeCase) => {
    setEditOpen(true);
    setActiveActionItem(activeCase);
  };
  const handleDeleteAction = (activeCase) => {
    setDeleteOpen(true);
    setActiveActionItem(activeCase);
  };
  const handleDetailAction = (activeCase) => {
    setDetailOpen(true);
    setActiveActionItem(activeCase);
  };
  const completeAction = (actionId, status) => {
    let patchData = {
      status: status === "done" ? "pending" : "done",
    };
    axios
      .patch(`/api/actionable_items/${actionId}/`, patchData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setTrigger(!trigger);
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
      });
  };

  const handleActionStatusUpdate = (e) => {
    setActionStatus(e.target.value);
    resetPagination();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`pr-4 h-full overflow-hidden flex w-full`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div
          className={`shadow-lg text-center 
              ${
                isDashboardVersion
                  ? toggleFilterMenu
                    ? "w-fit absolute bg-white z-10 overflow-y-auto max-h-[60vh] md:max-h-[80vh]"
                    : "w-[0px] hidden"
                  : !toggleFilterMenu
                  ? "lg:w-[30%] lg:relative w-fit absolute bg-white z-10 overflow-y-auto h-full"
                  : "w-[0px] hidden"
              }`}
        >
          {isDashboardVersion ? (
            ""
          ) : (
            <h1
              className={`text-xl text-purple-600 text-center mt-4 mb-0 font-bold`}
            >
              Filters
            </h1>
          )}
          <div className="font-bold text-lg pt-[16px] pb-[12px] px-4 flex lg:justify-between justify-end">
            {!toggleFilterMenu || isDashboardVersion ? (
              <Tooltip
                title={`${
                  toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                }`}
              >
                <IconButton
                  onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
                  className={`${toggleFilterMenu ? "" : "rotate-180"}`}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <ListItem>
            <FormControl className="justify-center p-[16px] text-sm w-full">
              <InputLabel className="mt-4 ml-4 font-normal">
                Action Item Status
              </InputLabel>
              <Select
                size="small"
                labelId="case-status"
                id="Filter"
                value={actionStatus}
                label="Case Status"
                onChange={(e) => handleActionStatusUpdate(e)}
                className="text-sm p-2 capitalize"
              >
                <MenuItem value="All" className="text-sm">
                  All
                </MenuItem>
                {actionStatusOptions.map((opt, idx) => (
                  <MenuItem
                    value={opt.key}
                    className="text-sm"
                    key={idx}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <MultiSelectSearch
              title="Sort By Acting Users"
              searchTitle="User Search"
              selectedItems={selectedUsers}
              setSelectedItems={setSelectedUsers}
              setActiveItems={setActiveUsers}
              activeItems={activeUsers}
              keyName="name"
              resetPagination={resetPagination}
              apiUrl={`/api/users/all_assignable_users/?`}
            />
          </ListItem>
          <ListItem>
            <MultiSelectSearch
              title="Sort Created By"
              searchTitle="User Search"
              selectedItems={selectedCreatedBy}
              setSelectedItems={setSelectedCreatedBy}
              setActiveItems={setActiveCreatedBy}
              activeItems={activeCreatedBy}
              keyName="name"
              resetPagination={resetPagination}
              apiUrl={`/api/users/all_assignable_users/?`}
            />
          </ListItem>
          {userRoles.permissions.includes("lawyer.view_lawyer") ? (
            <ListItem>
              <MultiSelectSearch
                title="Sort by Law Firm"
                searchTitle="Law Firm Search"
                selectedItems={selectedLawFirms}
                setSelectedItems={setSelectedLawFirms}
                setActiveItems={setActiveLawFirms}
                activeItems={activeLawFirms}
                keyName="name"
                resetPagination={resetPagination}
                apiUrl="/api/lawfirm/?"
              />
            </ListItem>
          ) : (
            ""
          )}
          {userRoles.permissions.includes("casemanager.view_case") ? (
            <ListItem>
              <MultiSelectSearch
                title="Sort by Cases"
                searchTitle="Case Search"
                selectedItems={selectedCases}
                setSelectedItems={setSelectedCases}
                setActiveItems={setActiveCases}
                activeItems={activeCases}
                resetPagination={resetPagination}
                apiUrl="/api/case/?"
              />
            </ListItem>
          ) : (
            ""
          )}
          {userRoles.permissions.includes("provider.view_provider") ? (
            <ListItem>
              <MultiSelectSearch
                title="Sort by Providers"
                searchTitle="Provider Search"
                selectedItems={selectedProviders}
                setSelectedItems={setSelectedProviders}
                setActiveItems={setActiveProviders}
                activeItems={activeProviders}
                resetPagination={resetPagination}
                apiUrl="/api/provider/?"
              />
            </ListItem>
          ) : (
            ""
          )}
          <ListItem>
            <DateRange
              val="Created At Range"
              dateFrom={dateCreatedFrom}
              dateTo={dateCreatedTo}
              setDateFrom={setDateCreatedFrom}
              setDateTo={setDateCreatedTo}
              resetPagination={resetPagination}
            />
          </ListItem>
          <ListItem>
            <DateRange
              val="Due Date Range"
              dateFrom={dateDueFrom}
              dateTo={dateDueTo}
              setDateFrom={setDateDueFrom}
              setDateTo={setDateDueTo}
              resetPagination={resetPagination}
            />
          </ListItem>
        </div>
        <div className={`w-full md:ml-4`}>
          {isDashboardVersion ? (
            ""
          ) : (
            <h1
              className={`text-xl text-purple-600 text-center my-4 font-bold`}
            >
              Action Items
            </h1>
          )}
          {isDashboardVersion ? (
            <Tooltip title="Go to full screen">
              <IconButton
                className="text-sky-600 float-right"
                onClick={() => navigate(`/action-items/`)}
              >
                <AspectRatio />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
          <div className="flex justify-center mt-4">
            <Tooltip
              title={`${
                toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
              }`}
            >
              <IconButton
                onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
                className={`${toggleFilterMenu ? "" : "rotate-180"} ${
                  !isDashboardVersion && !toggleFilterMenu ? "hidden" : ""
                }`}
              >
                <ArrowForwardIos />
              </IconButton>
            </Tooltip>
            <Tooltip title="Search by Acting User Name">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Tooltip>
          </div>
          <div className="mt-4">
            {" "}
            <CustomChip
              setActiveItems={setActiveUsers}
              activeItems={activeUsers}
              selectedItems={selectedUsers}
              setSelectedItems={setSelectedUsers}
              title="Acting Users"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveCreatedBy}
              activeItems={activeCreatedBy}
              selectedItems={selectedCreatedBy}
              setSelectedItems={setSelectedCreatedBy}
              title="Created By"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveLawFirms}
              activeItems={activeLawFirms}
              selectedItems={selectedLawFirms}
              setSelectedItems={setSelectedLawFirms}
              title="Law Firms"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveCases}
              activeItems={activeCases}
              selectedItems={selectedCases}
              setSelectedItems={setSelectedCases}
              title="Cases"
              resetPagination={resetPagination}
            />
            <CustomChip
              setActiveItems={setActiveProviders}
              activeItems={activeProviders}
              selectedItems={selectedProviders}
              setSelectedItems={setSelectedProviders}
              title="Providers"
              resetPagination={resetPagination}
            />
            {!activeFilters
              ? ""
              : activeFilters.map((filt, idx) => (
                  <Tooltip title="Clear Filter" key={filt + idx}>
                    <Chip
                      className={`px-2 m-1 text-center rounded-lg shadow-inner hover:bg-red-400 capitalize ${filterBgColor(
                        filt
                      )}`}
                      onClick={() => clearFilter(filt)}
                      label={
                        filt === "search"
                          ? `Search: ${search}`
                          : filt === "created_at__lte"
                          ? `Created Before: ${
                              !dateCreatedTo
                                ? ""
                                : new Date(dateCreatedTo).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : filt === "created_at__gte"
                          ? `Created After: ${
                              !dateCreatedFrom
                                ? ""
                                : new Date(dateCreatedFrom).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : filt === "due_date__lte"
                          ? `Due Before: ${
                              !dateDueTo
                                ? ""
                                : new Date(dateDueTo).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : filt === "due_date__gte"
                          ? `Due After: ${
                              !dateDueFrom
                                ? ""
                                : new Date(dateDueFrom).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : filt === "status"
                          ? `Action Status: ${actionStatus}`
                          : ""
                      }
                    />
                  </Tooltip>
                ))}
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex justify-between items-center my-2">
              <p className="pl-5">
                Results: {resultCount.current} / {resultCount.total}
              </p>
              <div className=" pr-5">
                {userRoles.permissions.includes(
                  "casemanager.add_actionableitems"
                ) ? (
                  <Tooltip title="Add Action Item">
                    <IconButton
                      className="text-green-600"
                      onClick={() => {
                        setActiveActionItem(null);
                        setAddOpen(true);
                      }}
                    >
                      <ControlPoint />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
                <Tooltip title="View Action Item Counts">
                  <IconButton
                    className="text-purple-600"
                    onClick={() => navigate(`/action-item-counts`)}
                  >
                    <AnalyticsOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </div>{" "}
            <div className="flex px-0 text-center py-2 border-b border-1 border-gray">
              <div className="md:w-1/5 w-1/3 font-bold">
                <h2 className="text-sm">CLIENT</h2>
              </div>
              <div className="md:w-1/5 w-1/3 md:block hidden font-bold">
                <h2 className="text-sm">ACTING USER</h2>
              </div>
              <div className="w-[15%] md:block hidden font-bold">
                <h2 className="text-sm">
                  DUE DATE{" "}
                  <OrderingArrow
                    val={"due_date"}
                    orderingVal={orderingVal}
                    setOrderingVal={setOrderingVal}
                  />
                </h2>
              </div>
              <div className="w-[30%] block font-bold">
                <h2 className="text-sm">ACTION </h2>
              </div>
              <div className="md:w-[12%] w-1/3 font-bold">
                <h2 className="text-sm">
                  STATUS{" "}
                  <OrderingArrow
                    val={"status"}
                    orderingVal={orderingVal}
                    setOrderingVal={setOrderingVal}
                  />
                </h2>
              </div>
              <div className="w-[10%] md:block hidden font-bold">&nbsp;</div>
            </div>
            <List
              className={`${
                isDashboardVersion && dashboardV === "legacy"
                  ? `max-h-[30vh]`
                  : "max-h-[75vh]"
              } overflow-y-auto`}
            >
              {loading === true ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {actItems.map((action, index) => (
                    <ActionListItem
                      key={index}
                      handleDeleteAction={handleDeleteAction}
                      handleDetailAction={handleDetailAction}
                      handleEditAction={handleEditAction}
                      action={action}
                      completeAction={completeAction}
                      truncate={truncate}
                      resultClasses={resultClasses}
                      navigateToDetail={navigateToDetail}
                      isDashboardVersion={isDashboardVersion}
                    />
                  ))}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
        {!addOpen ? (
          ""
        ) : (
          <ActionItemAdd
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            activeActionItem={activeActionItem}
            setActiveActionItem={setActiveActionItem}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!editOpen ? (
          ""
        ) : (
          <ActionItemAdd
            addOpen={editOpen}
            setAddOpen={setEditOpen}
            activeActionItem={activeActionItem}
            setActiveActionItem={setActiveActionItem}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!deleteOpen ? (
          ""
        ) : (
          <ActionItemDelete
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            setActiveActionItem={setActiveActionItem}
            activeActionItem={activeActionItem}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!detailOpen ? (
          ""
        ) : (
          <ActionItemDetail
            detailOpen={detailOpen}
            setDetailOpen={setDetailOpen}
            setActiveActionItem={setActiveActionItem}
            activeActionItem={activeActionItem}
            navigateToDetail={navigateToDetail}
          />
        )}
        <ResponseModal
          title={"Action Item Status Update"}
          description={"Action Item status has been succesfully updated"}
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title={"Error updating Action Item Status"}
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
