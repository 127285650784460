import { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";

export default function DeleteApptProcedure({
  selectedBill,
  setOpenDeleteBill,
}) {
  const { accessToken, setTrigger, trigger } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenDeleteBill(false);
    setTrigger(!trigger);
  };

  const handleApptProcedureDelete = () => {
    axios
      .delete(`/api/administered/${selectedBill.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.detail) {
      arrayVals.push(`detail: ${errorArray.detail}`);
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  return (
    <div className="w-full px-20 flex-vertical justify-center bg-white">
      {!selectedBill ? (
        "Please Select an Procedure to Continue"
      ) : (
        <div>
          <div className="grid grid-cols-2 bg-gray-200 mt-4 rounded-md">
            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Appointment Info
              </span>
              <div className="grid grid-cols-2 mt-4">
                <span style={{ fontWeight: "bold" }}>Provider Name:</span>{" "}
                <span>{selectedBill.administered_by.name}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Provider Phone:</span>{" "}
                <span>{selectedBill.administered_by.phone}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Date/Time:</span>{" "}
                <span>
                  {new Date(
                    selectedBill.appointment.scheduled
                  ).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Client Info
              </span>
              <div className="grid grid-cols-2 mt-4">
                <span style={{ fontWeight: "bold" }}>Name: </span>
                <span>{selectedBill.administered_to.name}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Email:</span>
                <span>{selectedBill.administered_to.email}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Phone:</span>
                <span>{selectedBill.administered_to.phone}</span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <span
              className="underline text-lg font-bold"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                margin: "10px 0",
                display: "block",
              }}
            >
              Bill Details
            </span>
            <div className="grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Code:</span>{" "}
              <span>
                {!selectedBill.service
                  ? "No Service"
                  : selectedBill.service.service_code}
              </span>
            </div>
            <div className="grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Name:</span>{" "}
              <span>
                {!selectedBill.service
                  ? "No Service"
                  : selectedBill.service.service_name}
              </span>{" "}
            </div>
            <div className="grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Price:</span>{" "}
              <span>${selectedBill.price}</span>{" "}
            </div>
            <div className="grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>
                Bill Administered By:
              </span>{" "}
              <span>{selectedBill.signature}</span>{" "}
            </div>
          </div>
        </div>
      )}
      <br />
      <h2 className="font-bold text-lg">
        <span
          className="text-xl text-red-800 underline"
          style={{ color: "red", fontWeight: "bold" }}
        >
          Warning:
        </span>{" "}
        Clicking the "Permanently Delete Bill" button below will permanently
        remove the billed data above from the database, after proceeding it{" "}
        <span className="text-lg underline">cannot</span> be recovered. Please
        make sure you want to delete the data before proceeding.
      </h2>
      <br />

      <ModalButton
        action={handleApptProcedureDelete}
        baseColor={"red"}
        content={"Permanently Delete Bill"}
        icon={"delete"}
      />
      <ResponseModal
        title="Bill Data"
        description="Successfully deleted the bill from client's data."
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Billed Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
