import React from "react";
import { TextField } from "@mui/material";

export default function SearchAutoSubmit({ onchange, placeholder }) {
  return (
    <div className="relative w-full">
      <div className="flex items-center mt-3 mx-3">
        <TextField
          className="placeholder:text-center rounded-lg min-w-[300px] text-sm"
          placeholder={placeholder}
          size="small"
          name="search"
          //value={value}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => onchange(event.target.value)}
        />
      </div>
    </div>
  );
}
