import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ProfileInfo from "./ProfileInfo";
import AccountSettings from "./AccountSettings";
import OrgPermissions from "./OrgPermissions";
import LoadingIndicator from "../../../../global/LoadingIndicator";

export default function MyAccount() {
  const { accessToken } = useContext(DataContext);
  const [trigger, setTrigger] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/users/me/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data;
        setUserInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage("There was an error loading this content");
      });
  }, [accessToken, setUserInfo, trigger]);

  return (
    <div className="h-full">
      {loading ? (
        <LoadingIndicator isLoading={loading} />
      ) : !userInfo && errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <div className="w-full relative h-full">
          <div className="xl:flex w-full h-full ">
            <ProfileInfo
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              myAccount={true}
            />
            <div className="xl:w-3/4 xl:h-full overflow-y-auto">
              <AccountSettings
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                myAccount={true}
              />
              <OrgPermissions
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                myAccount={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
