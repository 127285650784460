import { Button, ListItem } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/DataContext";

export default function ClientListItemUniversal({
  client,
  index,
  currentPage,
  isDashboardVersion,
  customBgLayer,
}) {
  const { userType } = useContext(DataContext);
  const { setSelectedClient } = useContext(DataContext);

  const navigate = useNavigate();
  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0";
  const columnClasses =
    userType !== "Law Firm" ? "md:w-1/6 w-1/3" : "md:w-1/5 w-1/2";
  const columnClassesSmall =
    userType !== "Law Firm" ? "md:w-[15%] w-1/4" : "md:w-[20%] w-1/3";
  const columnClassesLg =
    userType !== "Law Firm" ? "md:w-1/5 w-1/3" : "md:w-1/4 w-1/2";

  const navigateToDetail = (client) => {
    setSelectedClient(client.pk);
    navigate(`/client-detail/${client.pk}`);
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  return (
    <ListItem
      key={index * Math.random}
      value={client}
      className={`py-0 pt-0 items-start`}
    >
      <Button
        onClick={() => navigateToDetail(client.client)}
        className={`text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between ${
          customBgLayer ? customBgLayer : ""
        }`}
      >
        <span
          className={`block ${columnClassesLg} text-left pl-2 text-left ${resultClasses}`}
        >
          {currentPage > 1
            ? index + 1 * ((currentPage - 1) * 25) + 1
            : index + 1}
          .&nbsp;
          {!client ? "" : client.client.name}
        </span>
        <span
          className={`block ${columnClasses} text-left pl-2 text-center ${resultClasses} hidden lg:block`}
        >
          {!client ? "" : `${client.scheduled_appts}/${client.appointments_ct}`}
        </span>
        <span
          className={`block ${columnClassesSmall} text-left pl-2 text-center hidden lg:block ${resultClasses}`}
        >
          {!client.created_at
            ? ""
            : new Date(client.created_at).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
        </span>
        <span
          className={`block ${columnClassesSmall} text-left pl-2 text-center ${resultClasses}`}
        >
          {!client.date_of_accident
            ? ""
            : new Date(client.date_of_accident).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
        </span>
        <span
          className={`block ${columnClassesSmall} text-left pl-2 text-center break-all	 ${resultClasses} ${
            userType !== "Law Firm" ? "border-none" : ""
          }`}
        >
          {!client
            ? ""
            : !client.status
            ? ""
            : isDashboardVersion
            ? truncate(client.status, 0, 7)
            : client.status}
        </span>
        {userType !== "Law Firm" ? (
          <span
            className={`block w-1/5 text-left pl-2 text-center hidden md:block ${resultClasses} border-none text-sm break-all`}
          >
            {!client ? "" : client.law_firm_name}
          </span>
        ) : (
          ""
        )}
      </Button>
    </ListItem>
  );
}
