import {
  Delete,
  Edit,
  Image,
  InsertDriveFile,
  Preview,
} from "@mui/icons-material";
import { IconButton, ListItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import DeleteDocument from "./Modals/DeleteDocument";
import ApprovalChecklist from "./ApprovalChecklist";
import DataContext from "../../../../../../../../context/DataContext";
import DocumentUpdateListItem from "./DocumentUpdateListItem";

export default function DocumentListItem({
  document,
  documentTypeObj,
  setViewDocument,
  setActiveDoc,
  editDoc,
  truncate,
  docTrigger,
  setDocTrigger,
  docTypeOptions,
  docValidation,
}) {
  const { userRoles } = useContext(DataContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editType, setEditType] = useState(false);

  function getExtension(filename) {
    if (filename.includes(".")) {
      return filename.split(".").pop();
    } else {
      return "";
    }
  }
  const photoExtensions = ["png", "jpg", "jpeg"];

  const handleOpenDocument = (document) => {
    setViewDocument(true);
    setActiveDoc(document);
  };

  return (
    <>
      {editType ? (
        <DocumentUpdateListItem
          document={document}
          documentTypeObj={documentTypeObj}
          setActiveDoc={setActiveDoc}
          setViewDocument={setViewDocument}
          editDoc={editDoc}
          docTypeOptions={docTypeOptions}
          docValidation={docValidation}
          truncate={truncate}
          docTrigger={docTrigger}
          setDocTrigger={setDocTrigger}
          editType={editType}
          setEditType={setEditType}
        />
      ) : (
        <ListItem style={{ padding: "0 14px" }}>
          <div
            style={{
              borderBottom: "1px solid #EBEBEB",
              padding: "5px 0",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "75%" }}
            >
              <div style={{ alignSelf: "center" }}>
                <Tooltip title={document.file_name}>
                  <span>
                    {" "}
                    {photoExtensions.includes(
                      getExtension(document.file_name)
                    ) ? (
                      <Image />
                    ) : (
                      <InsertDriveFile />
                    )}
                  </span>
                </Tooltip>
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  alignSelf: "center",
                  width: "25%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={
                    photoExtensions.includes(getExtension(document.file_name))
                      ? "View Image"
                      : "View Document"
                  }
                >
                  <IconButton onClick={() => handleOpenDocument(document)}>
                    <Preview style={{ color: "#1976d2" }} />
                  </IconButton>
                </Tooltip>
                {!documentTypeObj
                  ? ""
                  : documentTypeObj[document.document_type]}{" "}
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  alignSelf: "center",
                  width: "15%",
                }}
              >
                <Tooltip title={new Date(document.created_at).toLocaleString()}>
                  Created:{new Date(document.created_at).toLocaleDateString()}
                </Tooltip>
                <br />
                <Tooltip title={new Date(document.updated_at).toLocaleString()}>
                  Updated: {new Date(document.updated_at).toLocaleDateString()}
                </Tooltip>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  alignSelf: "center",
                  width: "25%",
                }}
              >
                <strong>Created by:</strong>{" "}
                {!document.created_by_name
                  ? "Unknown"
                  : document.created_by_name}
                <br />
                <strong>Updated by:</strong> {document.updated_by_name}
              </div>
              {document.referral_to ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    alignSelf: "center",
                    width: "25%",
                  }}
                >
                  <strong>To:</strong> {document.referral_to_name}
                  <br />
                  <strong>From:</strong> {document.referral_from_name}
                </div>
              ) : document.provider ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    alignSelf: "center",
                    width: "25%",
                  }}
                >
                  {document.provider_name}
                </div>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", width: "25%" }}>
              {userRoles.permissions.includes(
                "filemanager.view_documentapproval"
              ) ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    alignSelf: "center",
                    width: "75%",
                  }}
                >
                  <ApprovalChecklist
                    document={document}
                    docTrigger={docTrigger}
                    setDocTrigger={setDocTrigger}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  alignSelf: "center",
                  width: "auto",
                  display: "flex",
                }}
              >
                {userRoles.permissions.includes(
                  "filemanager.delete_document"
                ) ? (
                  <Tooltip placement="right" title={"Delete"}>
                    <span>
                      <IconButton onClick={() => setOpenDeleteModal(true)}>
                        <Delete style={{ color: "red" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                {userRoles.permissions.includes(
                  "filemanager.change_document"
                ) ? (
                  <IconButton onClick={() => setEditType(true)}>
                    <Edit />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {!openDeleteModal ? (
            ""
          ) : (
            <DeleteDocument
              document={document}
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              getExtension={getExtension}
              docTrigger={docTrigger}
              setDocTrigger={setDocTrigger}
              documentTypeObj={documentTypeObj}
            />
          )}
        </ListItem>
      )}
    </>
  );
}
