import React from "react";
import ClientListSearchv3 from "../FullPages/ClientSearchFullv3";
import CallbackCal from "../FullPages/CallbackCal";
import CalendarFull from "../FullPages/CalendarFull";
import SearchByMissedAppts from "../FullPages/SearchByMissedAppts";
import ProviderClientSearch from "../FullPages/ProviderClientSearch";
import InNetworkProviders from "../FullPages/InNetworkProviders";
import NeedsSupervisor from "../FullPages/NeedsSupervisor";
import LiabilityRequestedList from "../FullPages/LiabilityRequestedList";
import LawfirmPropertyDamage from "../FullPages/LawfirmPropertyDamage";
import LawfirmPolicyLimit from "../FullPages/LawfirmPolicyLimit";
import LawfirmSortByReport from "../FullPages/LawfirmSortByReport";
import LawfirmSortLiability from "../FullPages/LawfirmSortByLiability";
import NoApptsNoBills from "../FullPages/NoApptsNoBills";
import DocumentSearch from "../FullPages/DocumentSearch";
import NeedsAppointment from "../FullPages/NeedsAppointment";

export default function renderAvailablePages(userRoles) {
  let activeArr = [];
  if (userRoles.permissions.includes("casemanager.view_case")) {
    activeArr.push(
      {
        path: "search",
        title: "Case Search",
        color: "purple",
        component: <ClientListSearchv3 isDashboardVersion={true} />,
        order: 1,
      },
      {
        path: "callback-calendar",
        title: "Callback Calendar",
        color: "purple",
        component: <CallbackCal isDashboardVersion={true} />,
        order: 3,
      }
    );
  }

  if (userRoles.permissions.includes("provider.view_appointment")) {
    activeArr.push(
      {
        path: "appointment-calendar",
        title: "Appointment Calendar",
        color: "sky",
        component: <CalendarFull isDashboardVersion={true} />,
        order: 4,
      },
      {
        path: "missed-appts",
        title: "Appointments Requiring Review",
        color: "orange",
        component: <SearchByMissedAppts isDashboardVersion={true} />,
        order: 5,
      },
      {
        path: "needs-appointment",
        title: "Needs Appointment",
        color: "red",
        component: <NeedsAppointment isDashboardVersion={true} />,
        order: 6,
      }
    );
  }
  if (userRoles.permissions.includes("provider.view_providerclientstatus")) {
    activeArr.push({
      path: "provider-client-search",
      title: "Provider Client Search",
      color: "purple",
      component: <ProviderClientSearch isDashboardVersion={true} />,
      order: 7,
    });
  }
  if (userRoles.permissions.includes("provider.view_provider")) {
    activeArr.push({
      path: "in-network-providers",
      title: "In Network Providers",
      color: "green",
      component: <InNetworkProviders isDashboardVersion={true} />,
      order: 8,
    });
  }
  if (userRoles.permissions.includes("users.can_view_supervisor_link")) {
    activeArr.push({
      path: "needs-supervisor",
      title: "Needs Supervisor",
      color: "red",
      component: <NeedsSupervisor isDashboardVersion={true} />,
      order: 9,
    });
  }

  if (userRoles.permissions.includes("casemanager.view_case")) {
    activeArr.push(
      {
        path: "liability-requested",
        title: "Liability Requested",
        color: "orange",
        component: <LiabilityRequestedList isDashboardVersion={true} />,
        order: 10,
      },
      {
        path: "needs-property-damage",
        title: "Needs Property Damage Cost",
        color: "orange",
        component: <LawfirmPropertyDamage isDashboardVersion={true} />,
        order: 11,
      },
      {
        path: "needs-policy-limit",
        title: "Needs Policy Limit",
        color: "orange",
        component: <LawfirmPolicyLimit />,
        order: 12,
      },
      {
        path: "needs-police-report",
        title: "Needs Police Report",
        color: "orange",
        component: <LawfirmSortByReport isDashboardVersion={true} />,
        order: 13,
      },
      {
        path: "liability-sort",
        title: "Sort by Liability",
        color: "purple",
        component: <LawfirmSortLiability isDashboardVersion={true} />,
        order: 14,
      }
    );
    if (
      userRoles.permissions.includes("provider.view_administeredservices") &&
      userRoles.permissions.includes("provider.view_appointment") &&
      userRoles.permissions.includes("casemanager.view_case")
    ) {
      activeArr.push({
        path: "needs-appt-bills",
        title: "Needs Appointment, No Bills",
        color: "purple",
        component: <NoApptsNoBills isDashboardVersion={true} />,
        order: 14,
      });
    }
    if (userRoles.permissions.includes("filemanager.view_document")) {
      activeArr.push({
        path: "document-search",
        title: "Document Search",
        color: "purple",
        component: <DocumentSearch isDashboardVersion={true} />,
        order: 15,
      });
    }
  }

  return activeArr;
}
