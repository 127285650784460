import {
  Button,
  CircularProgress,
  List,
  ListItem,
  InputAdornment,
  TextField,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import { Search } from "@mui/icons-material";

export default function LiabilityRequestedList({ isDashboardVersion }) {
  const { setSelectedClient, accessToken } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const navigateToDetail = (client) => {
    setSelectedClient(client.pk);
    navigate(`/client-detail/${client.pk}`);
  };

  const sendRequest = () => {
    axios
      .get(
        `/api/case/?${paginationUrl + `&liability_status=NP_REQ`}${
          !search ? "" : `&search=${search}`
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => sendRequest(),
    [accessToken, paginationUrl, setPaginationUrl, search],
    250
  );

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`header-wrapper px-4 flex ${
          isDashboardVersion
            ? "max-h-[85vh] overflow-auto"
            : "h-full overflow-hidden"
        }`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className="w-full md:ml-4">
          {isDashboardVersion ? (
            ""
          ) : (
            <h1
              className={`text-xl text-purple-600 text-center my-4 font-bold`}
            >
              Network Progression Requested Liability
            </h1>
          )}
          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2 items-center">
              <p className="md:pl-5 py-4">
                Showing: {resultCount.current} / {resultCount.total}
              </p>
              <div className="w-3/4">
                <TextField
                  size="small"
                  className="w-full my-4 text-center"
                  label="Search"
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>{" "}
            <div className="flex md:px-4 px-2 text-center py-2 border-b border-1 border-gray md:justify-start justify-between">
              <div className="md:w-1/4 font-bold">
                <h2>CLIENT NAME</h2>
              </div>
              <div className="w-1/4 w-1/3 font-bold md:block hidden">
                <h2>CASE NAME</h2>
              </div>
              <div className="md:w-1/4 w-1/3 font-bold">
                <h2>DATE CREATED</h2>
              </div>
              <div className="md:w-1/4 w-1/3 font-bold">
                <h2>STATUS</h2>
              </div>
            </div>
            <List
              className={`grid ${
                isDashboardVersion ? `h-[65vh]` : "max-h-[100%]"
              }  overflow-y-auto`}
            >
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {!responseData
                    ? ""
                    : responseData.map((client, index) => (
                        <ListItem
                          key={index}
                          value={client}
                          className="py-0 pt-0 md:px-4 px-0 items-start"
                        >
                          <Button
                            onClick={() => navigateToDetail(client.client)}
                            className={
                              "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between"
                            }
                          >
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-left ${resultClasses}`}
                            >
                              {currentPage > 1
                                ? index + 1 * ((currentPage - 1) * 25) + 1
                                : index + 1}
                              .&nbsp;
                              {!client ? "" : client.client.name}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses} md:block hidden`}
                            >
                              {!client ? "" : client.name}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses}`}
                            >
                              {!client.created_at
                                ? ""
                                : new Date(
                                    client.created_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: "UTC",
                                  })}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses} border-none`}
                            >
                              {!client ? "" : client.status}
                            </span>
                          </Button>
                        </ListItem>
                      ))}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                {" "}
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
