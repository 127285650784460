import { FormControl, InputLabel, MenuItem, Select } from "@mui/material/";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";

export default function EditApptv2({
  selectedAppt,
  setOpenEditAppt,
  handleModalClose,
}) {
  const { accessToken, setActiveFilterButton, trigger, setTrigger } =
    useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dateTimeVal, setDateTimeValue] = useState(selectedAppt.scheduled);
  const [value, setValue] = useState(selectedAppt.scheduled);
  const [apptStatus, setApptStatus] = useState(selectedAppt.status);
  const [apptNotes, setApptNotes] = useState(selectedAppt.notes);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenEditAppt(false);
    setApptStatus("");
    setApptNotes("");
  };

  const handleChange = (newValue) => {
    if (newValue === null || newValue === undefined) {
      setValue("");
    } else {
      setDateTimeValue(new Date(newValue).toISOString());
    }
  };

  const handleApptEdit = () => {
    let patchedProcedure = {
      ...(dateTimeVal === "" ? {} : { scheduled: dateTimeVal }),
      ...(apptStatus === "" ? {} : { status: apptStatus }),
      ...(apptNotes === "" ? {} : { notes: apptNotes }),
    };

    axios
      .patch(`/api/appointments/${selectedAppt.pk}/`, patchedProcedure, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setActiveFilterButton("");
          handleModalClose();
        }
      })
      .catch(function (error) {
        console.log(openError);
        if (error.response) {
          setOpenError(!openError);
          setErrorArray(error.response.data);
          setOpenError(!openError);
        }
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.scheduled) {
      arrayVals.push(`scheduled: ${errorArray.scheduled}`);
    }
    if (errorArray.status) {
      arrayVals.push(`status: ${errorArray.status}`);
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="w-full px-20 flex-vertical justify-center bg-white">
        {!selectedAppt ? (
          "Please Select an Appointment to Continue"
        ) : (
          <>
            <div className="">
              <div className="my-2">
                <div className="text-lg underline font-bold">
                  APPOINTMENT INFO
                </div>
                <div>
                  <span>Name:</span> <span>{selectedAppt.provider.name}</span>
                </div>
                <div>
                  <span>Phone:</span> <span>{selectedAppt.provider.phone}</span>
                </div>
                <div>
                  <span>Date/Time:</span> <span>{selectedAppt.scheduled}</span>
                </div>
              </div>

              <div className="my-1">
                <div className="text-lg underline font-bold">CLIENT INFO</div>
                <div>
                  <span>Name: </span> <span>{selectedAppt.client.name}</span>
                </div>
                <div>
                  <span>Email:</span> <span>{selectedAppt.client.email}</span>
                </div>
                <div>
                  <span>Phone:</span> <span>{selectedAppt.client.phone}</span>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="mt-4">
          <span className="text-lg font-bold">EDIT APPOINTMENT DETAILS</span>
          <DesktopDateTimePicker
            label="Appointment Date"
            value={!value ? "" : new Date(value)}
            className="w-full my-4 text-black"
            slotProps={{ textField: { variant: "outlined" } }}
            onChange={handleChange}
          />
          <FormControl className="w-full flex" style={{ width: "100%" }}>
            <InputLabel id="appt-status">Appointment Status</InputLabel>
            <Select
              required
              className="w-full"
              labelId="appt-status"
              id="Appt-status"
              value={apptStatus}
              label="Appt status"
              onChange={(event) => {
                setApptStatus(event.target.value);
              }}
            >
              <MenuItem value={"Assigned"}>Scheduled</MenuItem>
              <MenuItem value={"Overdue"}>Missed</MenuItem>
              <MenuItem value={"Done"}>Complete</MenuItem>
            </Select>
            <h3
              className="mt-4"
              style={{ fontWeight: "bold", margin: "1rem 0" }}
            >
              Appointment Notes
            </h3>
            <Editor
              textareaName="content"
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              value={apptNotes}
              onEditorChange={(newText) => {
                setApptNotes(newText);
              }}
              init={{
                menubar: false,
                branding: false,
                plugins: ["lists"],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
              }}
            />
          </FormControl>
        </div>
        <div className="text-sm my-2">
          <span className="text-red-600 font-bold">Warning:</span> Clicking the
          "Update Appointment Details" button below will permanently rewrite
          over the current appointment data, after proceeding previous data will
          be unrecoverable. Please make sure you want the above changes to take
          effect before proceeding.
        </div>
        <div style={{ padding: "1rem 0" }}>
          <ModalButton
            action={handleApptEdit}
            baseColor={"#9333ea"}
            content={`Update Appointment Details`}
            icon={"edit"}
          />
        </div>
        <ResponseModal
          title="Appointment Details Updated"
          description="Successfully Updated Appointment Details"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title="Error Updating Appointment Data"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
