import CloseIcon from "@mui/icons-material/Close";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  Button,
  Dialog,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import "../../../../../../styles/notes.css";
import CreateApptProcedurev2 from "./CreateApptProcedurev2";
import DeleteApptProcedurev2 from "./DeleteApptProcedurev2";
import EditApptProcedurev2 from "./EditApptProcedurev2";

export default function ProviderApptProceduresv2({
  selectedAppt,
  selectedProcedure,
  isModalVersion,
  setHasBills,
  activeProvObj,
  activeBills,
  apptResLimit,
}) {
  const { accessToken, trigger, selectedClient, userType, userRoles } =
    useContext(DataContext);
  const [procedures, setProcedures] = useState([]);
  const [openAddBill, setOpenAddBill] = useState(false);
  const [openEditBill, setOpenEditBill] = useState(false);
  const [openDeleteBill, setOpenDeleteBill] = useState(false);
  const [selectedBill, setSelectedBill] = useState([]);

  const { width } = useWindowDimensions();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleAddProcedure = () => {
    setOpenAddBill(true);
  };

  const handleAddBill = () => {
    setOpenAddBill(false);
  };

  const handleEditBill = () => {
    setOpenEditBill(false);
  };

  const handleDeleteBill = () => {
    setOpenDeleteBill(false);
  };

  const handleOpenEdit = (procedure) => {
    setSelectedBill(procedure);
    setOpenEditBill(true);
  };

  const handleOpenDelete = (procedure) => {
    setSelectedBill(procedure);
    setOpenDeleteBill(true);
  };

  useEffect(() => {
    let procedurePk = !selectedAppt ? "" : selectedAppt.pk;

    if (userRoles.permissions.includes("provider.view_administeredservices")) {
      axios
        .get(
          `/api/administered/?limit=100&administered_to=${selectedClient}&appointment=${procedurePk}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((proceduresList) => {
          setProcedures(proceduresList.data.results);
        });
    }
  }, [
    trigger,
    userRoles.permissions,
    selectedClient,
    selectedProcedure,
    accessToken,
    apptResLimit,
    selectedAppt,
  ]);

  function billTotal() {
    let vals = [];
    let totalCost;

    procedures.map((procedure) => {
      return vals.push(+procedure.price);
    });
    if (vals.length === 1) {
      totalCost = +vals[0];
    } else {
      totalCost = vals.reduce((a, v) => (a = a + v), 0);
    }
    return totalCost;
  }

  useEffect(() => {
    let billsObj = {};
    for (const item in activeBills) {
      billsObj[activeBills[item].appointment.pk] = activeBills[item].price;
    }

    setTimeout(() => {
      setHasBills(billsObj);
    }, "1000");
  }, [trigger, selectedClient, activeBills, apptResLimit, setHasBills]);

  return (
    <>
      {width < 640 ? (
        <div className={`relative`}>
          <div className="border-gray border rounded-md">
            <h3 className="underline" style={{ padding: "6px 25px" }}>
              Appointment Notes
            </h3>
            <div
              className="notes"
              dangerouslySetInnerHTML={{
                __html: !selectedAppt ? "" : selectedAppt.notes,
              }}
            ></div>
          </div>
          {!selectedAppt ||
          userType === "unknown" ||
          (userType === "Provider" &&
            selectedAppt.provider.pk !==
              activeProvObj[selectedAppt.provider.pk]) ? (
            ""
          ) : (
            <div className="text-center float-right">
              {userRoles.permissions.includes(
                "provider.add_administeredservices"
              ) ? (
                <Tooltip title="Add Bill" placement="top">
                  <Button
                    onClick={handleAddProcedure}
                    className="flex mx-auto text-green-500 hover:text-white hover:bg-green-500 overflow-x-hidden"
                  >
                    <span className="mx-4">
                      <ControlPointOutlinedIcon />
                    </span>
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )}
          {!selectedAppt ? (
            ``
          ) : (
            <div className="p-4 mt-6">
              {procedures.length === 0 ? (
                "No Procedures"
              ) : (
                <>
                  {procedures.map((procedure, pk) => (
                    <Accordion key={pk}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel${pk}-header`}
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <span className="font-bold">
                              {!procedure.service
                                ? "No Service Attached "
                                : procedure.service.service_name
                                ? procedure.service.service_name
                                : "No Service Attached "}
                            </span>

                            {procedure.price
                              ? formatter.format(procedure.price)
                              : "  "}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="flex">
                        <div className="p-2 m-1 flex flex-col border-purple-600 border rounded-md w-full text-center">
                          <div>
                            <div>
                              <span className="font-bold">
                                Bill Code:&nbsp;
                              </span>
                              {!procedure.service
                                ? ""
                                : procedure.service.service_code
                                ? procedure.service.service_code
                                : "  "}
                            </div>
                            <div>
                              <span className="font-bold">
                                Bill Name:&nbsp;
                              </span>
                              {!procedure.service
                                ? ""
                                : procedure.service.service_name
                                ? procedure.service.service_name
                                : "  "}
                            </div>
                            <div>
                              <span className="font-bold">
                                Bill Price:&nbsp;
                              </span>
                              {procedure.price
                                ? formatter.format(procedure.price)
                                : "  "}
                            </div>
                            <div>
                              <span className="font-bold">
                                Administered By:&nbsp;
                              </span>
                              {procedure.signature ? procedure.signature : "  "}
                            </div>
                          </div>
                          {userType === "Provider" &&
                          procedure.administered_by.pk !==
                            activeProvObj[procedure.administered_by.pk] ? (
                            ""
                          ) : (
                            <div>
                              {userRoles.permissions.includes(
                                "provider.change_administeredservices"
                              ) ? (
                                <Tooltip title="Edit Bill" placement="bottom">
                                  <Button
                                    onClick={(e) => handleOpenEdit(procedure)}
                                    className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                                    style={{ color: "#9333EA" }}
                                  >
                                    <span>
                                      <EditOutlinedIcon />
                                    </span>
                                  </Button>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {userRoles.permissions.includes(
                                "provider.delete_administeredservices"
                              ) ? (
                                <Tooltip title="Delete Bill" placement="bottom">
                                  <Button
                                    onClick={(e) => handleOpenDelete(procedure)}
                                    className="rounded-md text-red-300 hover:bg-red-300"
                                    style={{ color: "red" }}
                                  >
                                    {/* <span  style={{ marginLeft: "1px" }}>Delete</span> */}
                                    <span>
                                      <DeleteForeverOutlinedIcon />
                                    </span>
                                  </Button>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {!selectedAppt ? (
            ``
          ) : (
            <div className={``}>
              <div
                style={{
                  position: "sticky",
                  width: "100%",
                  background: "#FFF",
                }}
              >
                <div
                  className="grid grid-cols-5 mt-6 mb-4 text-center w-[100%]"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
                    marginTop: "1.5rem",
                    background: "white",
                  }}
                >
                  <div
                    className="grid w-[100%]"
                    style={{
                      display: "grid",
                    }}
                  >
                    <span
                      className="place-content-center overflow-x-hidden text-black font-bold rounded-l-lg"
                      style={{
                        overflowX: "hidden",
                        fontWeight: "bold",
                        placeContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Bill Code
                    </span>
                  </div>
                  <div
                    className="grid w-[100%]"
                    style={{
                      display: "grid",
                    }}
                  >
                    <span
                      className="place-content-center overflow-x-hidden text-black font-bold"
                      style={{
                        overflowX: "hidden",
                        fontWeight: "bold",
                        placeContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Bill Name
                    </span>
                  </div>
                  <div
                    className="grid"
                    style={{
                      display: "grid",
                    }}
                  >
                    <span
                      className="place-content-center overflow-x-hidden text-black font-bold"
                      style={{
                        overflowX: "hidden",
                        fontWeight: "bold",
                        placeContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Bill Price
                    </span>
                  </div>
                  <div
                    className="grid"
                    style={{
                      display: "grid",
                    }}
                  >
                    <span
                      className="place-content-center overflow-x-hidden text-black font-bold rounded-r-lg"
                      style={{
                        overflowX: "hidden",
                        fontWeight: "bold",
                        placeContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Administered By
                    </span>
                  </div>
                  <div
                    style={{
                      display: "grid",
                    }}
                  >
                    {!selectedAppt ||
                    userType === "unknown" ||
                    (userType === "Provider" &&
                      selectedAppt.provider.pk !==
                        activeProvObj[selectedAppt.provider.pk]) ? (
                      ""
                    ) : (
                      <div className="text-center float-right">
                        {userRoles.permissions.includes(
                          "provider.add_administeredservices"
                        ) ? (
                          <Tooltip title="Add Bill" placement="top">
                            <Button
                              onClick={handleAddProcedure}
                              className="flex mx-auto text-green-500 hover:text-white hover:bg-green-500 overflow-x-hidden"
                            >
                              <span className="mx-4">
                                <ControlPointOutlinedIcon />
                              </span>
                            </Button>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full p-0 mb-2 overflow-auto h-full">
                <div className="mt-2">
                  <div>
                    {procedures.length === 0 ? (
                      <div className="mt-6 w-full">
                        <h3
                          className="text-center bg-gray-200 rounded-lg uppercase"
                          style={{
                            background: "#E5E7EB",
                            borderRadius: "10px",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          No Current Bills
                        </h3>
                      </div>
                    ) : (
                      <>
                        <p
                          style={{
                            textAlign: "center",
                            background: "#FCA5A5",
                            borderRadius: "5px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Service Cost:
                          </span>{" "}
                          {formatter.format(billTotal())}
                        </p>
                        {procedures.map((procedure, pk) => (
                          <List
                            key={pk}
                            className="p-0"
                            style={{
                              borderTop: `${
                                isModalVersion === true
                                  ? "1px solid #e5e7eb"
                                  : "none"
                              }`,
                            }}
                          >
                            <div
                              className="my-2 p-0 w-full grid grid-cols-5 rounded-lg shadow-inner"
                              style={{
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(5, minmax(0, 1fr))",
                                overflowX: "hidden",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <ListItem
                                className=" w-[100%] justify-center place-content-center overflow-x-hidden text-center border-gray border-r"
                                style={{
                                  borderRight: "1px solid #e5e7eb",
                                  padding: "8px 16px",
                                  height: "100%",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {!procedure.service
                                  ? "No service"
                                  : !procedure.service.service_code
                                  ? "No service"
                                  : procedure.service.service_code}
                              </ListItem>
                              <ListItem
                                className=" w-[100%] justify-center place-content-center overflow-x-hidden text-center border-gray border-r"
                                style={{
                                  borderRight: "1px solid #e5e7eb",
                                  padding: "8px 16px",
                                  height: "100%",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {!procedure.service
                                  ? "No service"
                                  : !procedure.service.service_code
                                  ? "No service"
                                  : procedure.service.service_name}
                              </ListItem>
                              <ListItem
                                className=" w-[100%] justify-center place-content-center overflow-x-hidden text-center border-gray border-r"
                                style={{
                                  borderRight: "1px solid #e5e7eb",
                                  padding: "8px 16px",
                                  height: "100%",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {procedure.price
                                  ? formatter.format(procedure.price)
                                  : "  "}
                              </ListItem>
                              <ListItem
                                className=" w-[100%] justify-center place-content-center overflow-x-hidden text-center border-gray border-r"
                                style={{
                                  borderRight: "1px solid #e5e7eb",
                                  padding: "8px 16px",
                                  height: "100%",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {procedure.signature
                                  ? procedure.signature
                                  : "  "}
                              </ListItem>
                              {!activeProvObj && userType === "Provider" ? (
                                ""
                              ) : userType === "Provider" &&
                                procedure.administered_by.pk !==
                                  activeProvObj[
                                    procedure.administered_by.pk
                                  ] ? (
                                ""
                              ) : (
                                <ListItem className=" w-[100%] justify-center place-content-center text-center flex-col flex xl:flex-row">
                                  {userRoles.permissions.includes(
                                    "provider.change_administeredservices"
                                  ) ? (
                                    <Tooltip
                                      title="Edit Bill"
                                      placement="bottom"
                                    >
                                      <Button
                                        onClick={(e) =>
                                          handleOpenEdit(procedure)
                                        }
                                        className="rounded-md hover:text-purple-500 text-purple-300 hover:text-white hover:bg-purple-300"
                                        style={{ color: "#9333EA" }}
                                      >
                                        <span>
                                          <EditOutlinedIcon />
                                        </span>
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                  {userRoles.permissions.includes(
                                    "provider.delete_administeredservices"
                                  ) ? (
                                    <Tooltip
                                      title="Delete Bill"
                                      placement="bottom"
                                    >
                                      <Button
                                        onClick={(e) =>
                                          handleOpenDelete(procedure)
                                        }
                                        className="rounded-md text-red-300 hover:bg-red-300"
                                        style={{ color: "red" }}
                                      >
                                        {/* <span  style={{ marginLeft: "1px" }}>Delete</span> */}
                                        <span>
                                          <DeleteForeverOutlinedIcon />
                                        </span>
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </ListItem>
                              )}
                            </div>
                          </List>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Dialog
                open={openAddBill}
                fullScreen={width < 640 ? true : false}
              >
                <div
                  style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    <Button onClick={handleAddBill}>
                      <CloseIcon />
                    </Button>
                  </div>
                  <h1
                    style={{
                      fontSize: 20,
                      margin: "0 0 1rem 0",
                      textAlign: "center",
                      color: "#32CD30",
                    }}
                  >
                    Create Bill
                  </h1>
                  <CreateApptProcedurev2
                    selectedAppt={selectedAppt}
                    setOpenAddBill={setOpenAddBill}
                  />
                </div>
              </Dialog>
            </div>
          )}
        </>
      )}

      <Dialog open={openAddBill} fullScreen={width < 640 ? true : false}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={handleAddBill}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              margin: "0 0 1rem 0",
              textAlign: "center",
              color: "#32CD30",
            }}
          >
            Create Bill
          </h1>
          <CreateApptProcedurev2
            selectedAppt={selectedAppt}
            setOpenAddBill={setOpenAddBill}
          />
        </div>
      </Dialog>
      {/* open edit modal */}
      <Dialog open={openEditBill} fullScreen={width < 640 ? true : false}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={handleEditBill}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              margin: "0 0 1rem 0",
              textAlign: "center",
              color: "#9333EA",
              fontWeight: "bold",
            }}
          >
            Current Bill Details
          </h1>
          <EditApptProcedurev2
            selectedAppt={selectedAppt}
            setOpenEditBill={setOpenEditBill}
            selectedBill={selectedBill}
          />
        </div>
      </Dialog>
      {/* open delete modal */}
      <Dialog open={openDeleteBill} fullScreen={width < 640 ? true : false}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={handleDeleteBill}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              margin: "0 0 1rem 0",
              textAlign: "center",
              // eslint-disable-next-line
              color: "red",
              fontWeight: "bold",
            }}
          >
            Delete Bill
          </h1>
          <DeleteApptProcedurev2
            selectedAppt={selectedAppt}
            setOpenDeleteBill={setOpenDeleteBill}
            selectedBill={selectedBill}
          />
        </div>
      </Dialog>
    </>
  );
}
