import {
  ArrowBack,
  ArrowForward,
  ForwardToInboxOutlined,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material/";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ResponseModal from "../../../../global/ResponseModal";
import { SendClientInfo } from "./SendClientInfo";
import PlacesResponseList from "../../../../global/PlacesResponseList";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import ApiRequestErrorHandler from "../../../../global/ApiRequestErrorHandler";

const CreateClient_URL = "api/create-client/";

export default function AddClientForm() {
  const {
    accessToken,
    caseManagerName,
    selectedClient,
    userRoles,
    loggedInUser,
    setSelectedClient,
  } = useContext(DataContext);
  const [creationForm, setCreationForm] = useState("NewClientInfo");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [caseName, setCaseName] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [policyLimit, setPolicyLimit] = useState("");
  const [dob, setDob] = useState(new Date());
  const [address, setAddress] = useState("");

  const [policeReport, setPoliceReport] = useState(false);
  const [pictures, setPictures] = useState(false);
  const [doi, setDoi] = useState(new Date());
  const [dco, setDco] = useState(new Date());
  const [hasError, setHasError] = useState(true);
  const { activeLawfirm } = useContext(DataContext);
  const { lawFirmLink } = useContext(DataContext);
  const [npIntro, setNpIntro] = useState("False");
  const [lawfirms, setLawfirms] = useState([]);
  const [clientLawFirm, setClientLawfirm] = useState("");
  const [caseSources, setCaseSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [clientStatus, setClientStatus] = useState("Intake");
  const [lfSearch, setLfSearch] = useState("");
  const [campSearch, setCampSearch] = useState("");
  const [customEntity, setCustomEntity] = useState("");

  const [languageOptions, setLanguageOptions] = useState([]);
  const [language, setLanguage] = useState("Unknown");
  const [loading, setLoading] = useState("");

  const [caseNotes, setCaseNotes] = useState(
    '<ul class="ul1">\n<li class="li1">Preferred Language: </li>\n<li class="li1">Location of Accident: </li>\n<li class="li1">Citations given to client: </li>\nCitations given to defendant\n<li class="li1">Number of Vehicles: </li>\n<li class="li1">Passenger Names in Vehicle: </li>\n<li class="li1">Passenger relationships: </li>\n<li class="li1">Passengers need representation: </li>\n<li class="li1">Police Report Obtained: <br></br><br></br></li>\n<li class="li1">Injuries: </li>\n<li class="li1">Pain levels: </li>\n<li class="li1">Ambulance (Name): </li>\n<li class="li1">Hospital (Name):</li>\n<li class="li1">Procedures of Tests Done (Names): </li>\n<li class="li1">Previous Providers (Name, Location, Date): </li>\n</ul>\n<li class="li1">Scheduled Appts (Name, Location, Dates): <br></br><br></br></li>\n<li class="li1"> First Auto accident (Yes/No): </li>\n<li class="li1">When did accident occur: </li>\n<li class="li1">What were the injuries: </li>\n<li class="li1">Did you have representation: </li>\n<li class="li1">Who was the law firm: </li>\n<li class="li1">Is the case closed: </li><br/>\n<li class="li1">Do you currently have representation for this case: </li>\n<li class="li1">Which attorney currently represents your case: </li>\n<li class="li1">Has that attorney been notified: </li></ul>'
  );

  const navigate = useNavigate();
  const handlePlaceSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const handleOpenSuccess = () => {
    setLoading(false);
    setOpenSuccess(!openSuccess);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCaseName("");
    setLoading(false);
    setCaseNotes("");
    setPolicyLimit("");

    navigate(`/client-detail/${selectedClient}`);
  };

  useEffect(() => {
    if (loggedInUser.works_for_law_firm_pk) {
      axios
        .get(`/api/lawfirm/${loggedInUser.works_for_law_firm_pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setClientLawfirm(data);
        });
    }
  }, [loggedInUser.works_for_law_firm_pk, accessToken, setClientLawfirm]);

  useDebouncedEffect(
    () => {
      if (!userRoles.permissions.includes("lawyer.view_lawfirm")) {
        return;
      } else {
        axios
          .get(
            `/api/lawfirm/?ordering=name${
              !lfSearch ? "" : `&search=${lfSearch}`
            }`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            setLawfirms(data);
          });
      }
    },
    [accessToken, clientLawFirm, userRoles.permissions, lfSearch],
    500
  );

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes("leads.view_campaign") ||
        userRoles.permissions.includes("leads.view_all_campaigns")
      ) {
        axios
          .get(
            `/api/leads/campaigns/?ordering=name${
              !campSearch ? "" : `&search=${campSearch}`
            }`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            setCaseSources(data);
          });
      }
    },
    [setCaseSources, accessToken, campSearch],
    500
  );

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};
          for (const item in data) {
            obj[data[item].pk] = data[item].description;
          }
          setLanguageOptions(data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      return;
    }
  }, [accessToken, setLanguageOptions, userRoles.permissions]);

  const handleNewClientSubmit = (e) => {
    setLoading(true);
    let createNewUserData = {
      client: {
        name: name,
        email: email,
        phone: phone,
        date_of_birth: new Date(dob).toLocaleDateString("en-CA"),
        ...(language === "Unknown" ? {} : { preferred_language: language }),
        address: {
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          raw: address,
        },
      },
      case: {
        case_name: caseName,
        crash_report_received: false,
        ...(!selectedSource ? {} : { campaign: selectedSource.pk }),
        accident_pictures_received: pictures,
        date_of_accident: new Date(doi).toLocaleDateString("en-CA"),
        date_opened: new Date(dco).toLocaleDateString("en-CA"),
        police_report_received: policeReport,
        status: clientStatus,
        law_firm: !clientLawFirm ? null : clientLawFirm.pk,
        ...(!customEntity ? {} : { entity: customEntity.pk }),
      },
      notes: [
        {
          ...(caseNotes === "" ? {} : { content: caseNotes }),
        },
      ],
      settlement: {
        ...(policyLimit === "" ? {} : { policy_limit: policyLimit }),
      },
    };

    axios
      .post(CreateClient_URL, createNewUserData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setHasError(false);
          setOpenSuccess(!openSuccess);
          setSelectedClient(response.data.client.pk);
        }
      })
      .catch(function (error) {
        setHasError(true);
        setLoading(false);
        let errArr = ApiRequestErrorHandler(error.response.data);
        setErrorArray(errArr);
        setOpenError(!openError);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="h-full justify-items-center overflow-auto sm:mx-auto w-full">
        <h1
          className={`text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2 bg-white absolute`}
        >
          New Client Form
        </h1>
        <div className={`mt-14 mx-auto lg:w-1/2 w-[90%]`}>
          <form className="">
            <div className="text-center ">
              {creationForm === "NewClientInfo" ? (
                <div>
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Client Full Name (First & Last)"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Client Email"
                    placeholder="ex. email@email.com"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Client Phone"
                    placeholder="ex. 404-444-4444"
                    value={phone}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                  {userRoles.permissions.includes("utilities.view_language") ? (
                    <FormControl className="justify-center my-4 text-sm w-full">
                      <InputLabel id="language" className="text-sm">
                        Preferred Language
                      </InputLabel>
                      <Select
                        label="Preferred Language"
                        onChange={(e) => setLanguage(e.target.value)}
                        className="text-sm"
                        value={language}
                        MenuProps={{
                          sx: { height: "300px" },
                        }}
                      >
                        <MenuItem value={"Unknown"} className="text-sm">
                          Unknown
                        </MenuItem>
                        {!languageOptions
                          ? ""
                          : languageOptions.map((item) => (
                              <MenuItem
                                value={item.pk}
                                key={item.pk}
                                className="text-sm"
                              >
                                {item.description}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : (
                    ""
                  )}

                  {!loggedInUser.works_for_law_firm_pk ? (
                    <div>
                      <Autocomplete
                        className="w-full"
                        disablePortal
                        options={!lawfirms ? [] : lawfirms}
                        getOptionLabel={(option) => option.name}
                        noOptionsText="No Results"
                        sx={{ width: 300 }}
                        onChange={(e, value) => {
                          setClientLawfirm(value);
                        }}
                        onBlur={() => setLfSearch("")}
                        value={!clientLawFirm ? null : clientLawFirm}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} id={option.pk} key={option.name}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Law Firms"
                            onChange={(e) => setLfSearch(e.target.value)}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {clientLawFirm && clientLawFirm.entities.length > 1 ? (
                    <Autocomplete
                      className="w-full my-4"
                      disablePortal
                      options={
                        !clientLawFirm.entities ? [] : clientLawFirm.entities
                      }
                      getOptionLabel={(option) => option.name}
                      noOptionsText="No Results"
                      sx={{ width: 300 }}
                      onChange={(e, value) => {
                        setCustomEntity(value);
                      }}
                      value={!customEntity ? null : customEntity}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} id={option.pk} key={option.name}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Entities" />
                      )}
                    />
                  ) : (
                    ""
                  )}
                  {!userRoles.permissions.includes("leads.view_campaign") ? (
                    ""
                  ) : (
                    <Autocomplete
                      className="w-full my-4"
                      disablePortal
                      options={!caseSources ? [] : caseSources}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="No Results"
                      sx={{ width: 300 }}
                      onChange={(e, value) => {
                        setSelectedSource(value);
                      }}
                      onBlur={() => setCampSearch("")}
                      value={!selectedSource ? null : selectedSource}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} id={option.pk} key={option.name}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Campaigns"
                          onChange={(e) => setCampSearch(e.target.value)}
                        />
                      )}
                    />
                  )}

                  <DesktopDatePicker
                    className="w-full my-4 text-center"
                    label="Date of Birth"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    slotProps={{ textField: { variant: "outlined" } }}
                  />
                  <PlacesResponseList
                    address={address}
                    setAddress={setAddress}
                    handlePlaceSelect={handlePlaceSelect}
                  />
                  {coordinates.lat ? (
                    <Button
                      onClick={(e) => {
                        setCreationForm("assignCaseManagers");
                      }}
                      className="hover:text-white hover:bg-green-500 text-green-500 rounded-md flex justify-center border-solid border-green-500 border w-fit px-8 py-1 m-auto"
                    >
                      Next Page <ArrowForward />
                    </Button>
                  ) : null}
                </div>
              ) : creationForm === "SendClientInfo" ? (
                <div>
                  <Button
                    onClick={(e) => {
                      setCreationForm("assignCaseManagers");
                    }}
                    className="hover:text-white hover:bg-gray-600 text-gray-600 rounded-md flex justify-center border-solid border-gray-600 border w-fit px-8 py-1 m-auto"
                  >
                    <ArrowBack /> Previous Page
                  </Button>
                  <SendClientInfo
                    name={name}
                    caseName={caseName}
                    caseManagerName={caseManagerName}
                    caseNotes={caseNotes}
                    handleNewClientSubmit={handleNewClientSubmit}
                    policeReport={policeReport}
                    pictures={pictures}
                    hasError={hasError}
                    activeLawfirm={activeLawfirm}
                    lawFirmLink={lawFirmLink}
                    npIntro={npIntro}
                    setNpIntro={setNpIntro}
                    loading={loading}
                  />
                  <ResponseModal
                    title="New Client Added"
                    description={` Name: You have successfully added "${name}" a new client to the system!`}
                    openBool={openSuccess}
                    setOpenBool={setOpenSuccess}
                    handleCloseFunc={handleOpenSuccess}
                  />
                  <ResponseModal
                    title="Error Creating New Client"
                    isError={true}
                    openBool={openError}
                    setOpenBool={setOpenError}
                    errorMessage={errorArray}
                  />
                </div>
              ) : (
                <div className="overflow-auto">
                  <Button
                    onClick={(e) => {
                      setCreationForm("NewClientInfo");
                    }}
                    className="hover:text-white hover:bg-gray-600 text-gray-600 rounded-md flex w-full justify-center border-solid border-gray-600 border w-fit px-8 py-1 m-auto"
                  >
                    <ArrowBack /> Previous Page
                  </Button>
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Case Name"
                    placeholder="Law Firm Acronym - Number"
                    value={caseName}
                    onChange={(event) => {
                      setCaseName(event.target.value);
                    }}
                  />
                  <div>
                    <FormGroup className="justify-center py-4 w-full">
                      <InputLabel>Case Status</InputLabel>
                      <Select
                        size="small"
                        labelId="case-status"
                        id="case-status"
                        value={clientStatus}
                        label="Case Status"
                        onChange={(e) => setClientStatus(e.target.value)}
                      >
                        <MenuItem value="Lead">Lead</MenuItem>
                        <MenuItem value="Signed">Signed</MenuItem>
                        <MenuItem value="Intake">Intake</MenuItem>
                      </Select>
                    </FormGroup>
                  </div>
                  <div className="flex sm:flex-row flex-col">
                    <FormGroup className="justify-center py-4 w-1/2 mr-2">
                      <InputLabel>Police Report Received</InputLabel>
                      <Select
                        size="small"
                        labelId="police-report"
                        id="police-report"
                        value={policeReport}
                        label="Police Report Received"
                        onChange={(e) => setPoliceReport(e.target.value)}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormGroup>
                    <FormGroup className="justify-center py-4 w-1/2">
                      <InputLabel>Accident Pictures Received</InputLabel>
                      <Select
                        size="small"
                        labelId="accident-pictures"
                        id="accident-pictures"
                        value={pictures}
                        label="Accident Pictures Received"
                        onChange={(e) => setPictures(e.target.value)}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormGroup>
                  </div>
                  <div className="flex">
                    {" "}
                    <DesktopDatePicker
                      className="w-full mr-1 my-4 text-center"
                      label="Date of Injury"
                      value={doi}
                      onChange={(newValue) => {
                        setDoi(newValue);
                      }}
                      slotProps={{ textField: { variant: "outlined" } }}
                    />
                    <DesktopDatePicker
                      className="w-full ml-1 my-4 text-center"
                      label="Case Opened Date"
                      value={dco}
                      onChange={(newValue) => {
                        setDco(newValue);
                      }}
                      slotProps={{ textField: { variant: "outlined" } }}
                    />
                  </div>
                  <h3 className="font-bold mb-2">Case Notes</h3>
                  <Editor
                    textareaName="content"
                    apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                    value={caseNotes}
                    onEditorChange={(newText) => {
                      setCaseNotes(newText);
                    }}
                    init={{
                      menubar: false,
                      branding: false,
                      plugins: ["lists"],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Policy Limit"
                    value={policyLimit}
                    onChange={(event) => {
                      setPolicyLimit(event.target.value);
                    }}
                  />
                  <div className="mb-6">
                    <Button
                      className="border-black rounded-md hover:text-white hover:bg-[#1866d2] p-2"
                      onClick={(e) => {
                        setCreationForm("SendClientInfo");
                      }}
                    >
                      <ForwardToInboxOutlined />
                      <span className="ml-2">
                        Notify In the Network of New Client
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </LocalizationProvider>
  );
}
