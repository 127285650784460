import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import CaseDetailHeader from "./CaseDetailHeader";
import MedicalBreakdown from "./MedicalBreakdown";
import SettlementAttorneyDetail from "./SettlementAttorneyDetail";
import { ArrowBack } from "@mui/icons-material";
import LiensBalances from "./LiensBalances";
import Payments from "./Payments";

export default function Settlement() {
  const { casePk, getCasePk, accessToken, userRoles } = useContext(DataContext);
  const [trigger, setTrigger] = useState(false);
  const [caseDetail, setCaseDetail] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!casePk && params) {
      getCasePk(params.id);
    }

    if (!casePk || !params.id) {
      return;
    } else {
      axios
        .get(`/api/case/${!casePk ? params.id : casePk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setCaseDetail(data);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [setCaseDetail, accessToken, casePk, params, getCasePk, trigger]);

  return !caseDetail ? (
    <div className="m-4">
      {" "}
      <h1 className="text-[2rem] text-purple-600 font-bold text-center my-4">
        <CircularProgress color="secondary" />
      </h1>
    </div>
  ) : (
    <div className={`m-4 relative`}>
      <Tooltip title="Go Back">
        <IconButton
          onClick={() => navigate(-1)}
          className="text-purple-600 normal-case underline bg shadow-lg absolute left-[-5px] top-[-15px] z-30 bg-white"
        >
          <ArrowBack className="text-[18px]" />
        </IconButton>
      </Tooltip>

      <div className="flex justify-between flex-col lg:flex-row">
        {" "}
        <div
          className={`${
            userRoles.permissions.includes("provider.view_providersettlement")
              ? "lg:w-1/3"
              : "w-full"
          } mr-2 mt-4`}
        >
          <CaseDetailHeader caseDetail={caseDetail} />
          <SettlementAttorneyDetail
            caseDetail={caseDetail}
            trigger={trigger}
            setTrigger={setTrigger}
          />
          {userRoles.permissions.includes(
            "casemanager.view_settlementcheck"
          ) ? (
            <Payments
              caseDetail={caseDetail}
              trigger={trigger}
              setTrigger={setTrigger}
            />
          ) : (
            ""
          )}
          {userRoles.permissions.includes("provider.view_additionalexpense") ? (
            <LiensBalances />
          ) : (
            ""
          )}
        </div>
        {userRoles.permissions.includes("provider.view_providersettlement") ? (
          <div className="lg:w-2/3 mx-4">
            <MedicalBreakdown
              caseDetail={caseDetail}
              parentTrigger={trigger}
              setParentTrigger={setTrigger}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
