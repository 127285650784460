import React from "react";
import { Button, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";

export const SendClientInfo = ({
  npIntro,
  setNpIntro,
  handleNewClientSubmit,
  loading,
}) => {
  return (
    <div className="px-6 my-4">
      <h2 className="text-1xl text-center text-purple-600 uppercase font-bold w-full px-4 rounded-lg py-2">
        Client Email Details
      </h2>
      <FormGroup className="justify-center py-4">
        <InputLabel>In the Network Intro</InputLabel>
        <Select
          size="small"
          labelId="np-intro"
          id="np-intro"
          value={npIntro}
          label="NP Intro"
          onChange={(e) => setNpIntro(e.target.value)}
        >
          <MenuItem value="True">Yes</MenuItem>
          <MenuItem value="False">No</MenuItem>
        </Select>
      </FormGroup>
      <Button
        onClick={() => {
          handleNewClientSubmit();
        }}
        className="hover:text-white hover:bg-sky-600 text-sky-600 rounded-md flex justify-center border-solid border-sky-600 border w-fit px-8 py-1 m-auto"
        disabled={loading}
      >
        Submit Client
      </Button>
    </div>
  );
};
