import { Close } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ModalButton from "../../../../../../global/ModalButton";
import ResponseModal from "../../../../../../global/ResponseModal";

export default function AddExpense({
  casePk,
  addOpen,
  setAddOpen,
  trigger,
  setTrigger,
  activeExpense,
  setActiveExpense,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [deductionOptions, setDeductionOptions] = useState([]);
  const [deductionType, setDeductionType] = useState(3);
  const [label, setLabel] = useState("");

  const handleOpenSuccess = () => {
    setAddOpen(false);
    setOpenSuccess(!openSuccess);
    setTrigger(!trigger);
    setLabel("");
    setExpenseAmount("");
  };

  useEffect(() => {
    if (!activeExpense) {
      return;
    } else {
      setExpenseAmount(activeExpense.amount);
      setLabel(activeExpense.label);
      setDeductionType(activeExpense.deduction_type);
    }
  }, [addOpen, activeExpense]);

  useEffect(() => {
    axios
      .options("/api/additional_expense/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDeductionOptions(response.data.actions.POST.deduction_type.choices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setDeductionOptions, accessToken]);

  const handleExpenseUpdate = () => {
    const postData = {
      label: label,
      amount: +expenseAmount,
      deduction_type: +deductionType,
    };

    axios
      .patch(`/api/additional_expense/${activeExpense.pk}/`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
      })
      .catch((error) => {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const handleExpenseSubmit = () => {
    const postData = {
      label: label,
      case: +casePk,
      amount: +expenseAmount,
      deduction_type: +deductionType,
    };

    axios
      .post("/api/additional_expense/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
      })
      .catch((error) => {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const handleClose = () => {
    setAddOpen(false);
    setActiveExpense("");
    setExpenseAmount("");
    setLabel("");
    setDeductionType("");
  };
  return (
    <Dialog open={addOpen}>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%",
          width: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0 0 1rem 0",
            textAlign: "center",
            color: !activeExpense ? "#16A34A" : "#FFA500",
            fontWeight: "bold",
          }}
        >
          {!activeExpense ? "Add Expense" : "Edit Expense"}
        </h1>
        <FormControl
          className="w-full flex"
          style={{ width: "100%", marginBottom: "1rem" }}
        >
          <InputLabel id="deductionType">Deduction Type</InputLabel>
          <Select
            required
            className="w-full"
            labelId="deductionType"
            id="deduction-type"
            value={deductionType}
            label="Deduction Type"
            onChange={(event) => {
              setDeductionType(event.target.value);
            }}
          >
            {deductionOptions.map((type, idx) => {
              return (
                <MenuItem
                  value={type.value}
                  key={idx}
                  onClick={() => setDeductionType(type.value)}
                >
                  {type.display_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <TextField
            id="expense-label"
            value={label}
            type="text"
            label="Expense Label"
            onChange={(e) => setLabel(e.target.value)}
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{ m: 1 }}
          variant="standard"
          style={{ marginTop: "1rem" }}
        >
          <InputLabel htmlFor="standard-adornment-amount">
            Expense Amount
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={expenseAmount}
            type="number"
            onChange={(e) => setExpenseAmount(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <div style={{ marginTop: "1rem" }}>
          <ModalButton
            action={!activeExpense ? handleExpenseSubmit : handleExpenseUpdate}
            baseColor={!activeExpense ? "#16A34A" : "#FFA500"}
            content="Submit Expense"
            icon={"add"}
          />
        </div>
      </div>
      <ResponseModal
        title={`Expense Addition Successful`}
        description="Case expense has been added"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title={`Expense Add Failed`}
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </Dialog>
  );
}
