import { Dialog, IconButton, Tooltip } from "@mui/material";
import { Check, Close, HourglassBottom, Launch } from "@mui/icons-material";
import ContactControls from "../../../../../global/ContactControls";

export default function ActionItemDetail({
  detailOpen,
  setDetailOpen,
  activeActionItem,
  setActiveActionItem,
  navigateToDetail,
}) {
  const handleModalClose = () => {
    setDetailOpen(false);
    setActiveActionItem(null);
  };

  return (
    <Dialog open={detailOpen} fullWidth>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => handleModalClose()}>
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0",
            textAlign: "center",
            color: "#9333EA",
            fontWeight: "bold",
          }}
        >
          Action Item Detail
        </h1>

        {!activeActionItem ? (
          ""
        ) : (
          <div>
            <div style={{ margin: "1rem 0" }}>
              <div
                style={{
                  border: "2px solid #9333EA",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "1rem",
                }}
              >
                <div>
                  <span style={{ fontWeight: "bold", color: "#9333EA" }}>
                    Acting User Name:{" "}
                  </span>{" "}
                  <span>
                    {!activeActionItem.acting_user
                      ? ""
                      : activeActionItem.acting_user.name}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: "bold", color: "#9333EA" }}>
                    Due Date:
                  </span>{" "}
                  <span>
                    {new Date(activeActionItem.due_date).toLocaleString()}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: "bold", color: "#9333EA" }}>
                    Status:
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {activeActionItem.status}{" "}
                    {activeActionItem.status === "done" ? (
                      <Check style={{ color: "#16A34A" }} />
                    ) : (
                      <HourglassBottom style={{ color: "#FFA500" }} />
                    )}
                  </span>
                </div>
                <div>
                  <p>
                    <span style={{ fontWeight: "bold", color: "#9333EA" }}>
                      Detail:{" "}
                    </span>
                    {activeActionItem.action_detail}
                  </p>
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <p>
                  <strong>Case:</strong> {activeActionItem.on_case.client.name}
                  &nbsp;{" "}
                  <Tooltip title="View Client Detail">
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={() =>
                        navigateToDetail(activeActionItem.on_case.client)
                      }
                    >
                      <Launch />
                    </IconButton>
                  </Tooltip>
                </p>{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>
                    <strong>Phone:</strong>{" "}
                    {activeActionItem.on_case.client.phone}
                    &nbsp;
                  </h3>
                  <ContactControls
                    number={activeActionItem.on_case.client.phone}
                  />
                </div>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Assigned By: </span>{" "}
                <span>{activeActionItem.created_by.name}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Created At:</span>{" "}
                <span>
                  {new Date(activeActionItem.created_at).toLocaleString()}
                </span>
              </div>
              {!activeActionItem.on_firm ? (
                ""
              ) : (
                <div style={{ marginBottom: "1rem" }}>
                  <h3
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    <strong>Law Firm:</strong> {activeActionItem.on_firm.name}
                  </h3>{" "}
                  <ul style={{ textDecoration: "none" }}>
                    <li>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3>
                          <strong>Phone:</strong>{" "}
                          {activeActionItem.on_firm.phone}&nbsp;
                        </h3>
                        <ContactControls
                          number={activeActionItem.on_firm.phone}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              {!activeActionItem.on_provider ? (
                ""
              ) : (
                <div>
                  <h3
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    <strong>Provider:</strong>{" "}
                    {activeActionItem.on_provider.name}
                  </h3>{" "}
                  <ul style={{ textDecoration: "none" }}>
                    <li>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3>
                          <strong>Phone:</strong>{" "}
                          {activeActionItem.on_provider.phone}&nbsp;
                        </h3>
                        <ContactControls
                          number={activeActionItem.on_provider.phone}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
