import { Warning } from "@mui/icons-material";
import { Button, Rating, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ContactControls from "../../../../../../global/ContactControls";
import ResponseModal from "../../../../../../global/ResponseModal";
import ProviderSearchContext from "../../../../../../../context/ProviderSearchContext";
import { useNavigate } from "react-router-dom";
import HoursOfOperation from "./HoursOfOperation";

export default function ProviderDetailv2({ providerDetail }) {
  const { userType, accessToken, trigger, setTrigger, userRoles } =
    useContext(DataContext);
  const { setProvider, setActiveProviders } = useContext(ProviderSearchContext);
  const [ratingSuccess, setRatingSuccess] = useState(false);
  const [ratingErr, setRatingError] = useState(false);
  const [ratingErrRes, setRatingErrRes] = useState([]);

  const navigate = useNavigate();

  const navToProCliSearch = (prov) => {
    setProvider([prov.pk]);
    setActiveProviders([prov.name]);
    navigate(`/provider-client-search`);
  };

  const handleRatingChange = (event, val) => {
    const patchData = {
      rating: val,
    };

    axios
      .patch(
        `/api/provider/${event.target.name}/`,
        patchData,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setRatingSuccess(true);
        setTrigger(!trigger);
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setRatingError(true);
          setRatingErrRes(errArr);
        }
      });
  };

  return (
    <>
      {!providerDetail ? (
        'Please go back to "Provider List" an select a provider to contintue'
      ) : (
        <div className="flex rounded-b-lg p-0">
          <div className="text-left">
            <h3 className="my-2">
              <strong>Name:</strong> {providerDetail.name}
            </h3>
            <h3>
              {providerDetail.in_network === true ? (
                <span className="text-purple-600">
                  <strong>In-Network</strong> {providerDetail.type}{" "}
                </span>
              ) : (
                <span className="text-red-700">
                  <strong>Out-of-Network</strong> {providerDetail.type}{" "}
                </span>
              )}
            </h3>
            <h3 className="my-2">
              <strong>Address:</strong> {providerDetail.address.raw}
            </h3>
            <h3 className="my-2">
              <strong>Email:</strong> {providerDetail.email}
            </h3>
            <div className="flex justify-between items-center">
              <h3>
                <strong>Phone:</strong> {providerDetail.phone}
              </h3>
              <ContactControls number={providerDetail.phone} />
            </div>
            {userRoles.permissions.includes("provider.view_provider") ? (
              <div className="flex items-center">
                <h3>
                  <strong>Rating:</strong>
                </h3>
                <Rating
                  name={`${providerDetail.pk}`}
                  value={+providerDetail.rating}
                  className="flex justify-center"
                  disabled={
                    userRoles.permissions.includes("provider.change_provider")
                      ? false
                      : true
                  }
                  onChange={(event, newValue) => {
                    handleRatingChange(event, newValue);
                  }}
                />
                {providerDetail.rating === null ? (
                  <Tooltip title="Not yet rated">
                    <Warning style={{ color: "red" }} />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div className="my-2 border-purple-600 p-2 text-center border rounded-sm">
              <strong>Provider Summary:</strong> <p>{providerDetail.notes}</p>
            </div>
            {userRoles.permissions.includes(
              "provider.view_providerofficehours"
            ) ? (
              <HoursOfOperation providerDetail={providerDetail} />
            ) : (
              ""
            )}
            {userType === "Provider" ? (
              ""
            ) : (
              <div className="mx-auto mt-3 w-fit">
                <Button
                  onClick={() => navToProCliSearch(providerDetail)}
                  variant="outlined"
                  className="border-purple-600 text-center font-bold text-purple-600"
                >
                  View All Clients
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <ResponseModal
        title="Rating Updated"
        description="Provider Rating has been Updated"
        openBool={ratingSuccess}
        setOpenBool={setRatingSuccess}
      />
      <ResponseModal
        title="Update Rating Failed"
        isError={true}
        openBool={ratingErr}
        setOpenBool={setRatingErrRes}
        errorMessage={ratingErrRes}
      />
    </>
  );
}
