import { Check, Close, Delete } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useState } from "react";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import ModalShell from "../../../../../../global/ModalShell";

export default function HourWindowEditRow({
  index,
  activeWindow,
  provider,
  dayKey,
  trigger,
  setTrigger,
  formValues,
  setFormValues,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const postNewTimeWindow = () => {
    let start = new Date(startTime).toTimeString();
    let end = new Date(endTime).toTimeString();
    const postData = {
      provider: provider,
      day: dayKey,
      start_time: start.substring(0, start.length - 34),
      end_time: end.substring(0, end.length - 34),
    };

    axios
      .post(`/api/provider_office_hours/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Hours Added");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const patchExistingTimeWindow = () => {
    let start = new Date(startTime).toTimeString();
    let end = new Date(endTime).toTimeString();

    const patchData = {
      start_time: !startTime
        ? activeWindow.start_time
        : start === "Invalid Date"
        ? ""
        : start.substring(0, start.length - 34).length === 0
        ? activeWindow.start_time
        : start.substring(0, start.length - 34),
      end_time: !endTime
        ? activeWindow.end_time
        : end === "Invalid Date"
        ? ""
        : end.substring(0, end.length - 34).length === 0
        ? activeWindow.end_time
        : end.substring(0, end.length - 34),
    };

    axios
      .patch(`/api/provider_office_hours/${activeWindow.id}/`, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Hours Updated");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const deleteExistingTimeWindow = () => {
    axios
      .delete(`/api/provider_office_hours/${activeWindow.id}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Hour Window Deleted");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setLoading(false);
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
    setConfirmDelete(false);
  };

  const closeNewResModal = () => {
    if (isError) {
      setLoading(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    } else {
      setLoading(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
      removeFormFields(index);
      setTrigger(!trigger);
    }
  };

  return (
    <div className="flex items-center">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex">
          <TimePicker
            label="Start of Availability"
            value={
              !activeWindow
                ? startTime
                : new Date(`2024-06-25T${activeWindow.start_time}`)
            }
            className="w-full my-4 mx-auto text-black mr-2"
            slotProps={{ textField: { variant: "outlined" } }}
            onChange={(newValue) => setStartTime(newValue)}
          />{" "}
          <TimePicker
            label="End of Availability"
            value={
              !activeWindow
                ? endTime
                : new Date(`2024-06-25T${activeWindow.end_time}`)
            }
            className="w-full my-4 mx-auto text-black"
            slotProps={{ textField: { variant: "outlined" } }}
            onChange={(newValue) => setEndTime(newValue)}
          />{" "}
        </div>
      </LocalizationProvider>
      <Tooltip title="Submit/Update time window">
        <IconButton
          onClick={() => {
            !activeWindow
              ? postNewTimeWindow(index)
              : patchExistingTimeWindow();
          }}
          className="text-green-600"
          disabled={loading}
        >
          <Check />
        </IconButton>
      </Tooltip>
      {!activeWindow ? (
        <Tooltip title="Cancel add">
          <IconButton onClick={() => removeFormFields(index)}>
            <Close />
          </IconButton>
        </Tooltip>
      ) : userRoles.permissions.includes(
          "provider.delete_providerofficehours"
        ) ? (
        <Tooltip title="Delete existing time window">
          <IconButton
            onClick={() => setConfirmDelete(true)}
            className="text-red-600"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={!activeWindow ? closeNewResModal : closeResModal}
      />
      {!activeWindow ? (
        ""
      ) : (
        <ModalShell
          open={confirmDelete}
          setOpen={setConfirmDelete}
          color={"red"}
          title="Confirm time window removal"
        >
          <div>
            <p className="text-center">
              Are you sure you want to remove this time window?
            </p>
            <p className="text-center font-bold">
              {new Date(
                `2024-06-25T${activeWindow.start_time}`
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {new Date(
                `2024-06-25T${activeWindow.end_time}`
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
            </p>
            <div className="w-fit mx-auto my-2">
              {" "}
              <Button
                onClick={() => deleteExistingTimeWindow()}
                endIcon={<Delete />}
                variant="outlined"
                className="text-red-600 border-red-600"
                disabled={loading}
              >
                Confirm Delete
              </Button>
            </div>
          </div>
        </ModalShell>
      )}
    </div>
  );
}
