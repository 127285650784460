import {
  Cake,
  CarCrash,
  Close,
  EditOutlined,
  Email,
  Help,
  Language,
  LocationOn,
  Person,
  Phone,
  PhoneDisabled,
  PhoneIphone,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ContactControls from "../../../../../../global/ContactControls";
import ContactControlsMobile from "../../../../../../global/ContactControlsMobile";
import ModalButton from "../../../../../../global/ModalButton";
import PlacesResponseList from "../../../../../../global/PlacesResponseList";
import ResponseModal from "../../../../../../global/ResponseModal";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
import { useParams } from "react-router-dom";
import { user } from "../../../../../../api/endpoints/user";
import { SelectOptionsDataProcessor } from "../../../../../../global/SelectOptionsDataProcessor";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import GenderDropdown from "../../../../../../global/FormComponents/FormPresets/Gender/GenderDropdown";
import NamePrefixDropdown from "../../../../../../global/FormComponents/FormPresets/NamePrefix/NamePrefixDropdown";

export default function ClientDetailSummary({
  printable,
  caseInfo,
  clientLawfirm,
  languageOptions,
  openCalModal,
  languageObj,
}) {
  const {
    selectedClient,
    trigger,
    setTrigger,
    accessToken,
    caseManagerPk,
    casePk,
    userRoles,
  } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [dob, setDOB] = useState("");
  const [updatedDOB, setUpdatedDOB] = useState("");
  const [updatedDOI, setUpdatedDOI] = useState("");
  const [suggestsActive, setSuggestsActive] = useState(false);
  const [openLfDetails, setOpenLfDetails] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [editActive, setEditActive] = useState(false);
  const [copiedAlert, setCopiedAlert] = useState(`Click to Copy`);
  const [clientInfo, setClientInfo] = useState("");
  const [language, setLanguage] = useState("Unknown");
  const [gender, setGender] = useState("");
  const [genderOptions, setGenderOptions] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [prefixOptions, setPrefixOptions] = useState(null);
  // const [clientAvailable, setClientAvailable] = useState(false);
  const [availExplanation, setAvailExplanation] = useState("");

  const { width } = useWindowDimensions();

  const params = useParams();

  const handlePlaceSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const handleClientInfoEdit = () => {
    setEditActive(!editActive);
  };
  const handleGenderSelection = (value) =>{
    setGender(value);
  }
  const handlePrefixSelection = (value) =>{
    setPrefix(value);
  }


  const createFieldOptions = useCallback(async(results) =>{
    return SelectOptionsDataProcessor(results, "No Selection");
  },[]);

  const fetchFieldOptionsData = useCallback(async () => {
    const fieldOptionsUrls = [
      user.getGenderFieldOptions(accessToken),
      user.getNamePrefixFieldOptions(accessToken)
      ];
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorMessage(errArr);
      });
  }, [accessToken]);

  useEffect(() => {
    let active = true;

    if (active) {
      if (!genderOptions && !prefixOptions) {
        fetchFieldOptionsData()
        .then(async(results) => {
          if (results) {
            if(results[0]){
              let options = await createFieldOptions(results[0].results);
              setGenderOptions(options);
            }
            if(results[1]){
              let options2= await createFieldOptions(results[1].results);
              setPrefixOptions(options2);
            }
          }
          return results;
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setErrorMessage(errArr);
        });
      }
    }
    return () => {
      active = false;
    };
  }, [genderOptions, 
    prefixOptions, 
    setGenderOptions, 
    setPrefixOptions, 
    createFieldOptions, 
    fetchFieldOptionsData]);

  
  useEffect(() => {
    if (
      !caseInfo ||
      !userRoles.permissions.includes("users.view_usercontacthours")
    ) {
      return;
    } else {
      axios
        .get(`/api/users/${caseInfo.client.pk}/is_available/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then(function (response) {
          // setClientAvailable(response.data.available);
          setAvailExplanation(response.data.message);
        })
        .catch(function (error) {});
    }
  }, [
    caseInfo,
    accessToken,
    setAvailExplanation,
    openCalModal,
    userRoles.permissions,
  ]);

  const handleOpenSuccess = () => {
    let patchedCaseInfo = {
      date_of_accident: !updatedDOI
        ? caseInfo.date_of_accident
        : new Date(updatedDOI).toLocaleDateString("en-CA"),
    };

    let patchedClientInfo = {
      ...(name === "" ? {} : { name: name }),
      email: email,
      ...(phone === "" ? {} : { phone: phone }),
      ...(address === ""
        ? {}
        : {
            address: {
              latitude: !coordinates ? lat : coordinates.lat,
              longitude: !coordinates ? long : coordinates.lng,
              raw: address,
            },
          }),
      ...(language === "Unknown" ? {} : { preferred_language: language }),
      ...(gender === "" ? { gender: null } : { gender: gender }),
      ...(prefix === "" ? { name_prefix: null } : { name_prefix: prefix }),
      ...(dob === ""
        ? {}
        : {
            date_of_birth: !updatedDOB
              ? dob
              : new Date(updatedDOB).toLocaleDateString("en-CA"),
          }),
    };

    axios
      .patch(`/api/users/${selectedClient}/`, patchedClientInfo, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setTrigger(!trigger);
        setEditActive(false);
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
          setOpenError(true);
          // apiError = true;
        }
      });

    axios
      .patch(`/api/case/${casePk}/`, patchedCaseInfo, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setEditActive(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
          setOpenError(true);
          // apiError = true;
        }
      });

    if (!openError) {
      setOpenSuccess(true);
    }
  };

  function replacer(i, val) {
    if (val === null) {
      return ""; // change null to empty string
    } else {
      return val; // return unchanged
    }
  }

  useEffect(() => {
    axios
      .get(`/api/users/${params.id}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = JSON.stringify(response.data, replacer);
        setClientInfo(JSON.parse(data));
        setName(response.data.name);
        setEmail(
          !response.data.email || response.data.email === ""
            ? ""
            : response.data.email
        );
        setPhone(
          !response.data.phone || response.data.phone === ""
            ? ""
            : response.data.phone
        );

        setAddress(
          !response.data.address
            ? ""
            : response.data.address.raw === "" ||
              response.data.address.raw === null
            ? ""
            : String(response.data.address.raw)
        );
        setLat(
          !response.data.address
            ? ""
            : response.data.address.latitude === ""
            ? ""
            : Number(response.data.address.latitude)
        );
        setLong(
          !response.data.address
            ? ""
            : response.data.address.longitude === ""
            ? ""
            : Number(response.data.address.longitude)
        );
        setDOB(response.data.date_of_birth);
        setLanguage(
          !response.data.preferred_language
            ? "Unknown"
            : response.data.preferred_language
        );
        setPrefix(
          !response.data.name_prefix
            ? ""
            : response.data.name_prefix
        );
        setGender(
          !response.data.gender
            ? ""
            : response.data.gender
        );
      });

    // eslint-disable-next-line
  }, [
    accessToken,
    selectedClient,
    setClientInfo,
    casePk,
    caseManagerPk,
    trigger,
  ]);

  const copyToClipboard = (value, name, type) => {
    navigator.clipboard.writeText(value);
    setCopiedAlert(
      `Copied ${name}'s ${type === "phone" ? "phone number" : "email"}`
    );
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!selectedClient ? (
          ""
        ) : (
          <div className="w-full relative">
            {!printable ? (
              <List className="flex w-full">
                <div className="text-start px-2 pt-4 pb-2 w-full">
                  <div className="p-2">
                  <ListItem className="text-left p-0 w-auto pb-2">
                     <div>
                      <span className="font-bold text-sm">Prefix: </span>
                      { caseInfo?.client?.name_prefix && prefixOptions && 
                      prefixOptions.map((item) => item.id === caseInfo.client.name_prefix ?
                      item.name : "")
                      }
                      </div> 
                    </ListItem>
                    <div className="flex justify-between pb-2">
                    
                      <ListItem className="text-left p-0 w-auto">
                        <Tooltip title="Client Name">
                          <div className="flex">
                            <Person className="w-[20px] mr-2" />
                            {clientInfo.name}
                          </div>
                        </Tooltip>
                      </ListItem>
                      {userRoles.permissions.includes("users.change_user") ? (
                        <Tooltip title="Edit Client Details">
                          <Button
                            onClick={handleClientInfoEdit}
                            className="block justify-center text-left p-0 w-[20px] hover:text-white hover:bg-sky-600 min-w-0"
                          >
                            <EditOutlined /> <span className="ml-4">{""}</span>
                          </Button>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                    
                    
                    <ListItem className=" text-left p-0 w-auto pb-2">
                      <div className="flex">
                        {" "}
                        <Email className="w-[20px] mr-2" />{" "}
                        <Tooltip title={copiedAlert} placement="right">
                          <button
                            className="bg-none border-0 mr-2"
                            onClick={() => {
                              copyToClipboard(
                                clientInfo.email,
                                clientInfo.name,
                                "email"
                              );
                            }}
                            onMouseLeave={() => setCopiedAlert("Click to Copy")}
                          >
                            {!clientInfo.email
                              ? "Not Provided"
                              : clientInfo.email}
                          </button>
                        </Tooltip>
                      </div>
                    </ListItem>
                    <ListItem className="text-left p-0 w-auto pb-2">
                      <div className="flex">
                        <PhoneIphone className="w-[20px] mr-2" />
                        <Tooltip title={copiedAlert} placement="right">
                          <button
                            className="bg-none border-0 mr-2"
                            onClick={() => {
                              copyToClipboard(
                                clientInfo.phone,
                                clientInfo.name,
                                "phone"
                              );
                            }}
                            onMouseLeave={() => setCopiedAlert("Click to Copy")}
                          >
                            {clientInfo.phone
                              ? `${clientInfo.phone.slice(
                                  0,
                                  2
                                )} (${clientInfo.phone.slice(
                                  2,
                                  5
                                )}) ${clientInfo.phone.slice(
                                  5,
                                  8
                                )}-${clientInfo.phone.slice(8, 12)}`
                              : "Not Provided"}
                          </button>
                        </Tooltip>
                      </div>
                    </ListItem>
                    <ListItem className="w-fit md:flex hidden">
                      <ContactControls number={clientInfo.phone} />
                      &nbsp;
                      {userRoles.permissions.includes(
                        "users.view_usercontacthours"
                      ) ? (
                        <Tooltip title={availExplanation}>
                          {availExplanation === "No hours set for user" ? (
                            <Help className="text-orange-400" />
                          ) : availExplanation ===
                            "You are not within the user's contact hours" ? (
                            <PhoneDisabled className="text-red-600 block" />
                          ) : (
                            <Phone className="text-green-600 block" />
                          )}
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </ListItem>
                    <ListItem className="w-fit md:hidden flex">
                      <ContactControlsMobile number={clientInfo.phone} />
                      {userRoles.permissions.includes(
                        "users.view_usercontacthours"
                      ) ? (
                        <Tooltip title={availExplanation}>
                          {availExplanation === "No hours set for user" ? (
                            <Help className="text-orange-400" />
                          ) : availExplanation ===
                            "You are not within the user's contact hours" ? (
                            <PhoneDisabled className="text-red-600 block" />
                          ) : (
                            <Phone className="text-green-600 block" />
                          )}
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </ListItem>
                    <ListItem className="text-left p-0 w-auto pb-2">
                      <Tooltip title="Client Address">
                        <div className="flex break-all">
                          <LocationOn className="w-[20px] mr-2" />
                          {address === "" ? "Not Provided" : address}
                        </div>
                      </Tooltip>
                    </ListItem>
                    
                    <ListItem className="text-left p-0 w-auto pb-2">
                     <div>
                      <span className="font-bold text-sm">Gender: </span>
                     { caseInfo?.client?.gender && genderOptions && 
                     genderOptions.map((item) => item.id === caseInfo.client.gender ? 
                     item.name : "") 
                     }
                     </div> 
                    </ListItem>
                    {!caseInfo ? (
                      ""
                    ) : !caseInfo.client.preferred_language ||
                      !userRoles.permissions.includes(
                        "utilities.view_language"
                      ) ? (
                      ""
                    ) : (
                      <ListItem className="text-left p-0 w-auto pb-2">
                        <Tooltip title="Preferred Language">
                          <div className="flex">
                            <Language className="w-[20px] mr-2" />
                            {
                              languageObj[caseInfo.client.preferred_language]
                                .description
                            }
                          </div>
                        </Tooltip>
                      </ListItem>
                    )}
                    <ListItem className="text-left p-0 w-auto pb-2">
                      <Tooltip title="Date of Birth">
                        <div className="flex">
                          <Cake className="w-[20px] mr-2" />
                          {!dob
                            ? "Unknown"
                            : new Date(dob).toLocaleDateString("en-US", {
                                timeZone: "UTC",
                              })}
                        </div>
                      </Tooltip>
                      <Tooltip title="Date of Incident">
                        <div className="flex">
                          <CarCrash className="w-[20px] mx-2" />
                          {!caseInfo
                            ? ""
                            : !caseInfo.date_of_accident
                            ? "Unknown"
                            : new Date(
                                caseInfo.date_of_accident
                              ).toLocaleDateString("en-US", {
                                timeZone: "UTC",
                              })}
                        </div>
                      </Tooltip>
                    </ListItem>
                  </div>
                </div>
              </List>
            ) : (
              <div
                style={{
                  border: "2px solid #9333EA",
                  borderRadius: "5px",
                  margin: "1rem auto",
                  width: "90%",
                  padding: "1rem",
                  textAlign: "center",
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  <span style={{ marginRight: "5px" }}>
                    <Person />
                  </span>
                  {clientInfo.name}
                </div>
                <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <span style={{ marginRight: "5px" }}>
                    <Email />
                  </span>
                  {clientInfo.email}
                </div>
                <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <span style={{ marginRight: "5px" }}>
                    <PhoneIphone />
                  </span>
                  {clientInfo.phone}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span style={{ marginRight: "5px" }}>
                    <LocationOn />
                  </span>
                  <span className="break-word">
                    {address === "" ? "" : address}
                  </span>
                </div>
              </div>
            )}

            <Dialog open={editActive} fullScreen={width < 640 ? true : false}>
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: width < 640 ? "auto" : "400px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    fontSize: "20px",
                  }}
                >
                  <Button onClick={handleClientInfoEdit}>
                    <Close />
                  </Button>
                </div>
                <h1
                  style={{
                    fontSize: 20,
                    color: "orange",
                    margin: "0 0 1rem 0",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Edit Client Details
                </h1>
                <TextField
                  style={{
                    width: "100%",
                    display: `${suggestsActive === true ? "none" : "flex"}`,
                    margin: "1rem 0",
                  }}
                  className="w-full my-4 text-center"
                  placeholder="Client Name" //
                  type="text"
                  autoComplete="off"
                  label="Client Name"
                  value={name}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => setName(event.target.value)}
                />
                <div className="flex justify-items-stretch">
                <NamePrefixDropdown
                  prefix={prefix}
                  setPrefixParent={handlePrefixSelection}
                  optionsList={prefixOptions}
                /> 
                <GenderDropdown
                  gender={gender}
                  setGenderParent={handleGenderSelection}
                  optionsList={genderOptions}
                /> 
                </div>
                <TextField
                  style={{
                    width: "100%",
                    display: `${suggestsActive === true ? "none" : "flex"}`,
                    margin: "1rem 0",
                  }}
                  className="w-full my-4 text-center"
                  placeholder="Client Email" //
                  type="text"
                  autoComplete="off"
                  label="Client Email"
                  value={email}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                  style={{
                    width: "100%",
                    display: `${suggestsActive === true ? "none" : "flex"}`,
                    margin: "1rem 0",
                  }}
                  placeholder="Client Phone"
                  type="text"
                  autoComplete="off"
                  label="Client Phone"
                  value={phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => setPhone(event.target.value)}
                />
                
                {userRoles.permissions.includes("utilities.view_language") ? (
                  <FormGroup
                    style={{
                      width: "100%",
                      padding: "0",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel id="language" className="text-sm">
                      Preferred Language
                    </InputLabel>
                    <Select
                      label="Preferred Language"
                      onChange={(e) => setLanguage(e.target.value)}
                      className="text-sm p-2"
                      value={language}
                    >
                      <MenuItem value={"Unknown"} className="text-sm">
                        Unknown
                      </MenuItem>
                      {!languageOptions
                        ? ""
                        : languageOptions.map((item) => (
                            <MenuItem
                              value={item.pk}
                              key={item.pk}
                              className="text-sm"
                            >
                              {item.description}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormGroup>
                ) : (
                  ""
                )}
                 
                <div
                  style={{
                    width: "100%",
                    padding: "10px 0",
                    display: `${suggestsActive === true ? "none" : "flex"}`,
                  }}
                >
                  <div style={{ width: "49%", marginRight: "8px" }}>
                    <DatePicker
                      label="Date of Birth"
                      value={!updatedDOB ? parseISO(dob) : updatedDOB}
                      onChange={(newValue) => {
                        setUpdatedDOB(newValue);
                      }}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          helperText: !dob ? "DOB Unknown" : "",
                        },
                      }}
                      emptyLabel="Date of Birth"
                    />
                  </div>
                  <div style={{ width: "49%" }}>
                    <DatePicker
                      label="Date of Injury"
                      value={
                        !updatedDOI
                          ? !caseInfo
                            ? ""
                            : parseISO(caseInfo.date_of_accident)
                          : updatedDOI
                      }
                      onChange={(newValue) => {
                        setUpdatedDOI(newValue);
                      }}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          helperText: !caseInfo
                            ? ""
                            : !caseInfo.date_of_accident
                            ? "DOI Unknown"
                            : "",
                        },
                      }}
                    />
                  </div>
                </div>
                <PlacesResponseList
                  address={address}
                  setAddress={setAddress}
                  handlePlaceSelect={handlePlaceSelect}
                  setSuggestsActive={setSuggestsActive}
                />
              </div>
              <div style={{ margin: "0 0 2rem 0" }}>
                <ModalButton
                  action={handleOpenSuccess}
                  baseColor={"#1976d2"}
                  content={"Update"}
                />
              </div>
            </Dialog>
            <ResponseModal
              title="Client Info Updated"
              description="The clients data has been updated"
              openBool={openSuccess}
              setOpenBool={setOpenSuccess}
            />
            <ResponseModal
              title="Error Updating Data"
              isError={true}
              openBool={openError}
              setOpenBool={setOpenError}
              errorMessage={errorMessage}
            />
            <Dialog open={openLfDetails} fullWidth>
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    fontSize: "20px",
                  }}
                >
                  <Button onClick={() => setOpenLfDetails(false)}>
                    <Close />
                  </Button>
                </div>
                <h1
                  style={{
                    fontSize: "24px",
                    margin: "0 0 1rem 0",
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#1976d2",
                  }}
                >
                  Law Firm Detail
                </h1>
                {!clientLawfirm ? (
                  ""
                ) : (
                  <div
                    className="flex rounded-b-lg"
                    style={{ padding: "0 0 2rem 0" }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <h3 className="my-2">
                        <strong>Name:</strong> {clientLawfirm.name}
                      </h3>
                      <h3 className="my-2">
                        <strong>Address:</strong>{" "}
                        {!clientLawfirm.address
                          ? ""
                          : clientLawfirm.address.raw}
                      </h3>
                      <h3 className="my-2">
                        <strong>Email:</strong>{" "}
                        <a
                          href={`mailto:${clientLawfirm.email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {clientLawfirm.email}
                        </a>
                      </h3>
                      <h3 className="my-2">
                        <strong>Lawfirm Website:</strong>{" "}
                        <a
                          href={clientLawfirm.homepage_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {clientLawfirm.homepage_url}
                        </a>
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <h3>
                          <strong>Phone:</strong> {clientLawfirm.phone}
                        </h3>
                        <ContactControls number={clientLawfirm.phone} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Dialog>
          </div>
        )}
      </LocalizationProvider>
    </>
  );
}
