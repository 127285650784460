import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import {
  ListItem,
  Button,
  Tooltip,
  CircularProgress,
  List,
} from "@mui/material/";
import "chart.js/auto";

export default function NoApptsNoBills({ isDashboardVersion }) {
  const { setSelectedClient, accessToken, caseManagerPk } =
    useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [treatingClients, setTreatingClients] = useState([]);
  const [allAppts, setAllAppts] = useState([]);
  const [clientsNoAppt, setClientsNoAppt] = useState(null);
  const [clientsNoBills, setClientsNoBills] = useState(null);

  const navigate = useNavigate();

  const format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  function handleClient(client) {
    const selectedClient = Number(client.client.pk);
    setSelectedClient(Number(client.client.pk));
    navigate(`/client-detail/${selectedClient}`);
    return selectedClient;
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/case/?limit=1000&status=Treating`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setTreatingClients(response.data.results);
      });

    axios
      .get(
        `/api/administered/?limit=1000&administered_by__managed_by=${caseManagerPk}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        let data = response.data.results;
        setClientsNoBills(data);
      });

    axios
      .get(`/api/appointments/?limit=1000&status=Assigned`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setAllAppts(
          response.data.results.filter((appt) => {
            let currentDate = Date.parse(new Date());
            let parseApptDate = Date.parse(appt.scheduled);
            let res;
            if (currentDate < parseApptDate) {
              res = appt.client;
            }
            return res;
          })
        );
      });
  }, [accessToken, caseManagerPk]);

  useEffect(() => {
    let usersWithApts = {};
    let recentAppts = {};
    let byCallBackObj = {};
    let billObj = {};
    for (const item in allAppts) {
      usersWithApts[allAppts[item].client.pk] = allAppts[item].client.pk;
      recentAppts[allAppts[item].client.pk] = allAppts[item].scheduled;
    }

    for (const item in treatingClients) {
      byCallBackObj[treatingClients[item].client] =
        treatingClients[item].callback;
    }

    for (const item in clientsNoBills) {
      billObj[clientsNoBills[item].administered_to.pk] =
        clientsNoBills[item].administered_to.pk;
    }

    let noAppts = treatingClients.filter((item) => {
      let res;
      if (item.client.pk !== usersWithApts[item.client.pk]) {
        res = item;
      }
      return res;
    });
    noAppts = noAppts.filter((item) => {
      let res;
      if (item.client.pk !== billObj[item.client.pk]) {
        res = item;
      }
      return res;
    });
    noAppts = noAppts.filter((appt) => {
      let res;
      if (appt.callback_time === null) {
        res = appt;
      }
      return res;
    });

    setClientsNoAppt(noAppts);
    if (clientsNoAppt === null || clientsNoAppt.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, "3000");
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [allAppts, treatingClients, clientsNoBills, accessToken]);

  const resultClasses =
    "border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0";

  return (
    <div
      className={`pb-4 ${
        !isDashboardVersion
          ? "h-[100%] overflow-hidden"
          : "max-h-[88vh] overflow-auto"
      }`}
    >
      {isDashboardVersion ? (
        ""
      ) : (
        <div
          className={` w-full text-center font-bold text-xl text-purple-600 py-4 shadow-sm`}
        >
          Treating Clients - Need Appointment
        </div>
      )}

      <div className="flex px-4 text-center py-2 border-b border-1 border-gray">
        <div className="md:w-1/4 w-1/3 font-bold">
          <h2 className="md:block hidden">CLIENT NAME</h2>
          <h2 className="md:hidden">NAME</h2>
        </div>
        <div className="md:w-1/4 w-1/3 font-bold hidden md:block">
          <h2>CASE NAME</h2>
        </div>
        <div className="md:w-1/4 w-1/3 font-bold">
          <h2>LAST UPDATED</h2>
        </div>
        <div className="md:w-1/4 w-1/3 font-bold">
          <h2>STATUS</h2>
        </div>
      </div>
      <List
        className={`grid ${
          isDashboardVersion ? `xl:h-[80vh]` : "max-h-[80vh]"
        }  overflow-y-auto `}
      >
        {loading === true ? (
          <div className="flex justify-center my-24">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {clientsNoAppt.length === 0 ? (
              <p className="text-center">No Results</p>
            ) : (
              clientsNoAppt.map((client, index) => (
                <Tooltip title="View Client Details" key={client.pk}>
                  <ListItem className="py-0 pt-0">
                    <Button
                      onClick={() => handleClient(client)}
                      className={
                        "text-black shadow-lg py-1 my-1 w-full flex bg-orange-300 rounded-md border-gray-100 border-y hover:bg-orange-500 hover:text-white justify-between"
                      }
                    >
                      <span
                        className={`block w-1/4 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-left ${resultClasses}`}
                      >
                        {index + 1}.&nbsp;
                        {!client ? "" : client.client.name}
                      </span>
                      <span
                        className={`block w-1/4 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses} hidden md:block`}
                      >
                        {!client ? "" : client.name}
                      </span>
                      <span
                        className={`block w-1/4 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses}`}
                      >
                        {!client.updated_at
                          ? ""
                          : new Date(client.updated_at)
                              .toLocaleString("en-us", format)
                              .split(",")[0]}
                      </span>
                      <span
                        className={`block w-1/4 text-left ${
                          isDashboardVersion ? "text-sm" : "text-base"
                        } pl-2 text-center ${resultClasses} border-none`}
                      >
                        {!client ? "" : client.status}
                      </span>
                    </Button>
                  </ListItem>
                </Tooltip>
              ))
            )}
          </>
        )}
      </List>
    </div>
  );
}
