import React, { useReducer, useContext, useEffect } from "react";
import { callLogReducer } from "./state/callLogReducer";
import { initialState } from "./state/initialState";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { CallLogDispatchContext } from "./context/CallLogDispatchContext";
import { CallLogStateContext } from "./context/CallLogStateContext";
import CallLogPanel from "./components/CallLogPanel";
import qs from "query-string";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import PermissionWrapper from "../../../../../global/PermissionWrapper";
import { useParams } from "react-router-dom";

export default function CallLog({view_calllog}) {
  const { accessToken, caseManagerPk } = useContext(DataContext);
  const [state, dispatch] = useReducer(callLogReducer, initialState);

  const params = useParams();

  let ignore = false;

  async function getCallLog(params, paginationUrl, querytest = "") {
    dispatch({ type: "FETCH_INIT", payload: true });
    await axios
      .get(`/api/call_logs/?&user__in=${params.id}&${paginationUrl}&` + querytest, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        if (!ignore) {
          dispatch({
            type: "FETCH_INPROGRESS",
            payload: response.data.results,
          });
        }
        return response;
      })
      .then((response) => {
        dispatch({ type: "RESULT_COUNT_SET", payload: {
          total: response.data.count,
          current: response.data.results.length,
        }});
        return response;
      })
      .then((response) => {
        dispatch({ type: "FETCH_SUCCESS", payload: true });
        return response;
      })
      .catch((error) => {
        if (!ignore) {
          let res = error.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          dispatch({ type: "FETCH_ERROR", payload: errArr});
        }
      });
  }
  useEffect(() => {
    if (state.loading && state.callLogs !== null) {
      dispatch({ type: "FETCH_DONE", payload: false });
    }
  }, [state.loading, state.callLogs]);

  useDebouncedEffect(
    () => {
      let querytest = qs.stringify(
        { search: !state.logSearch ? null : state.logSearch },
        { skipNull: true }
      );
      getCallLog(params, state.paginationUrl, querytest)
    },
    [
      accessToken, 
      state.logSearch,
      state.paginationUrl
    ],
    250
  );
  const handleChange = (event, value) => {
    dispatch({ type: "CURRENT_PAGE_SET", payload: value });
    
    if (value === 1) {
      return dispatch({ type: "CURRENT_PAGE_URL_SET", payload: `limit=${state.resLimit}&`});
    }
    return dispatch({ type: "CURRENT_PAGE_URL_SET", payload: `limit=${state.resLimit}&offset=${state.resLimit * (value - 1)}&`});
  };


  return (
    <div data-testid="Call-Log">
      <PermissionWrapper permission={view_calllog}>
      {!state.callLogs ? (
        <div data-testid="loading">Loading...</div>
      ) : (
        <CallLogStateContext.Provider value={state}>
          <CallLogDispatchContext.Provider value={dispatch}>
            <CallLogPanel>
              <CallLogPanel.Header />
              <CallLogPanel.Search
                onchange={(text) => dispatch({type:"CALL_LOG_SEARCH", payload:text})}
              />
              {state.errorArray.length > 0 ? 
              <CallLogPanel.Error errorArray={state.errorArray}/>
              :
              <CallLogPanel.List />
              }
              <CallLogPanel.Pagination
                  alignment={"center"}
                  resultCount={state.resultCount}
                  handleChange={handleChange}
                  currentPage={state.currentPage}
                />
            </CallLogPanel>
          </CallLogDispatchContext.Provider>
        </CallLogStateContext.Provider>
      )}
      </PermissionWrapper>
      <PermissionWrapper permission={!view_calllog}>
        <div data-testid="no-access-message">You do not have permission to access any logs.</div>
      </PermissionWrapper>
    </div>
  );
}
