import React, { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function DeleteProviderv2({
  providerDetail,
  setDeleteFormDisplay,
  trigger,
  setTrigger,
}) {
  const { selectedClient, accessToken, setActiveFilterButton } =
    useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [cliprov_id, setCliprov_id] = useState("");

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setDeleteFormDisplay(false);
    setTrigger(!trigger);
    setActiveFilterButton("");
  };
  function handleProviderDelete() {
    axios
      .delete(`/api/pro_cli_status/${cliprov_id}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 500) {
          setErrorMessage(["Unable to remove provider from this users case"]);
        } else if (error) {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setErrorMessage(errArr);
        }
        setOpenError(!openError);
      });
  }

  useEffect(() => {
    axios
      .get(
        `/api/pro_cli_status/?client=${selectedClient}&provider=${providerDetail.pk}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        let procli_list = response.data.results;
        setCliprov_id(procli_list[0].pk);
      });
    // eslint-disable-next-line
  }, [selectedClient, accessToken]);

  return (
    <div className="bg-white">
      {!providerDetail ? (
        "Please Select an Client to Continue"
      ) : (
        <div>
          <span className="text-[20px] font-bold">
            Provider Info
            <br />
            <br />
          </span>
          <div>
            <div>
              <div>
                <span className="font-bold">Provider Name:</span>{" "}
                <span>{providerDetail.name}</span>
              </div>
              <div>
                <span className="font-bold">Provider Phone:</span>{" "}
                <span>{providerDetail.phone}</span>
              </div>
            </div>

            <div>
              <div>
                <span className="font-bold">In Network:</span>{" "}
                <span>
                  {providerDetail.in_network === true ? "True" : "False"}
                </span>
              </div>
              <div>
                <span className="font-bold">Provider Type:</span>{" "}
                <span>{providerDetail.type}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
      <h2 className="font-bold text-lg">
        <span className="text-xl text-red-600 underline font-bold">
          Warning:
        </span>{" "}
        Clicking the "Permanently Remove Provider" button below will permanently
        remove the provider data for this client (including all appointments and
        corresponding procedures) from the database, after proceeding it{" "}
        <span className="text-lg underline">cannot</span> be recovered. Please
        make sure you want to delete the data before proceeding.
      </h2>
      <br />
      <br />
      <ModalButton
        action={handleProviderDelete}
        baseColor={"red"}
        content={"Permanently Remove Provider"}
        icon={"delete"}
      />
      <ResponseModal
        title="Provider Removed"
        description="Successfully Removed Provider from Client's Data"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Provider from Client's Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
