import { useState, useEffect, useContext } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import ModalButton from "../../../../../global/ModalButton";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import ResponseModal from "../../../../../global/ResponseModal";

export default function CreateAppointmentv3({
  setOpenAddAppts,
  activeProvObj,
}) {
  const {
    accessToken,
    setActiveFilterButton,
    selectedClient,
    trigger,
    setTrigger,
    userType,
  } = useContext(DataContext);
  const [dateTimeVal, setDateTimeValue] = useState();
  const [value, setValue] = useState();
  const [scheduledApptStatus, setScheduledApptStatus] = useState("");
  const [scheduledApptNotes, setScheduledApptNotes] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerList, setProviderList] = useState([]);

  const handleChange = (newValue) => {
    if (newValue === null || newValue === undefined) {
      setValue("");
    } else {
      setDateTimeValue(new Date(newValue).toISOString());
    }
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenAddAppts(false);
  };

  //  UseEffects
  useEffect(() => {
    if (selectedClient) {
      axios
        .get(`/api/pro_cli_status/?client=${selectedClient}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          if (userType === "Provider") {
            data = data.filter(
              (item) => item.provider.pk === activeProvObj[item.provider.pk]
            );
          }
          setProviderList(data);
        });
    }
    // eslint-disable-next-line
  }, [selectedClient, accessToken]);

  const handleNewApptSubmit = (e) => {
    let createNewAppt = {
      client: selectedClient,
      provider: selectedProvider,
      scheduled: dateTimeVal,
      status: scheduledApptStatus,
      ...(scheduledApptNotes === "" ? {} : { notes: scheduledApptNotes }),
    };

    axios
      .post("/api/appointments/", createNewAppt, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setValue("");
          setDateTimeValue("");
          setScheduledApptStatus("");
          setScheduledApptNotes("");
          setActiveFilterButton("");
          setSelectedProvider("");
          setTrigger(!trigger);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
          setOpenError(!openError);
        }
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.scheduled) {
      arrayVals.push(`scheduled: ${errorArray.scheduled}`);
    }

    if (errorArray.status) {
      arrayVals.push(`status: ${errorArray.status}`);
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="">
        <div className="w-full px-20 flex-vertical justify-center bg-white">
          <br />
          <DesktopDateTimePicker
            label="Appointment Date"
            value={value}
            className="w-full my-4"
            slotProps={{ textField: { variant: "outlined" } }}
            onChange={handleChange}
          />
          <br />
          <br />
          <FormControl className="w-full flex" style={{ width: "100%" }}>
            <InputLabel id="Provider">Provider</InputLabel>
            <Select
              required
              className="w-full"
              labelId="Provider"
              id="Provider"
              value={selectedProvider}
              label="Provider"
              onChange={(event) => {
                setSelectedProvider(event.target.value);
              }}
            >
              {providerList.map((prov, idx) => {
                return (
                  <MenuItem
                    value={prov.provider.pk}
                    key={idx}
                    onClick={() => setSelectedProvider(prov.provider.pk)}
                  >
                    {prov.provider.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <br />
          <br />
          <FormControl className="w-full flex" style={{ width: "100%" }}>
            <InputLabel id="appt-status">Appointment Status</InputLabel>
            <Select
              required
              className="w-full"
              labelId="appt-status"
              id="Provider-Status"
              value={scheduledApptStatus}
              label="Appt status"
              onChange={(event) => {
                setScheduledApptStatus(event.target.value);
              }}
            >
              <MenuItem value={"Assigned"}>Scheduled</MenuItem>
              <MenuItem value={"Overdue"}>Missed</MenuItem>
              <MenuItem value={"Done"}>Complete</MenuItem>
            </Select>
            <br />
            <h3 className="mt-4">Appointment Notes</h3>
            <Editor
              textareaName="content"
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              value={scheduledApptNotes}
              onEditorChange={(newText) => {
                setScheduledApptNotes(newText);
              }}
              init={{
                menubar: false,
                branding: false,
                plugins: ["lists"],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
              }}
            />
          </FormControl>
          <div style={{ padding: "1rem 0" }}>
            <ModalButton
              action={handleNewApptSubmit}
              baseColor={"#32CD30"}
              content={`Create Appointment`}
              icon={"add"}
            />
          </div>
        </div>
        <ResponseModal
          title="Appointment Created"
          description="Appointment has been added to clients case"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title="Error Creating Appointment"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
