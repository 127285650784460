import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import ProviderSearchContext from "../../../../../../context/ProviderSearchContext";
import ProviderDetailv2 from "../../ClientDetail/ProvApptDetails/ProviderDetailModal/ProviderDetailv2";

export default function ProviderDetailModal({
  providerDetailDisplay,
  setProviderDetailDisplay,
  providerDetail,
}) {
  const { setProvider, setActiveProviders } = useContext(ProviderSearchContext);

  const navigate = useNavigate();

  const navToProCliSearch = (prov) => {
    setProvider([prov.pk]);
    setActiveProviders([prov.name]);
    navigate(`/provider-client-search`);
  };
  return (
    <Dialog open={providerDetailDisplay} fullWidth>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          <Button
            onClick={() => setProviderDetailDisplay(!providerDetailDisplay)}
          >
            <Close />
          </Button>
        </div>
        <h1
          style={{
            fontSize: "24px",
            margin: "0 0 1rem 0",
            textAlign: "left",
            fontWeight: "bold",
            color: "#9333ea",
          }}
        >
          Provider Detail
        </h1>
        <ProviderDetailv2
          providerDetail={providerDetail}
          navToProCliSearch={navToProCliSearch}
        />
      </div>
    </Dialog>
  );
}
