import React, { useContext, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import PermissionWrapper from "../../../PermissionWrapper";
import SelectBasic from "../../SelectBasic";

export default function GenderDropdown({
  gender,
  setGenderParent,
  optionsList,
}) {
  const { userRoles } = useContext(DataContext);
  
  const [options] = useState(optionsList);
  const [selectedGender, setSelectedGender] = useState(
    optionsList.find((g) => g.id === gender)
  );

  const perms = {
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
  };

  const STRINGS = {
    TITLE: "Gender",
  };
  const setSelectedGenderState = (value) => {
    setSelectedGender(value);
    setGenderParent(value.id);
  };

  return (
    <div data-testid="Gender-Dropdown" style={{margin:'0rem 1rem', width:'100%'}}>
      <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
        {options.length > 0 && (
          <SelectBasic
            selectedVal={selectedGender}
            setSelectedVal={setSelectedGenderState}
            placeholder={STRINGS.TITLE}
            title={STRINGS.TITLE}
            optionsData={options}
          />
        )}
      </PermissionWrapper>
    </div>
  );
}
