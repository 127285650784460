import React, { useReducer } from "react";
import { addUserReducer, initialState } from "./state/addUserReducer";
import Form from "./components/Form";
import { Button, IconButton } from "@mui/material";
import { AddUserStateContext } from "./context/AddUserStateContext";
import { AddUserDispatchContext } from "./context/AddUserDispatchContext";
import { toggleSuccessUI, toggleErrorUI, setAPIErrors } from "./state/actions";
import Success from "./components/Success";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import { Close, ControlPointOutlined } from "@mui/icons-material";

export default function AddUser({
  permissionSets,
  submitForm,
  close,
  isStandalone,
  reloadTrigger,
  setReloadTrigger,
}) {
  /**
   * AddUser Component
   *  - Communicates with User Profile Manager.
   *  - Handles Form States, Form Data(User Entered and default values for fields) and API Requests
   *  -- Loading - Renders Loading when Form is busy/not ready to be rendered
   *  -- Form Init - Initiates and Renders Form when ready
   *  -- Form Submit - Gathers Data and Submits User to Backend
   *  -- Success - Shows SUCCESS Message upon Success Submission to API
   *  -- Error - Shows ERROR Message upon unsuccessful Submission to API
   */
  const STRINGS = {
    TITLE: "New Application User",
    SUCCESS: "User Creation Successful!",
    ERROR: "User Creation Failed!",
  };

  const [state, dispatch] = useReducer(addUserReducer, initialState);

  const handleSuccessUI = () => {
    dispatch({ type: "CLEAR_USER" });
    toggleSuccessUI(dispatch, false);
    if (setReloadTrigger) {
      setReloadTrigger(!reloadTrigger);
    }
  };

  const handleErrorUI = () => toggleErrorUI(dispatch, !state.toggleErrorUI);

  const renderSuccess = () => <Success handleOpenSuccess={handleSuccessUI} />;

  const unMountComponent = () => close();

  // const handleSubmitForm = () => {
  //   console.log(state.newUserForm);
  // };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    submitForm(e, state.newUserForm, null)
      .then((res) => {
        toggleSuccessUI(dispatch, true);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response.data);
        setAPIErrors(dispatch, errArr);
        handleErrorUI();
      });
  };

  return (
    <AddUserStateContext.Provider value={state}>
      <AddUserDispatchContext.Provider value={dispatch}>
        <div data-testid="Add-User" className="p-4">
          <div className="flex justify-between">
            <div>&nbsp;</div>
            <h1 className="text-2xl py-2 text-center text-purple-600 uppercase font-bold">
              {STRINGS.TITLE}
            </h1>
            {isStandalone ? (
              <div>&nbsp; </div>
            ) : (
              <IconButton
                data-testid="button-close"
                onClick={(e) => unMountComponent(e)}
              >
                <Close />
              </IconButton>
            )}
          </div>

          {!state.toggleSuccessUI ? (
            <div>
              <Form
                permission_set_list={permissionSets}
                onClickSubmit={handleSubmitForm}
              />
            </div>
          ) : (
            <div className="mx-auto">
              {renderSuccess()}
              {isStandalone ? (
                <div className="my-2 mx-auto w-fit">
                  <Button
                    variant="outlined"
                    onClick={() => handleSuccessUI()}
                    startIcon={<ControlPointOutlined />}
                    className="text-green-800 border-green-800 hover:bg-green-800 hover:text-white hover:border-green-800"
                  >
                    Add another user
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <ResponseModal
            title={STRINGS.ERROR}
            isError={true}
            openBool={state.toggleErrorUI}
            setOpenBool={handleErrorUI}
            errorMessage={state.errorArray}
          />
        </div>
      </AddUserDispatchContext.Provider>
    </AddUserStateContext.Provider>
  );
}
