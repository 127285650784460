import { CloseOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ModalButton from "../../../../../../global/ModalButton";
import ResponseModal from "../../../../../../global/ResponseModal";

import emailjs from "@emailjs/browser";

export default function CaseChecklist({
  isIntake,
  caseInformation,
  clientLawfirm,
}) {
  const {
    casePk,
    trigger,
    setTrigger,
    accessToken,
    caseManagerPk,
    activeLawfirm,
    userRoles,
  } = useContext(DataContext);
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [openSupervisorDialog, setOpenSupervisorDialog] = useState(false);
  const [supervisorReason, setSupervisorReason] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [referralSuccess, setReferralSuccess] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const [openReferralDialog, setOpenReferralDialog] = useState(false);
  const [autoRefNotes, setAutoRefNotes] =
    useState(`<ul class="ul1">\n<li class="li1">Old Vehicle Year: </li>\n<li class="li1">Old Vehicle Make: </li>\n<li class="li1">Old Vehicle Model: </li>\n<li class="li1">Was the totaled car owned outright or paid off: </li>\n<li class="li1">What's the payoff amount of the previous loan (leave blank if no previous loan): </li>\n<li class="li1">PD Amount Approved (to determine the equity in the totaled vehicle): </li>\n</ul>
  `);
  const [autoProvs, setAutoProvs] = useState([]);
  const [autoProvId, setAutoProvId] = useState();
  const [isReferred, setIsReferred] = useState(false);
  const [referredProvider, setReferredProvider] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/users/${caseManagerPk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setActiveUser(response.data);
      });
  }, [accessToken, caseManagerPk]);

  useEffect(() => {
    axios
      .get(`/api/case/${casePk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setSupervisorReason(response.data.supervisor_needed_reason);
      });
  }, [trigger, accessToken, casePk]);

  const handleResolve = () => {
    let patchedData = {
      supervisor_needed_reason: null,
      callback: null,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setOpenSupervisorDialog(!openSupervisorDialog);
          setOpenSuccess(!openSuccess);
          setSupervisorReason("");
        }
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
        setOpenError(!openError);
      });
  };

  const handleOpenSupReason = () => {
    setOpenSupervisorDialog(!openSupervisorDialog);
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const sendEmail = () => {
    var templateParams = {
      CLIENT_NAME: caseInformation.client.name,
      CASE_NAME: caseInformation.name,
      SUPERVISOR_REASON: removeTags(supervisorReason),
      PROGRESSION_MANAGER: activeUser.name,
      LAWFIRM_NAME: clientLawfirm.name,
      LAWFIRM_LINK: `https://app.netproapp.com/`,
    };

    emailjs
      .send(
        "service_l3vhqbw",
        "template_9yr8c7l",
        templateParams,
        "5s77IwCjWSlxjYLSZ"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    if (userRoles.permissions.includes("users.view_user")) {
      axios
        .get(`/api/users/${caseManagerPk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setActiveUser(response.data);
        });
    }

    if (
      userRoles.permissions.includes("auto_providers.view_all_auto_providers")
    ) {
      axios
        .get(`/api/auto/providers/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setAutoProvs(response.data.results);
        });
    }
  }, [caseManagerPk, accessToken, userRoles.permissions]);

  useEffect(() => {
    if (!caseInformation) {
      return;
    } else {
      axios
        .get(`/api/auto/referrals/?case=${caseInformation.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          if (response.data.count > 0) {
            setIsReferred(true);
            setReferredProvider(response.data.results[0].auto_provider.name);
          }
        });
    }
  }, [caseInformation, trigger, accessToken]);

  const handleProgressCheck = (e) => {
    const statusVal = {
      [e.target.name]: e.target.checked,
    };

    const timer = setTimeout(() => {
      axios
        .patch(`/api/case/${casePk}/`, statusVal, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        })
        .then(function (response) {
          setOpenSuccess(true);
          setTrigger(!trigger);
        })
        .catch(function (error) {
          if (error.response) {
            let res = error.response.data;
            let errArr = [];
            for (const item in res) {
              errArr.push(item + `: ${res[item]}`);
            }
            setErrorArray(errArr);
            setOpenError(!openError);
          }
        });
    }, "1000");
    return () => clearTimeout(timer);
  };

  const handleNeedsSupNotes = () => {
    let patchedData = {
      ...(supervisorReason === ""
        ? {}
        : { supervisor_needed_reason: supervisorReason }),
      callback: null,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setTrigger(!trigger);
          setOpenSupervisorDialog(!openSupervisorDialog);

          if (
            activeLawfirm === "DEV TEST" ||
            activeLawfirm === "STAGING TEST"
          ) {
            console.log("Local test, no email needed");
            return;
          } else {
            console.log("im sending email");
            sendEmail();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data.non_field_errors);
          setOpenError(true);
        }
      });
  };

  const handleAutoReferral = () => {
    let postData = {
      auto_provider: autoProvId,
      case: caseInformation.pk,
      notes: autoRefNotes,
    };

    axios
      .post(`/api/auto/referrals/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setReferralSuccess(true);
          setTrigger(!trigger);
          setOpenReferralDialog(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
          setErrorArray(error.response.data.non_field_errors);
          setOpenError(true);
        }
      });
  };
  return (
    <div>
      {" "}
      <div>
        <FormGroup className="w-full mx-2">
          <FormControlLabel
            name="police_report_received"
            disabled={isIntake ? true : false}
            control={
              <Checkbox
                color="success"
                checked={
                  !caseInformation
                    ? false
                    : caseInformation.police_report_received
                }
              />
            }
            onChange={(e) => handleProgressCheck(e)}
            label={<Typography variant="body2">Police Report</Typography>}
          />
          {!userRoles.permissions.includes("users.can_view_supervisor_link") ? (
            ""
          ) : (
            <FormControlLabel
              name="supervisor_needed_reason"
              disabled={isIntake ? true : false}
              control={
                <Checkbox
                  checked={
                    !caseInformation
                      ? false
                      : caseInformation.supervisor_needed_reason
                  }
                  iconstyle={{ fill: "red" }}
                  // inputStyle={{ color: "red" }}
                  style={{ color: "red" }}
                />
              }
              onChange={(e) => handleOpenSupReason(e)}
              label={<Typography variant="body2">Needs Supervisor</Typography>}
            />
          )}
          {userRoles.permissions.includes(
            "auto_providers.view_all_auto_providers"
          ) ? (
            <Tooltip
              title={
                isReferred
                  ? `Client has already been reffered to ${referredProvider}`
                  : "Refer client to Auto Provider"
              }
            >
              <FormControlLabel
                name="auto_referral"
                disabled={isReferred ? true : isIntake ? true : false}
                control={
                  <Checkbox
                    checked={isReferred}
                    iconstyle={{ fill: "#1976d2" }}
                    // inputStyle={{ color: "red" }}
                    style={{ color: "#1976d2" }}
                  />
                }
                onChange={(e) => setOpenReferralDialog(true)}
                label={<Typography variant="body2">Refer to Auto</Typography>}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </FormGroup>
      </div>
      <ResponseModal
        title="Case Info Update Failed"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
      <Dialog open={openSupervisorDialog}>
        <Alert variant="outlined" severity="error" role="alert">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "20px",
              color: "#d32f2f",
              margin: "0 0 1rem",
            }}
          >
            <AlertTitle>Needs Supervisor</AlertTitle>

            <Button onClick={handleOpenSupReason}>
              <CloseOutlined style={{ color: "#d32f2f" }} />
            </Button>
          </div>
          <Editor
            textareaName="content"
            apiKey={process.env.REACT_APP_TINY_MCE_KEY}
            value={supervisorReason}
            onEditorChange={(newText) => {
              setSupervisorReason(newText);
            }}
            init={{
              menubar: false,
              branding: false,
              toolbar: "undo redo",
            }}
          />
          <h2
            style={{
              fontSize: "1rem",
              color: "#d32f2f",
              fontWeight: "bold",
              margin: "1rem 0 0",
            }}
          >
            WARNING
          </h2>
          <p className="">
            By submitting this form, the client information entered here will be
            submitted to supervisors for review. Callback cadence will be
            removed and uneditable until supervisor reviews this form or Needs
            Supervisor status is resolved.
          </p>
        </Alert>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "50%" }}>
            <ModalButton
              baseColor="#d32f2f"
              content={supervisorReason ? "Update" : "Submit"}
              action={handleNeedsSupNotes}
            />
          </div>
          {caseInformation.supervisor_needed_reason ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "center",
                margin: "0 2rem 0",
              }}
            >
              <ModalButton
                baseColor="#FFA500"
                content="Resolve"
                action={handleResolve}
              />
              <p
                style={{
                  fontSize: "10px",
                  width: "90%",
                  textAlign: "center",
                  alignSelf: "center",
                  marginTop: "10px",
                }}
              >
                Please note: Clicking above will clear all previous available
                notes and information involving this supervisor need.{" "}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog>
      <Dialog open={openReferralDialog}>
        <Alert variant="outlined" severity="info" role="alert">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "20px",
              margin: "0 0 1rem",
            }}
          >
            <AlertTitle>Auto Referral</AlertTitle>

            <Button onClick={() => setOpenReferralDialog(false)}>
              <CloseOutlined style={{ color: "#d32f2f" }} />
            </Button>
          </div>
          <div>
            <FormGroup
              className="justify-center p-0 text-sm w-full"
              style={{ width: "100%", margin: "1rem auto", minWidth: "200px" }}
            >
              <InputLabel id="auto_providers" className="text-sm">
                Auto Providers
              </InputLabel>
              <Select
                size="small"
                labelId="auto_providers"
                id="Filter"
                value={!autoProvId ? "" : autoProvId}
                label="Auto Providers"
                onChange={(e) => setAutoProvId(e.target.value)}
                className="text-sm"
                placeholder="Auto Providers"
                style={{ width: "100%" }}
              >
                {!autoProvs
                  ? ""
                  : autoProvs.map((item, idx) => (
                      <MenuItem
                        value={item.pk}
                        className="text-sm"
                        key={idx + Math.random()}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                <MenuItem value={null} className="text-sm">
                  Remove Provider
                </MenuItem>
              </Select>
            </FormGroup>
            <Editor
              textareaName="content"
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              value={autoRefNotes}
              onEditorChange={(newText) => {
                setAutoRefNotes(newText);
              }}
              init={{
                menubar: false,
                branding: false,
                toolbar: "undo redo",
              }}
            />
          </div>
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              margin: "1rem 0 0",
            }}
          >
            WARNING
          </h2>
          <p className="">
            By submitting this form, you are sending client information to an
            auto provider.
          </p>
        </Alert>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "50%" }}>
            <ModalButton
              baseColor="#1976d2"
              content={"Submit Referral"}
              action={handleAutoReferral}
            />
          </div>
        </div>
      </Dialog>
      <ResponseModal
        title="Auto Referral"
        description="Auto referral email sent to shop contact"
        openBool={referralSuccess}
        setOpenBool={setReferralSuccess}
      />
    </div>
  );
}
