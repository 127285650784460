import React, { useContext, useState } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ResponseModal from "../../../../../global/ResponseModal";
import StatusSelectDropdown from "../../../../../global/StatusSelectDropdown";

export default function PdDropdown({ caseInfo, pdChoices }) {
  const { accessToken, trigger, setTrigger, userRoles } =
    useContext(DataContext);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [editPdSev, setEditPdSev] = useState(false);

  const handlePdChange = (e, key) => {
    const patchedData = {
      [key]: e.target.value,
    };
    axios
      .patch(`/api/case/${caseInfo.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setEditPdSev(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const bgVal = (val) => {
    let colorVal;
    if (val === null) {
      colorVal = "bg-red-600";
    } else if (val === 0) {
      colorVal = "bg-orange-600";
    } else if (val === 1) {
      colorVal = "bg-yellow-600";
    } else if (val === 2) {
      colorVal = "bg-green-600";
    } else {
      colorVal = "bg-gray-100";
    }
    return colorVal;
  };

  return (
    <div className="w-1/2">
      {" "}
      <div className="mx-auto px-2">
        <StatusSelectDropdown
          editActive={editPdSev}
          setEditActive={setEditPdSev}
          val={!caseInfo ? "" : caseInfo.property_damage_severity}
          optionsArr={pdChoices}
          title="PD Severity"
          postFunc={
            userRoles.permissions.includes("casemanager.change_case")
              ? handlePdChange
              : false
          }
          valKey="property_damage_severity"
          bgVal={bgVal}
          objDispKey="label"
          objValKey="key"
        />
      </div>
      <ResponseModal
        title="Status Update Failed"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
      <ResponseModal
        title="Status Update Successful"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
      />
    </div>
  );
}
