import React, { useContext } from "react";
import AccordionShell from "../../../../../global/AccordionShell";
import OrgChart from "./OrgChart";
import PermissionsManager from "./PermissionsManager";
import DataContext from "../../../../../../context/DataContext";

export default function OrgPermissions({
  userInfo,
  trigger,
  setTrigger,
  myAccount,
  profile,
}) {
  const { userRoles } = useContext(DataContext);
  return (
    <div className=" shadow-lg p-4 h-fit mr-6 mt-6 rounded-sm">
      <h2 className="text-[1.2rem] font-bold mb-2">
        Permissions and Org Structure
      </h2>
      <div className="ml-2">
        {userRoles.permissions.includes("users.view_permissionset") ? (
          <AccordionShell title="Permissions">
            <PermissionsManager
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              myAccount={myAccount}
            />
          </AccordionShell>
        ) : (
          ""
        )}
        {userRoles.permissions.includes("users.view_usertocaseassignments") ? (
          <AccordionShell title="Organization">
            <OrgChart
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              myAccount={myAccount}
              profile={profile}
            />
          </AccordionShell>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
