import React, { useState } from "react";
import { TextField } from "@mui/material";
import PlacesResponseList from "../../../../../../global/PlacesResponseList";
import ModalButton from "../../../../../../global/ModalButton";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { DatePicker } from "@mui/x-date-pickers";

export default function EditProfileDetails({
  userInfo,
  handleUpdateInfo,
  fullName,
  setFullName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  setAddress,
  dob,
  setDOB,
  setCoordinates,
}) {
  const [suggestsActive, setSuggestsActive] = useState(false);

  const handlePlaceSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  return (
    <div className="md:min-w-[25vw] w-full">
      <TextField
        style={{
          width: "100%",
          display: `${suggestsActive === true ? "none" : "flex"}`,
          margin: "1rem 0",
        }}
        className="w-full my-4 text-center"
        placeholder="Name" //
        type="text"
        label="Name"
        value={fullName ? fullName : userInfo.name ? userInfo.name : ""}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setFullName(event.target.value)}
      />
      <TextField
        style={{
          width: "100%",
          display: `${suggestsActive === true ? "none" : "flex"}`,
          margin: "1rem 0",
        }}
        className="w-full my-4 text-center"
        placeholder="Email" //
        type="text"
        label="Email"
        value={email ? email : userInfo.email ? userInfo.email : ""}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        style={{
          width: "100%",
          display: `${suggestsActive === true ? "none" : "flex"}`,
          margin: "1rem 0",
        }}
        placeholder="Phone" //
        type="text"
        autoComplete="off"
        label="Phone"
        value={phone ? phone : userInfo.phone ? userInfo.phone : ""}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setPhone(event.target.value)}
      />
      <div
        style={{
          width: "100%",
          padding: "10px 0",
          display: `${suggestsActive === true ? "none" : "flex"}`,
        }}
      >
        <div style={{ width: "100%" }}>
          <DatePicker
            label="Date of Birth"
            className="w-full"
            value={
              dob
                ? new Date(dob)
                : userInfo.date_of_birth
                ? new Date(userInfo.date_of_birth)
                : ""
            }
            onChange={(newValue) => {
              setDOB(newValue);
            }}
            slotProps={{ textField: { variant: "outlined" } }}
          />
        </div>
      </div>
      <PlacesResponseList
        address={
          address ? address : userInfo.address ? userInfo.address.raw : ""
        }
        setAddress={setAddress}
        handlePlaceSelect={handlePlaceSelect}
        setSuggestsActive={setSuggestsActive}
      />
      <div style={{ margin: "0 0 2rem 0" }}>
        <ModalButton
          action={handleUpdateInfo}
          baseColor={"#1976d2"}
          content={"Update"}
        />
      </div>
    </div>
  );
}
