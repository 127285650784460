import { Chip, Tooltip } from "@mui/material";

export const CustomChip = ({
  setActiveItems,
  activeItems,
  selectedItems,
  setSelectedItems,
  title,
  resetPagination,
}) => {
  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const clearQuery = (active, selected) => {
    active([]);
    selected([]);
    if (resetPagination) {
      resetPagination();
    }
  };

  return (
    <>
      {activeItems.length === 0 ? (
        ""
      ) : (
        <Tooltip title={selectedItems}>
          <Chip
            className={`px-2 m-1 text-left rounded-lg shadow-inner hover:bg-red-400 py-2`}
            onClick={() => clearQuery(setActiveItems, setSelectedItems)}
            label={
              activeItems.length >= 5
                ? `${title}: ${activeItems.map((prov) =>
                    truncate(prov, 5, 20)
                  )}`
                : `${title}: ` + activeItems
            }
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
