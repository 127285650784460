import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import LoadingIndicator from "../../../../../global/LoadingIndicator";

export default function InteractiveMap({ providers, address, loading }) {
  const { width } = useWindowDimensions();
  const [activeInfoWindow, setActiveInfoWindow] = useState("");

  const markerClicked = (marker, index) => {
    setActiveInfoWindow(index);
  };

  const infoWindowDisplay = (prov) => {
    return (
      <div className="grid p-15">
        <h1 className="font-bold text-lg">{prov.name}</h1>
        <p className="pb-2 text-base	">{prov.phone}</p>
        <p className="">{prov.address.raw}</p>
        <p className="font-bold pt-1 text-lg">
          Distance from client: {prov.distance} miles
        </p>
      </div>
    );
  };

  return (
    <div>
      {" "}
      <GoogleMap
        mapContainerStyle={{
          width: width < 765 ? "95vw" : "45vw",
          height: width < 765 ? "40vh" : "55vh",
        }}
        center={
          !address
            ? {
                lat: 34.03671,
                lng: -84.07817,
              }
            : address
        }
        zoom={10}
      >
        {!providers
          ? ""
          : providers.length === 0
          ? ""
          : providers.map((prov, index) => (
              <Marker
                key={prov.pk}
                position={{
                  lat: prov.address.latitude,
                  lng: prov.address.longitude,
                }}
                options={
                  prov.in_network
                    ? {
                        icon: "https://img.icons8.com/ios-filled/35/9333EA/marker.png",
                      }
                    : {
                        icon: "https://img.icons8.com/ios-filled/35/FFA500/marker.png",
                      }
                }
                // label={prov.label}
                onClick={(event) => markerClicked(prov, index)}
              >
                {activeInfoWindow === index && (
                  <InfoWindow
                    position={{
                      lat: prov.address.latitude,
                      lng: prov.address.longitude,
                    }}
                  >
                    {infoWindowDisplay(prov)}
                  </InfoWindow>
                )}
              </Marker>
            ))}
        {!address ? (
          ""
        ) : (
          <Marker
            position={address}
            options={{
              icon: "https://img.icons8.com/ios-filled/40/1976d2/order-delivered.png",
            }}
          ></Marker>
        )}
      </GoogleMap>
      {loading ? <LoadingIndicator isLoading={loading} /> : ""}
      <div className="hidden">
        <a href="https://icons8.com/icon/53432/here">Here</a> icon by{" "}
        <a href="https://icons8.com">Icons8</a>
        <a href="https://icons8.com/icon/36196/home-address">
          Home Address
        </a>{" "}
        icon by <a href="https://icons8.com">Icons8</a>
      </div>
    </div>
  );
}
