import { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { TextField } from "@mui/material/";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";

export default function CreateServiceProcedurev2({
  selectedAppt,
  setOpenCreateBill,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [procedureName, setProcedureName] = useState("");
  const [procedureCode, setProcedureCode] = useState("");

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setProcedureName("");
    setProcedureCode("");
    setOpenCreateBill(false);
  };

  const handleCreateProcedure = (e) => {
    let patchedService = {
      ...(procedureName === "" ? {} : { service_name: procedureName }),
      ...(procedureCode === "" ? {} : { service_code: procedureCode }),
      ...(procedureCode === "" ? {} : { provider: [selectedAppt.provider.pk] }),
    };

    axios
      .post(`/api/services/`, patchedService, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.service_name) {
      arrayVals.push(`procedure name: ${errorArray.service_name}`);
    }
    if (errorArray.service_code) {
      arrayVals.push(`procedure code: ${errorArray.service_code}`);
    }
    if (errorArray.provider) {
      arrayVals.push(`price: ${errorArray.price}`);
    }

    setErrorMessage(arrayVals);
  }, [errorArray]);

  return (
    <div
      className="w-full px-20 flex-vertical justify-center bg-white"
      style={{ width: "300px", display: "flex", flexDirection: "column" }}
    >
      <h3
        className="my-6 underline"
        style={{ textAlign: "center", padding: "0 0 1rem 0" }}
      >
        Bill Name and Code must be unique.
      </h3>

      <TextField
        className="w-full my-4 text-center"
        id="Procedure/Service Name"
        label="Procedure/Service Name"
        value={procedureName}
        onChange={(event) => {
          setProcedureName(event.target.value);
        }}
      />
      <br />
      <TextField
        className="w-full my-4 text-center"
        id="Procedure/Service Code"
        label="Procedure/Service Code"
        value={procedureCode}
        onChange={(event) => {
          setProcedureCode(event.target.value);
        }}
      />
      <br />

      <div style={{ margin: "0 0 2rem 0" }}>
        <ModalButton
          action={handleCreateProcedure}
          baseColor={"#1976d2"}
          content={"Submit Bill"}
        />
      </div>
      <ResponseModal
        title="Bill Created"
        description="Successfully created new bill type"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Creating Bill Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
