import { StyledEngineProvider } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import PasswordReset from "./components/cm/Dashboard/FullPages/ClientForm/PasswordReset";
import { AuthProvider } from "./context/AuthProvider";
import { DataProvider } from "./context/DataContext";
import { ProCliProvider } from "./context/ProviderSearchContext";
import { SearchProvider } from "./context/SearchContext";
import "./index.css";
import Login from "./login";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import globalRoutes from "./UserRoutes/GlobalRoutes";
const container = document.getElementById("root");
const root = createRoot(container);
const globalPaths = globalRoutes("");

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV || "local",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        Router,
        Routes,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://dev-app.netproapp.com/",
    "https://staging-app.netproapp.com/",
    "https://app.netproapp.com/",
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_RATE,
});
root.render(
  <AuthProvider>
    <DataProvider>
      <SearchProvider>
        <ProCliProvider>
          <StyledEngineProvider injectfirst>
            <Router>
              <Routes>
                <Route index element={<Login />} />
                {globalPaths}
                <Route path="pw-reset" element={<PasswordReset />} />
                <Route path="*" element={<ErrorPage />} status={404} />
              </Routes>
            </Router>
          </StyledEngineProvider>
        </ProCliProvider>
      </SearchProvider>
    </DataProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
