import React, { useReducer, useEffect, useState, useContext } from "react";
import List from "./components/List";
import { userListReducer, initialState } from "./state/userListReducer";
import { setUsers } from "./state/actions";
import DataContext from "../../../../../../context/DataContext";

export default function UserList({
  isLoading,
  data,
  deactivatedUser,
  editUser,
  resetpassword,
  currentPage,
  resultCount,
  pageHandleChange,
  languageObj,
  orderingVal,
  setOrderingVal,
}) {
  /**
   * UserList Component
   *  - Communicates with User Profile Manager.
   *  - Handles List States, List Data and API Requests
   *  -- Loading - Renders Loading when List is busy/not ready to be rendered
   *  -- List Init - Initiates and Renders List when ready
   
   */
  const { userRoles } = useContext(DataContext);
  const [state, dispatch] = useReducer(userListReducer, initialState);
  const [isDataReady, setIsDataReady] = useState(true);

  const columns = [
    "Name",
    "Status",
    "Entity",
    "Phone",
    "Email",
    //"Reports To",
    "Date of Birth",
    "Primary Language",
    "Last Login",
    "Password Updated At",
    "Date Joined",
    "Options",
  ];

  const createRows = async (data) => {
    if (!data) {
      return;
    } else {
      return data.map((user) => {
        return {
          //isSelected: false,
          pk: user.pk,
          photo: user.profile_picture,
          name: user.name,
          status: user.is_active === true ? "Active" : "Not Active",
          title: user.title,
          entity: user.entity?.name,
          phone: user.phone,
          email: user.email,
          //reportsTo: user.managed_by.name,
          dateOfBirth: user.date_of_birth,
          primaryLanguage: user.preferred_language,
          lastLogin: user.last_login,
          passwordUpdatedAt: user.password_updated_at,
          updatedAt: user.updated_at,
          dateJoined: user.date_joined,
          userDetail: user,
          options: [
            {
              displayName: "View/Manage User",
              id: "edit-user",
              permission: userRoles.permissions.includes("users.view_user"),
              userDetail: user,
            },
            // {
            //   displayName: "Reset Password",
            //   id: "reset-password",
            //   permission: userRoles.permissions.includes("users.change_user"),
            //   userDetail: false,
            // },
            {
              displayName: "Deactivate User",
              id: "deactivate-user",
              permission: userRoles.permissions.includes("users.delete_user"),
              userDetail: false,
            },
          ],
        };
      });
    }
  };
  const handleOptionsSelection = (action, user) => {
    console.log(action);

    if (action === "edit-user") {
      editUser(user);
    } else if (action === "reset-password") {
      resetpassword(user.pk);
    } else if (action === "deactivate-user") {
      deactivatedUser(user);

      if (action === "edit-user") {
        editUser(user);
      } else if (action === "reset-password") {
        resetpassword(user.pk);
      } else if (action === "deactivate-user") {
        deactivatedUser(user);
      } else {
      }
    }
  };

  useEffect(() => {
    if (isDataReady) {
      setIsDataReady(false);
      // console.log("what data is beiang returned", data);
      createRows(data)
        .then((res) => {
          setUsers(dispatch, res);
          return res;
        })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err))
        .finally(() => setIsDataReady(true));
    }
  }, [data, isLoading]);

  const getUserList = () => {
    return (
      <List
        rows={state.users}
        columns={columns}
        optionClick={handleOptionsSelection}
        currentPage={currentPage}
        resultCount={resultCount}
        pageHandleChange={pageHandleChange}
        languageObj={languageObj}
        orderingVal={orderingVal}
        setOrderingVal={setOrderingVal}
      />
    );
  };

  return <div data-testid="User-List">{getUserList()}</div>;
}
