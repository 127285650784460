import ApiServices from "../service";

export const case_detail = {
  patch:(casePk, params)=>`/api/case/${!casePk ? params.id : casePk}/`
}

export const case_fee_schedule = {
  retrieve: (id, accessToken) =>
  ApiServices.getData(`/api/case/${id}/fee_schedule/`, accessToken)
    .then((res) => res)
    .then((res) => res)
    .catch(err => console.log(err)),
}
