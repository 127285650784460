import {
  Add,
  Archive,
  Check,
  Close,
  CloseOutlined,
  PinDrop,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  FormGroup,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import qs from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import InlineValueEdit from "../../../../../../global/InlineValueEdit";
import StatusSelectDropdown from "../../../../../../global/StatusSelectDropdown";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import CaseChecklist from "../CaseChecklist";
import AddCallbackButton from "../../AddCallbackButton";
import ModalShell from "../../../../../../global/ModalShell";
import LostStatus from "../CaseColumns/Modals/LostStatus";
import DynamicInput from "../CaseColumns/CaseColumn/EditModal/DynamicInput";
import AutoCompleteSearch from "../CaseColumns/CaseColumn/EditModal/AutoCompleteSearch";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";

export default function CaseInfo({
  printable,
  caseName,
  caseInformation,
  handleCaseStatusSubmit,
  statusEdit,
  setStatusEdit,
  editCaseName,
  setEditCaseName,
  setCaseName,
  handleName,
  clientLawfirm,
  setEditUmStatus,
  editUmStatus,
  handleUmStatusSubmit,
  canEdit,
}) {
  const {
    selectedClient,
    setSelectedClient,
    accessToken,
    trigger,
    setTrigger,
    casePk,
    caseManagerPk,
    userRoles,
  } = useContext(DataContext);
  const [policyLimitAmount, setPolicyLimitAmount] = useState("");
  const [liabilityStatus, setLiabilityStatus] = useState("UNREQ");
  const [crashReport, setCrashReport] = useState("null");
  const [movingViolation, setMovingViolation] = useState("");
  const [damageAmount, setDamageAmount] = useState(null);
  const [editInvolved, setEditInvolved] = useState(false);
  const [editViolation, setEditViolation] = useState(false);
  const [editDamage, setEditDamage] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [caseStatus, setCaseStatus] = useState();
  const [providerMax, setProviderMax] = useState();
  const [editProviderMax, setEditProviderMax] = useState(false);
  const [caseSearchQuery, setCaseSearchQuery] = useState();
  const [caseList, setCaseList] = useState([]);
  const [caseSources, setCaseSources] = useState();
  const [sourcesEdit, setSourcesEdit] = useState(false);
  const [editPolicyLimit, setEditPolicyLimit] = useState(false);
  const [umValue, setUmValue] = useState("");
  const [editUmValue, setEditUmValue] = useState(false);
  const [editLiabilityValue, setEditLiabilityValue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [umStatusChoices, setUmStatusChoices] = useState([]);
  const [liabilityStatusChoices, setLiabilityStatusChoices] = useState([]);
  const [openCbModal, setOpenCbModal] = useState(false);
  const [callback, setCallback] = useState(new Date());
  const [callBackReason, setCallBackReason] = useState("");
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [caseStatusHistory, setCaseStatusHistory] = useState(false);
  const [lostDetails, setLostDetails] = useState("");
  const [openLostModal, setOpenLostModal] = useState(false);
  const [activeDialog, setActiveDialog] = useState({ color: "", title: "" });

  const [stateNameObj, setStateNameObj] = useState({});
  const [activeStateObj, setActiveStateObj] = useState("");
  const [incidentState, setIncidentState] = useState("");
  const [editIncidentState, setEditIncidentState] = useState(false);
  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setEditInvolved(false);
    setTrigger(!trigger);
  };
  const handleOpenError = () => {
    setOpenError(!openError);
  };

  const params = useParams();

  const filterFieldData = (data, field) => {
    let filtered;
    filtered = data.filter((item) => item.field_name === field);
    return filtered;
  };

  const createDisplayObj = (data, field) => {
    let newObj = {};
    for (const item in data) {
      if (data[item].field_name === field) {
        newObj[data[item].key] = data[item].label;
      }
    }
    return newObj;
  };

  const createActiveObj = (data, valKey) => {
    let newObj;

    for (const item in data) {
      if (caseInformation[valKey] === data[item].key) {
        newObj = data[item];
      }
    }
    return newObj;
  };

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
      axios
        .get(`/api/field_options/?content_type=case&ordering=order&limit=500`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setCaseStatusOptions(filterFieldData(data, "status"));
          setLiabilityStatusChoices(filterFieldData(data, "liability_status"));
          setUmStatusChoices(filterFieldData(data, "um_status"));
          setActiveStateObj(
            createActiveObj(
              filterFieldData(data, "incident_location"),
              "incident_location"
            )
          );
          setStateNameObj(
            createDisplayObj(
              filterFieldData(data, "incident_location"),
              "incident_location"
            )
          );
        });
    } else {
      return;
    }
  }, [
    accessToken,
    caseInformation,
    setLiabilityStatusChoices,
    setUmStatusChoices,
    setActiveStateObj,
    setStateNameObj,
  ]);

  const handleSubmit = (e) => {
    setLiabilityStatus(e.target.value);

    const liabilityStat = {
      liability_status: e.target.value,
    };
    axios
      .patch(`/api/case/${casePk}/`, liabilityStat, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function () {
        setTrigger(!trigger);
        setOpenSuccess(true);
        setEditLiabilityValue(false);
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const handleProvMax = () => {
    let patchedData = {
      ...(providerMax === "" ? {} : { provider_max: providerMax }),
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditProviderMax(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const handleViolation = () => {
    let patchedData = {
      ...(movingViolation === "" ? {} : { moving_violation: movingViolation }),
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditViolation(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const handleDamage = () => {
    let patchedData = {
      ...(damageAmount === "" ? {} : { property_damage_amount: damageAmount }),
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditDamage(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const handleIncidentLocUpdate = () => {
    const patchObj = {
      incident_location: !incidentState ? null : incidentState.key,
    };
    axios
      .patch(`/api/case/${casePk}/`, patchObj, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditIncidentState(false);
          setIncidentState("");
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  //* Need to change all patches on this component to just use this one function !!
  const handleCaseValUpdate = (e, key) => {
    let patchedData = {
      [key]: e.target.value,
    };
    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setSourcesEdit(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const sendRequest = () => {
    axios
      .get(
        `/api/case/${casePk}/relatable_cases/?${qs.stringify(
          {
            name_search: !caseSearchQuery ? null : caseSearchQuery,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setCaseList(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [caseSearchQuery],
    250
  );

  useEffect(() => {
    if (userRoles.permissions.includes("leads.view_campaign")) {
      axios
        .get(`/api/leads/campaigns/?limit=100`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setCaseSources(data);
        });
    }
  }, [setCaseSources, accessToken, userRoles.permissions]);

  useEffect(() => {
    setTimeout(() => {
      if (!caseInformation) {
        return;
      } else {
        setLoading(false);
        setPolicyLimitAmount(caseInformation.policy_limit);
        setMovingViolation(caseInformation.moving_violation);
        setDamageAmount(caseInformation.property_damage_amount);
        setLiabilityStatus(caseInformation.liability_status);
        setCrashReport(caseInformation.crash_report_received);
        setCallback(caseInformation.callback_time);
        setProviderMax(caseInformation.provider_max);
        setUmValue(caseInformation.um_value);
        setCaseStatus(caseInformation.status);
      }
    }, "1000");
  }, [
    accessToken,
    selectedClient,
    caseManagerPk,
    trigger,
    casePk,
    caseInformation,
    setCallback,
    userRoles.permissions,
  ]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("casemanager.view_casestatushistory") &&
      caseInformation
    ) {
      axios
        .get(`/api/case/${caseInformation.pk}/status-history/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setCaseStatusHistory(response.data.results[0]);
        })
        .catch((error) => {
          if (error.response.status === 404) setCaseStatus(false);
        });
    }
  }, [
    userRoles.permissions,
    caseInformation,
    setCaseStatusHistory,
    accessToken,
    trigger,
    params,
  ]);

  const addCallback = (type) => {
    let patchedData;
    if (type === "reset") {
      patchedData = {
        callback_time: null,
        callback_reason: null,
      };
    } else {
      patchedData = {
        callback_time: callback,
        callback_reason: callBackReason,
      };
    }

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setOpenCbModal(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const navigateToDetail = (pk) => {
    setSelectedClient(pk);
    navigate(`/client-detail/${pk}`);
    setLostDetails("");
    setCaseStatusHistory("");
  };

  const removeCaseRelationship = (id) => {
    let newInvArr = [];

    caseInformation.related_cases.forEach((element) => {
      newInvArr.push(element.id);
    });

    newInvArr = newInvArr.filter((e) => e !== id);

    let patchedData = {
      related_cases: newInvArr,
    };
    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditInvolved(false);
          setCaseSearchQuery("");
          setTrigger(!trigger);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const addCaseRelationship = (id) => {
    let newInvArr = [];

    caseInformation.related_cases.forEach((element) => {
      newInvArr.push(element.id);
    });

    newInvArr.push(id);

    let patchedData = {
      related_cases: newInvArr,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditInvolved(false);
          setCaseSearchQuery("");
          setTrigger(!trigger);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const handleDialogOpen = (title, color) => {
    setOpenLostModal(true);
    setActiveDialog({ color: color, title: title });
  };

  useEffect(() => {
    if (!caseInformation || !caseInformation.lost) {
      setLostDetails("");
      return;
    } else {
      axios
        .get(`/api/case/${caseInformation.pk}/lost/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setLostDetails(response.data);
        });
    }
  }, [caseInformation, accessToken, setLostDetails, params, trigger]);

  const updatePolicyLimit = () => {
    let patchedData = {
      policy_limit: +policyLimitAmount,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setEditPolicyLimit(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const updateUmValue = (e) => {
    let patchedData = {
      um_value: +umValue,
    };

    axios
      .patch(`/api/case/${casePk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setEditUmValue(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <>
        {!printable ? (
          loading ? (
            <div className="w-full min-h-[45vh] flex justify-center items-center px-2">
              <CircularProgress color="secondary" className="block m-auto" />
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center flex-col">
                <div className="flex">
                  <h2 className="text-l font-bold py-2">Case Name:</h2>
                  <span className="flex">
                    {editCaseName === false ? (
                      <span className="font-bold">
                        <Button
                          onClick={() => setEditCaseName(true)}
                          className="text-black font-bold normal-case text-lg"
                          disabled={canEdit ? false : true}
                        >
                          <Tooltip title="Click to edit case name">
                            {caseName}
                          </Tooltip>
                        </Button>
                      </span>
                    ) : (
                      <span className="flex ml-10">
                        <TextField
                          className="placeholder:text-center rounded-lg w-full"
                          placeholder="Case Name"
                          size="small"
                          value={caseName}
                          InputLabelProps={{ shrink: true }}
                          label="Case Name"
                          onChange={(event) => setCaseName(event.target.value)}
                        />
                        <Button
                          onClick={handleName}
                          className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md"
                        >
                          Save
                        </Button>
                      </span>
                    )}
                  </span>
                </div>
                {!caseInformation ? (
                  ""
                ) : caseInformation.archived === true ? (
                  <div className="border rounded-md bg-red-600 font-bold">
                    <p className="px-4 py-2 text-white">
                      CASE ARCHIVED <Archive />
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={` mb-2 flex md:flex-row flex-col justify-evenly shadow-lg py-4 md:min-h-[45vh]`}
              >
                <div className="md:w-1/2 mx-4">
                  <div className="flex">
                    <div className="flex items-end mr-2">
                      {!statusEdit ? (
                        ""
                      ) : (
                        <FormGroup className="justify-center p-0 text-sm w-full">
                          <InputLabel id="case_status" className="text-sm">
                            Case Status
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="case-status"
                            id="Filter"
                            value={caseStatus}
                            label="Case Status"
                            onChange={(e) => handleCaseStatusSubmit(e)}
                            className="text-sm"
                          >
                            {caseStatusOptions.map((opt) => (
                              <MenuItem
                                value={`${opt.key}`}
                                className="text-sm"
                                key={opt.key}
                                style={{
                                  color: !opt.additional_attributes.color
                                    ? ""
                                    : opt.additional_attributes.color,
                                }}
                              >
                                {opt.order === 0 ? "00" : +opt.order - 1} -{" "}
                                {opt.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormGroup>
                      )}
                      {statusEdit ? (
                        <Button onClick={() => setStatusEdit(false)}>x</Button>
                      ) : (
                        <div>
                          <Tooltip
                            title={
                              canEdit
                                ? "Click to edit status"
                                : "You do not have permissions to edit this content"
                            }
                          >
                            <span>
                              <Button
                                onClick={() => setStatusEdit(true)}
                                disabled={canEdit ? false : true}
                                className={`font-bold normal-case text-black rounded-md
                            ${
                              !caseInformation
                                ? ""
                                : caseInformation.status === "Pending"
                                ? "bg-orange-100"
                                : caseInformation.status ===
                                  "Client Unresponsive"
                                ? "bg-orange-100"
                                : caseInformation.status === "Intake"
                                ? "bg-purple-100"
                                : caseInformation.status === "Scheduled"
                                ? "bg-purple-100"
                                : caseInformation.status === "Treating"
                                ? "bg-purple-100"
                                : caseInformation.status ===
                                  "Waiting on Medical"
                                ? "bg-purple-100"
                                : caseInformation.status ===
                                  "Progression Complete"
                                ? "bg-purple-100"
                                : caseInformation.status === "Waiting on Offer"
                                ? "bg-amber-100"
                                : caseInformation.status ===
                                  "Sent to Trial Attorney"
                                ? "bg-amber-100"
                                : caseInformation.status === "Settled"
                                ? "bg-amber-100"
                                : caseInformation.status === "Disbursed"
                                ? "bg-purple-100"
                                : "bg-gray-100"
                            }`}
                              >
                                {!caseInformation
                                  ? ""
                                  : !caseInformation.status
                                  ? "Add Status"
                                  : !caseStatus
                                  ? caseInformation.status
                                  : caseStatus}
                              </Button>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div>
                      {userRoles.permissions.includes("leads.view_campaign") &&
                      !editIncidentState ? (
                        <StatusSelectDropdown
                          editActive={sourcesEdit}
                          setEditActive={setSourcesEdit}
                          val={
                            !caseInformation
                              ? ""
                              : caseInformation.campaign === null
                              ? ""
                              : caseInformation.campaign
                          }
                          optionsArr={caseSources}
                          title="Campaign"
                          postFunc={!canEdit ? false : handleCaseValUpdate}
                          valKey="campaign"
                          showRemove={true}
                          objDispKey="name"
                          objValKey="pk"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={editIncidentState ? "w-full" : ""}>
                      {!editIncidentState ? (
                        <Tooltip title="Click to edit Incident State">
                          <span>
                            <Button
                              className="font-bold normal-case text-black rounded-md bg-gray-100"
                              startIcon={<PinDrop />}
                              disabled={canEdit ? false : true}
                              onClick={() => setEditIncidentState(true)}
                            >
                              {!caseInformation.incident_location
                                ? "Add Incident State"
                                : stateNameObj[
                                    caseInformation.incident_location
                                  ]}
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <div className="flex items-center">
                          <AutoCompleteSearch
                            apiUrl="/api/field_options/?content_type=case&ordering=label&field_name=incident_location"
                            customLimit={100}
                            keyVal="incident_location"
                            title="Incident State"
                            setVal={setIncidentState}
                            activeEditObj={activeStateObj}
                          />
                          <Tooltip title="Cancel Edit">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEditIncidentState(false);
                                setIncidentState(
                                  caseInformation.incident_location
                                );
                              }}
                            >
                              <Close className="text-red-600" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Submit">
                            <IconButton
                              size="small"
                              onClick={() => handleIncidentLocUpdate()}
                              disabled={!incidentState ? true : false}
                            >
                              <Check
                                className={
                                  incidentState ? "text-green-600" : ""
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  {!caseStatusHistory ? (
                    ""
                  ) : (
                    <div className="text-[10px] w-full mt-2">
                      <span className="block">
                        Updated at:{" "}
                        {new Date(
                          caseStatusHistory.created_at
                        ).toLocaleString()}
                      </span>
                      <span>
                        Updated by:{" "}
                        {!caseStatusHistory.created_by
                          ? "-"
                          : caseStatusHistory.created_by.name}
                      </span>
                    </div>
                  )}

                  <div className="mt-4">
                    <InlineValueEdit
                      title="Moving Violation"
                      inputVal={movingViolation}
                      setInputVal={setMovingViolation}
                      editVal={editViolation}
                      setEditVal={setEditViolation}
                      isIntake={canEdit ? false : true}
                      postFunc={handleViolation}
                    />
                    <InlineValueEdit
                      title="Property Damage Cost"
                      inputVal={damageAmount}
                      setInputVal={setDamageAmount}
                      editVal={editDamage}
                      setEditVal={setEditDamage}
                      isIntake={canEdit ? false : true}
                      postFunc={handleDamage}
                      formatter={formatter}
                    />
                    <InlineValueEdit
                      title="Provider Max"
                      inputVal={providerMax}
                      setInputVal={setProviderMax}
                      editVal={editProviderMax}
                      setEditVal={setEditProviderMax}
                      isIntake={canEdit ? false : true}
                      postFunc={handleProvMax}
                      formatter={formatter}
                    />
                  </div>
                  <h2 className="my-4 font-bold border-b text-center">
                    Policy
                  </h2>
                  <div className="">
                    <StatusSelectDropdown
                      editActive={editUmStatus}
                      setEditActive={setEditUmStatus}
                      val={!caseInformation ? "" : caseInformation.um_status}
                      optionsArr={umStatusChoices}
                      title="UM Status"
                      postFunc={!canEdit ? false : handleUmStatusSubmit}
                      valKey="um_status"
                      objDispKey="label"
                      objValKey="key"
                    />
                    <InlineValueEdit
                      title="UM Value"
                      inputVal={umValue}
                      setInputVal={setUmValue}
                      editVal={editUmValue}
                      setEditVal={setEditUmValue}
                      isIntake={canEdit ? false : true}
                      postFunc={updateUmValue}
                      formatter={formatter}
                    />
                    <InlineValueEdit
                      title="Policy Limit"
                      inputVal={policyLimitAmount}
                      setInputVal={setPolicyLimitAmount}
                      editVal={editPolicyLimit}
                      setEditVal={setEditPolicyLimit}
                      isIntake={canEdit ? false : true}
                      postFunc={updatePolicyLimit}
                      formatter={formatter}
                    />
                  </div>
                </div>
                <div className="flex px-4 md:w-1/2">
                  <div className="justify-center text-center block w-full">
                    <div className="flex items-center flex-wrap">
                      <div className="lg:w-[50%]">
                        <StatusSelectDropdown
                          editActive={editLiabilityValue}
                          setEditActive={setEditLiabilityValue}
                          val={
                            !caseInformation
                              ? ""
                              : caseInformation.liability_status
                          }
                          optionsArr={liabilityStatusChoices}
                          title="Liability Status"
                          postFunc={!canEdit ? false : handleSubmit}
                          valKey="liability_status"
                          objDispKey="label"
                          objValKey="key"
                        />
                      </div>
                      <div className="lg:w-[50%]">
                        <AddCallbackButton
                          caseObj={caseInformation}
                          onclick={() => setOpenCbModal(!openCbModal)}
                          canEdit={canEdit}
                          note={
                            canEdit
                              ? "Add/Edit Callback"
                              : "You do not have permissions to edit this content"
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <CaseChecklist
                        isIntake={canEdit ? false : true}
                        caseInformation={caseInformation}
                        clientLawfirm={clientLawfirm}
                      />
                    </div>
                    {editInvolved === false ? (
                      <div className="mt-4">
                        <div className="flex items-center">
                          <span className="font-bold text-sm">
                            Related Cases:&nbsp;
                          </span>

                          <div className="flex flex-wrap">
                            {!caseInformation
                              ? ""
                              : !caseInformation.related_cases
                              ? ""
                              : caseInformation.related_cases.map((inv) =>
                                  !inv.show_delete ? (
                                    <Chip
                                      className="text-sm m-1"
                                      key={inv.id}
                                      onClick={() =>
                                        navigateToDetail(inv.client_id)
                                      }
                                      variant="outlined"
                                      label={truncate(inv.client_name, 5, 15)}
                                    />
                                  ) : (
                                    <Chip
                                      className="text-sm m-1"
                                      key={inv.id}
                                      onClick={() =>
                                        navigateToDetail(inv.client_id)
                                      }
                                      onDelete={() =>
                                        removeCaseRelationship(inv.id)
                                      }
                                      variant="outlined"
                                      label={truncate(inv.client_name, 5, 15)}
                                    />
                                  )
                                )}
                          </div>
                          <Tooltip
                            title={
                              canEdit
                                ? "Add Involved Case"
                                : "You do not have permissions to edit this content"
                            }
                          >
                            <span>
                              <IconButton
                                onClick={() => setEditInvolved(true)}
                                disabled={canEdit ? false : true}
                              >
                                <Add />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    ) : (
                      <div className="relative">
                        <div className="flex items-center">
                          <TextField
                            className="placeholder:text-center rounded-lg w-full text-sm"
                            placeholder="Case Search"
                            size="small"
                            name="case-search"
                            value={caseSearchQuery}
                            InputLabelProps={{ shrink: true }}
                            label="Case Search"
                            onChange={(event) =>
                              setCaseSearchQuery(event.target.value)
                            }
                          />
                          <IconButton onClick={() => setEditInvolved(false)}>
                            <Close />
                          </IconButton>
                        </div>

                        <div className="absolute max-h-[100px] overflow-y-auto bg-white z-20 shadow-md">
                          {!caseList
                            ? ""
                            : caseList.map((caseItem) => (
                                <Tooltip
                                  title="Add Case Relationship"
                                  key={caseItem.pk}
                                >
                                  <Button
                                    className="text-left block"
                                    onClick={() =>
                                      addCaseRelationship(caseItem.pk)
                                    }
                                  >
                                    {caseItem.client.name}
                                  </Button>
                                </Tooltip>
                              ))}
                        </div>
                      </div>
                    )}
                    <div className="mt-6 flex justify-end">
                      <div>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() =>
                            handleDialogOpen("Report Case Lost", "#d32f2f")
                          }
                          endIcon={
                            caseInformation.lost ? (
                              <Check className="text-red-600" />
                            ) : (
                              ""
                            )
                          }
                          disabled={
                            caseInformation.lost ||
                            !userRoles.permissions.includes(
                              "casemanager.add_loststatushistory"
                            )
                              ? true
                              : false
                          }
                        >
                          Report Case Lost{" "}
                        </Button>
                        {lostDetails ? (
                          <div className="text-[10px]">
                            <span className="block">
                              Updated at:{" "}
                              {new Date(
                                lostDetails.created_at
                              ).toLocaleString()}
                            </span>
                            <span>
                              Updated by: {lostDetails.created_by.name}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <h2
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
                fontWeight: "bold",
                margin: "1rem 0 0",
                padding: "0",
              }}
            >
              Summary
            </h2>
            <div
              style={{
                border: "1px solid #e3ebeb",
                borderRadius: "5px",
                margin: "0 auto 1rem",
                padding: "1rem",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Status: {caseStatus}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Onboarded:{" "}
                    {!caseInformation.onboarded ? (
                      <span style={{ color: "red" }}>False</span>
                    ) : (
                      <span style={{ color: "green" }}>True</span>
                    )}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Police Report:{" "}
                    {!caseInformation.police_report_received ? (
                      <span style={{ color: "red" }}>False</span>
                    ) : (
                      <span style={{ color: "green" }}>True</span>
                    )}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>&nbsp;</p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Policy Limit:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(+caseInformation.policy_limit)}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Provider Max:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(
                        (+caseInformation.provider_max).toFixed(2)
                      )}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>&nbsp;</p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Liability Status:{" "}
                    {liabilityStatus === "ACCEPT"
                      ? "Accepted"
                      : liabilityStatus === "DENY"
                      ? "Denied"
                      : liabilityStatus === "REQ"
                      ? "Requested"
                      : "Unrequested"}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Crash Report:{" "}
                    {crashReport === "null"
                      ? "Not Uploaded"
                      : crashReport === "false"
                      ? "Requested"
                      : "Uploaded to Drive"}
                  </p>
                  {/* <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Involved Individuals:{" "}
                    <span style={{ fontWeight: "normal" }}>{caseInformation.involved}</span>
                  </p> */}
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Moving Violation:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {caseInformation.moving_violation}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Property Damage Cost:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(
                        +caseInformation.property_damage_amount
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Success!</AlertTitle>
            <p>
              Successfully Updated Content
              <br />
            </p>
          </Alert>
          <Button onClick={handleOpenSuccess}>OK</Button>
        </Dialog>
        <Dialog open={openError}>
          <Alert variant="outlined" severity="error" role="error">
            <AlertTitle>Error Updating</AlertTitle>
            <br />
            <div className="">
              {errorArray.map((issue, index) => (
                <ListItem key={index} value={issue}>
                  {issue}
                </ListItem>
              ))}
              <br />
              <h3>
                Fix errors and then resubmit. If errors persist, contact your
                Progression Network Representative.
              </h3>
            </div>
          </Alert>
          <Button onClick={handleOpenError}>OK</Button>
        </Dialog>
        <Dialog open={openCbModal} fullWidth sx={{ top: "25%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {" "}
            <Button onClick={() => setOpenCbModal(false)}>
              <CloseOutlined />
            </Button>
          </div>
          <div style={{ padding: "1rem" }}>
            <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
              Schedule Callback
            </p>
            <DesktopDateTimePicker
              label="Callback Date/Time"
              value={!callback ? new Date() : new Date(callback)}
              className="w-full my-4 mx-auto text-black"
              onChange={(newValue) => setCallback(newValue)}
              slotProps={{ textField: { variant: "outlined" } }}
            />
            <div style={{ margin: "1rem 0 0" }}>
              <TextField
                className="w-full my-4 text-center"
                label="Change Callback Reason/Description"
                style={{ width: "100%" }}
                onChange={(e) => setCallBackReason(e.target.value)}
                value={callBackReason}
              />
            </div>
          </div>
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <Button
              onClick={() => addCallback("reset")}
              style={{ color: "red" }}
            >
              Clear
            </Button>
            <Button onClick={() => addCallback("")}>Submit</Button>
          </div>
        </Dialog>
        <ModalShell
          open={openLostModal}
          setOpen={setOpenLostModal}
          color={activeDialog.color ? activeDialog.color : "#1976d2"}
          title={activeDialog.title}
        >
          <LostStatus
            caseDetail={caseInformation}
            setOpenCustomDialog={setOpenLostModal}
          />
        </ModalShell>
      </>
    </LocalizationProvider>
  );
}
