import React, { useContext, useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import ShowMoreButton from "./ShowMoreButton";
import axios from "../api/axios";
import DataContext from "../../context/DataContext";
import { useDebouncedEffect } from "../hooks/useDebounceEffect";

export default function MultiSelectSearchV2({
  title,
  searchTitle,
  selectedItems,
  setActiveItems,
  activeItems,
  setSelectedItems,
  nameKey,
  valueKey,
  resetPagination,
  apiUrl,
  searchKey,
  orderingVal,
}) {
  const { accessToken } = useContext(DataContext);
  const [showForm, setShowForm] = useState(false);
  const [resLimit, setResLimit] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [loadingRes, setLoadingRes] = useState(false);

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  useDebouncedEffect(
    () => {
      if (!apiUrl || !showForm) {
        return;
      } else {
        setLoadingRes(true);
        let searchQuery = !searchVal
          ? ""
          : `&${!searchKey ? "search" : searchKey}=${searchVal}`;
        axios
          .get(
            `${apiUrl}limit=${resLimit}&ordering=${
              !orderingVal ? "name" : orderingVal
            }${searchQuery}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
            setResponseArray(data);
            setLoadingRes(false);
          });
      }
    },
    [resLimit, searchVal, showForm],
    250
  );

  const handleSelectedItem = (event, info) => {
    let compVar =
      typeof event.target.name === "string"
        ? event.target.name
        : +event.target.name;
    if (selectedItems.includes(compVar)) {
      let activeItemName = info[nameKey];
      setActiveItems(activeItems.filter((e) => e !== activeItemName));
      setSelectedItems(selectedItems.filter((e) => e !== compVar));
    } else {
      setActiveItems([...activeItems, info[nameKey]]);
      setSelectedItems([...selectedItems, compVar]);
    }
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <Button
          onClick={() => setShowForm(!showForm)}
          className="text-left normal-case text-[15px] p-0"
        >
          <span className="text-black">{title}</span>
          {!showForm ? (
            <Add className="text-sky-600" />
          ) : (
            <Remove className="text-sky-600" />
          )}
        </Button>
      </div>
      {!showForm ? (
        ""
      ) : (
        <>
          {" "}
          <div className="relative mt-4">
            <div className="flex items-center">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm"
                placeholder={searchTitle}
                size="small"
                value={searchVal}
                InputLabelProps={{ shrink: true }}
                label={searchTitle}
                onChange={(event) => setSearchVal(event.target.value)}
              />
            </div>
          </div>
          <FormControl
            sx={{ m: 1 }}
            className="max-h-[20vh] overflow-y-auto  w-[100%]"
          >
            {loadingRes ? (
              <CircularProgress
                color="secondary"
                className="block mx-auto my-4"
              />
            ) : (
              <List className="w-[100%]">
                {!responseArray
                  ? ""
                  : responseArray.map((item) => (
                      <ListItem key={item[valueKey]} className="m-0 p-0">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                !selectedItems
                                  ? false
                                  : selectedItems.includes(`${item[valueKey]}`)
                                  ? true
                                  : false
                              }
                              className="text-sm"
                              onClick={(event) =>
                                handleSelectedItem(event, item)
                              }
                              name={`${item[valueKey]}`}
                            />
                          }
                          label={truncate(item[nameKey], 20, 15)}
                        />
                        <div
                          className={`w-[15px] h-[15px] rounded-full ${
                            !item.client && item.entity
                              ? item.entity.entity_type_label ===
                                "Client Case Management"
                                ? "bg-yellow-400"
                                : "bg-purple-600"
                              : ""
                          }`}
                        ></div>
                      </ListItem>
                    ))}
              </List>
            )}

            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={25}
            />
          </FormControl>
        </>
      )}
    </div>
  );
}
