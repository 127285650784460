import { CalendarMonth, FilterAlt } from "@mui/icons-material/";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";

export default function AnalyticsFullV2() {
  const { accessToken } = useContext(DataContext);
  const [timeFrame, setTimeFrame] = useState(1);
  const [chiroScheduled, setChiroScheduled] = useState(0);
  const [mriScheduled, setMriScheduled] = useState(0);
  const [orthoScheduled, setOrthoScheduled] = useState(0);
  const [nnScheduled, setNnScheduled] = useState(0);
  const [chiroMissed, setChiroMissed] = useState(0);
  const [mriMissed, setMriMissed] = useState(0);
  const [orthoMissed, setOrthoMissed] = useState(0);
  const [nnMissed, setNnMissed] = useState(0);
  const [chiroDone, setChiroDone] = useState(0);
  const [mriDone, setMriDone] = useState(0);
  const [orthoDone, setOrthoDone] = useState(0);
  const [nnDone, setNnDone] = useState(0);
  const [startDate, setStartDate] = useState(
    startOfWeek(subWeeks(new Date(), 2))
  );
  const [endDate, setEndDate] = useState(endOfWeek(subWeeks(new Date(), 1)));
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Assigned&provider__type=Chiropractor&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setChiroScheduled(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Overdue&provider__type=Chiropractor&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setChiroMissed(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Done&provider__type=Chiropractor&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setChiroDone(response.data.count);
      });

    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Assigned&provider__type=MRI&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setMriScheduled(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Overdue&provider__type=MRI&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setMriMissed(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Done&provider__type=MRI&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setMriDone(response.data.count);
      });

    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Assigned&provider__type=Orthopedic&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setOrthoScheduled(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Overdue&provider__type=Orthopedic&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setOrthoMissed(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&provider__in_network=true&status=Done&provider__type=Orthopedic&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setOrthoDone(response.data.count);
      });

    //NOT NET API CALLS
    axios
      .get(
        `/api/appointments/?limit=25&status=Assigned&provider__in_network=false&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setNnScheduled(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&status=Overdue&provider__in_network=false&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setNnMissed(response.data.count);
      });
    axios
      .get(
        `/api/appointments/?limit=25&status=Done&provider__in_network=false&scheduled__gte=${startDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00&scheduled__lte=${endDate
          .toISOString()
          .substring(0, 10)}T00%3A00%3A00%2B00%3A00`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setNnDone(response.data.count);
      });
  }, [accessToken, startDate, endDate]);

  const options = {
    plugins: {
      title: {
        display: false,
        text: "xxx",
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
      },
    },
  };

  const setTimeVal = (e) => {
    setTimeFrame(e.target.value);
    if (e.target.value === 0) {
      setStartDate(startOfMonth(subMonths(new Date(), 1)));
      setEndDate(endOfMonth(subMonths(new Date(), 1)));
    } else if (e.target.value === 1) {
      setStartDate(startOfWeek(subWeeks(new Date(), 2)));
      setEndDate(endOfWeek(subWeeks(new Date(), 1)));
    } else {
      setStartDate(startOfWeek(new Date()));
      setEndDate(endOfWeek(new Date()));
    }
    handleClose();
  };

  return (
    <div className="h-full overflow-auto w-full">
      <div className="flex items-center justify-between w-full">
        <div className="w-1/3">&nbsp;</div>
        <div className="font-bold w-1/3 text-center flex items-center justify-center">
          <CalendarMonth />
          {timeFrame === 0
            ? "Previous Month"
            : timeFrame === 1
            ? "Previous 2 Weeks"
            : "This Week"}
        </div>
        <div className="w-1/3 text-right">
          <Tooltip title="Filter">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="text-black"
            >
              <FilterAlt />
            </Button>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              value={0}
              className="text-sm"
              onClick={(e) => setTimeVal(e)}
            >
              Previous Month
            </MenuItem>
            <MenuItem
              value={1}
              className="text-sm"
              onClick={(e) => setTimeVal(e)}
            >
              Previous 2 Weeks
            </MenuItem>
            <MenuItem
              value={2}
              className="text-sm"
              onClick={(e) => setTimeVal(e)}
            >
              This Week
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div
        className={`flex w-full flex-wrap text-center font-bold text-sm py-4 shadow-sm md:flex-row flex-col`}
      >
        <div className="md:w-1/2 border-r">
          <div className="w-full text-lg border-b border-purple mb-2">
            Chiro
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10">Scheduled</div>
            <div className="w-1/3 pl-5">Missed</div>
            <div className="w-1/3">Completed</div>
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10 text-sky-300">{chiroScheduled}</div>
            <div className="w-1/3 pl-5 text-red-300">{chiroMissed}</div>
            <div className="w-1/3 text-green-400">{chiroDone}</div>
          </div>
          <div>
            {" "}
            <Chart
              type="bar"
              data={{
                labels: [
                  // Provider name//
                  "Scheduled",
                  "Missed",
                  "Completed",
                ],
                datasets: [
                  {
                    label: "# of Appointments",
                    data: [
                      //number of clients per Provider
                      chiroScheduled,
                      chiroMissed,
                      chiroDone,
                    ],
                    backgroundColor: [
                      "rgba(14 165 233)",
                      "rgba(234,147,51)",
                      "rgba(51, 234, 147)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={options}
              className="pt-1 "
            />
          </div>
        </div>

        <div className="md:w-1/2 border-r">
          <div className="w-full text-lg border-b border-purple mb-2">MRI</div>
          <div className="flex">
            <div className="w-1/3 pl-10">Scheduled</div>
            <div className="w-1/3 pl-5">Missed</div>
            <div className="w-1/3">Completed</div>
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10 text-sky-300">{mriScheduled}</div>
            <div className="w-1/3 pl-5 text-red-300">{mriMissed}</div>
            <div className="w-1/3 text-green-400">{mriDone}</div>
          </div>
          <div>
            {" "}
            <Chart
              type="bar"
              data={{
                labels: [
                  // Provider name//
                  "Scheduled",
                  "Missed",
                  "Completed",
                ],
                datasets: [
                  {
                    label: "# of Appointments",
                    data: [
                      //number of clients per Provider
                      mriScheduled,
                      mriMissed,
                      mriDone,
                    ],
                    backgroundColor: [
                      "rgba(14 165 233)",
                      "rgba(234,147,51)",
                      "rgba(51, 234, 147)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={options}
              className="pt-1 "
            />
          </div>
        </div>
        <div className="md:w-1/2 border-r">
          <div className="w-full text-lg border-b border-purple mb-2">
            Ortho
          </div>

          <div className="flex">
            <div className="w-1/3 pl-10">Scheduled</div>
            <div className="w-1/3 pl-5">Missed</div>
            <div className="w-1/3">Completed</div>
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10 text-sky-300">{orthoScheduled}</div>
            <div className="w-1/3 pl-5 text-red-300">{orthoMissed}</div>
            <div className="w-1/3 text-green-400">{orthoDone}</div>
          </div>
          <div>
            {" "}
            <Chart
              type="bar"
              data={{
                labels: [
                  // Provider name//
                  "Scheduled",
                  "Missed",
                  "Completed",
                ],
                datasets: [
                  {
                    label: "# of Appointments",
                    data: [
                      //number of clients per Provider
                      orthoScheduled,
                      orthoMissed,
                      orthoDone,
                    ],
                    backgroundColor: [
                      "rgba(14 165 233)",
                      "rgba(234,147,51)",
                      "rgba(51, 234, 147)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={options}
              className="pt-1 "
            />
          </div>
        </div>

        <div className="md:w-1/2 border-r">
          <div className="w-full text-lg border-b border-purple mb-2 text-orange-600">
            Non-Network
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10">Scheduled</div>
            <div className="w-1/3 pl-5">Missed</div>
            <div className="w-1/3">Completed</div>
          </div>
          <div className="flex">
            <div className="w-1/3 pl-10 text-sky-300">{nnScheduled}</div>
            <div className="w-1/3 pl-5 text-red-300">{nnMissed}</div>
            <div className="w-1/3 text-green-400">{nnDone}</div>
          </div>
          <div>
            {" "}
            <Chart
              type="bar"
              data={{
                labels: [
                  // Provider name//
                  "Scheduled",
                  "Missed",
                  "Completed",
                ],
                datasets: [
                  {
                    label: "# of Appointments",
                    data: [
                      //number of clients per Provider
                      nnScheduled,
                      nnMissed,
                      nnDone,
                    ],
                    backgroundColor: [
                      "rgba(14 165 233)",
                      "rgba(234,147,51)",
                      "rgba(51, 234, 147)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={options}
              className="pt-1 "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
