import {
  Close,
  Delete,
  Error,
  Image,
  Info,
  InsertDriveFile,
  Preview,
  UploadFile,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  ListItem,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ModalButton from "../../../../../../../global/ModalButton";
import CustomSearchSelect from "../../../../../../../global/CustomSearchSelect";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import DeleteDocument from "./Modals/DeleteDocument";
import ResponseModal from "../../../../../../../global/ResponseModal";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";

export default function DocumentUpdateListItem({
  document,
  setViewDocument,
  setActiveDoc,
  docTypeOptions,
  docValidation,
  docTrigger,
  setDocTrigger,
  documentTypeObj,
  editType,
  setEditType,
}) {
  const { userType, caseManagerPk, accessToken, userRoles } =
    useContext(DataContext);
  const [documentType, setDocumentType] = useState("");
  const [providerPk, setProviderPk] = useState("");
  const [referralToPk, setReferralToPk] = useState("");
  const [referralFromPk, setReferralFromPk] = useState("");
  const [fileReq, setFileReq] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const handleDocumentType = (e, optObj) => {
    if (!optObj) {
      setFileReq("");
      setDocumentType();
      return;
    } else {
      setFileReq(docValidation.valid_extensions[optObj.value]);
      setDocumentType(optObj);
      if (
        providerPk &&
        !docValidation.required.provider.includes(optObj.value)
      ) {
        setProviderPk("");
      }
      if (
        referralToPk &&
        !docValidation.required.referral_to.includes(optObj.value)
      ) {
        setReferralToPk("");
      }
      if (
        referralFromPk &&
        !docValidation.required.referral_from.includes(optObj.value)
      ) {
        setReferralFromPk("");
      }
    }
  };

  function getExtension(filename) {
    if (filename.includes(".")) {
      return filename.split(".").pop();
    } else {
      return "";
    }
  }
  const photoExtensions = ["png", "jpg", "jpeg"];

  const handleOpenDocument = (document) => {
    setViewDocument(true);
    setActiveDoc(document);
  };

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = (e) => {
    // get the selected file from the input
    e.preventDefault();
    setIsLoading(true);
    // create a new FormData object and append the file to it
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (documentType) {
      formData.append("document_type", documentType.value);
    }
    if (referralToPk) {
      formData.append("referral_to", referralToPk);
    }
    if (referralFromPk) {
      formData.append("referral_from", referralFromPk);
    }
    if (providerPk) {
      formData.append("provider", providerPk);
    }
    let contentType;
    let postData = {
      document_type: documentType.value,
      ...(providerPk === "" ? {} : { provider: providerPk }),
      ...(referralToPk === "" ? {} : { referral_to: referralToPk }),
      ...(referralFromPk === "" ? {} : { referral_from: referralFromPk }),
    };

    if (file) {
      contentType = "multipart/form-data";
      postData = formData;
    } else {
      contentType = "application/json";
    }

    axios
      .patch(`/api/documents/${document.pk}/`, postData, {
        headers: {
          "Content-Type": contentType,
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        // handle the response
        setUploadSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        setUploadFailed(true);
        setIsLoading(false);
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setErrorArray(errArr);
      });
  };

  const clearFileInfo = () => {
    setFile("");
    setDocumentType("");
    setProviderPk("");
    setReferralToPk("");
    setReferralToPk("");
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(false);
    setDocTrigger(!docTrigger);
    setEditType(false);
    clearFileInfo();
  };

  const handleCancelEdit = () => {
    clearFileInfo();
    setEditType(false);
  };

  return (
    <ListItem className="py-0 pt-0 text-sm px-0">
      <div
        style={{
          borderBottom: "1px solid #EBEBEB",
          padding: "5px 0",
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ alignSelf: "center" }}>
          {editType ? (
            <Tooltip
              title={`Current Type: ${documentTypeObj[document.document_type]}`}
            >
              <Info style={{ color: "green" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Document is missing type">
              <Error style={{ color: "red" }} />
            </Tooltip>
          )}
        </div>

        <div style={{ alignSelf: "center" }}>
          <Tooltip title={document.file_name}>
            <span>
              {" "}
              {photoExtensions.includes(getExtension(document.file_name)) ? (
                <Image />
              ) : (
                <InsertDriveFile />
              )}
            </span>
          </Tooltip>
        </div>
        <div
          style={{
            textAlign: "left",
            fontSize: "14px",
            alignSelf: "center",
            width: "15%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              photoExtensions.includes(getExtension(document.file_name))
                ? "View Image"
                : "View Document"
            }
          >
            <IconButton onClick={() => handleOpenDocument(document)}>
              <Preview style={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
          {!documentTypeObj ? "" : documentTypeObj[document.document_type]}{" "}
        </div>
        <div
          style={{
            margin: "10px ",
            display: "flex",
            width: "fit-content",
            borderBottom: "1px solid #1976d2",
          }}
        >
          <Button
            component="label"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            startIcon={<UploadFile />}
          >
            {!file ? <span>&nbsp; Replace File</span> : file.name}

            <input type="file" hidden onChange={handleFileSelect} />
          </Button>
          {!file ? (
            ""
          ) : (
            <Button style={{ color: "red" }} onClick={clearFileInfo}>
              <Close />
            </Button>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            padding: "8px",
            alignSelf: "center",
            width: "20%",
            margin: "0 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {documentType === "Choose Type" ? (
            ""
          ) : (
            <div style={{ marginRight: "5px" }}>
              <p
                style={{
                  fontSize: "12px",
                  color: "grey",
                  textAlign: "center",
                  wordBreak: "break-word",
                }}
              >
                <Tooltip
                  title={`Supported File Extensions - ${fileReq}.  If the current file type doesn't match the required types,
                please delete doc and upload a new version.`}
                >
                  <Info style={{ fontSize: "18px" }} />
                </Tooltip>
              </p>
            </div>
          )}
          <div style={{ margin: "1rem 10px", width: "100%" }}>
            <Autocomplete
              className="w-full"
              disablePortal
              options={!docTypeOptions ? "" : docTypeOptions}
              getOptionLabel={(option) => option.display_name}
              noOptionsText="No Results"
              onChange={(e, value) => {
                handleDocumentType(e, value);
              }}
              value={!documentType ? null : documentType}
              renderOption={(props, option) => {
                return (
                  <li
                    {...props}
                    id={option.display_name}
                    key={option.display_name}
                  >
                    {option.display_name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Document Types" />
              )}
            />
          </div>
        </div>
        {!docValidation || !documentType ? (
          ""
        ) : docValidation.required.provider.includes(documentType.value) ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
              alignSelf: "center",
              width: "20%",
            }}
          >
            {/* <div style={{ margin: "1rem 10px", width: "100%" }}>
            <Autocomplete
              className="w-full"
              disablePortal
              options={!docTypeOptions ? "" : docTypeOptions}
              getOptionLabel={(option) => option.display_name}
              noOptionsText="No Results"
              sx={{ width: 300 }}
              onChange={(e, value) => {
                handleDocumentType(e, value);
              }}
              value={!providerPk ? null : providerPk}
              renderOption={(props, option) => {
                return (
                  <li
                    {...props}
                    id={option.display_name}
                    key={option.display_name}
                  >
                    {option.display_name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Providers" />
              )}
            />
          </div> */}

            <CustomSearchSelect
              selectedVal={providerPk}
              setSelectedVal={setProviderPk}
              searchTitle="Search Client's Providers"
              title="Related Provider"
              nameKey="provider"
              apiUrl={
                userType === "Provider"
                  ? `/api/provider/?managed_by=${caseManagerPk}&`
                  : `/api/pro_cli_status/?case=${document.case}&`
              }
            />
          </div>
        ) : (
          ""
        )}
        {
          (docValidation?.required?.referral_to.includes(documentType?.value) ||
          docValidation?.required?.referral_from.includes(
            documentType.value)) ?
          <div className="w-1/2">
            <div className="mx-4 mt-1 p-4 text-black rounded-sm bg-yellow-100 text-sm">
            <strong>NOTE: </strong>Only providers that are already assigned to the client's case can be chosen below. If the provider needed is not shown below, please add them to the case before uploading the referral file.
          </div>
         
        <div className="flex items-center">
        {!docValidation || !documentType ? (
          ""
        ) : docValidation.required.referral_to.includes(documentType.value) ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
              alignSelf: "center",
              width: "50%",
            }}
          >
            <CustomSearchSelect
              selectedVal={referralToPk}
              setSelectedVal={setReferralToPk}
              searchTitle="Search Providers"
              title="Referred To"
              nameKey="provider"
              apiUrl={`/api/pro_cli_status/?case=${document.case}&`}
            />
          </div>
        ) : (
          ""
        )}
        {!docValidation || !documentType ? (
          ""
        ) : docValidation.required.referral_from.includes(documentType.value) ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
              alignSelf: "center",
              width: "50%",
            }}
          >
            <CustomSearchSelect
              selectedVal={referralFromPk}
              setSelectedVal={setReferralFromPk}
              searchTitle="Search Providers"
              title="Referred From"
              nameKey="provider"
              apiUrl={
                userType === "Provider"
                  ? `/api/provider/?managed_by=${caseManagerPk}&`
                  : `/api/pro_cli_status/?case=${document.case}&`
              }
            />
          </div>
          
        ) : (
          ""
        )}</div></div>
        :
        ""
        }
        {documentType === "Choose Type" ? (
          ""
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
              alignSelf: "center",
              width: "15%",
            }}
          >
            <ModalButton
              className="block"
              action={handleFileUpload}
              baseColor="#16A34A"
              icon="add"
              content={"Submit"}
            />
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            padding: "8px",
            alignSelf: "center",
            width: "auto",
          }}
        >
          <Tooltip placement="right" title={"Delete"}>
            <span>
              <IconButton
                className={`${"text-red-400 hover:bg-red-400 hover:text-white"}`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        {editType ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
              alignSelf: "center",
            }}
          >
            <Tooltip placement="right" title={"Cancel edit "}>
              <span>
                <IconButton
                  className={`${"text-red-400 hover:bg-red-400 hover:text-white"}`}
                  onClick={() => handleCancelEdit()}
                >
                  <Close style={{ color: "red" }} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ) : (
          ""
        )}
      </div>
      <LoadingIndicator isLoading={isLoading} />
      <ResponseModal
        title="Update Success"
        description="The image/document was successfully updated"
        openBool={uploadSuccess}
        setOpenBool={setUploadSuccess}
        handleCloseFunc={handleUploadSuccess}
      />
      <ResponseModal
        title="Update failed"
        isError={true}
        openBool={uploadFailed}
        setOpenBool={setUploadFailed}
        errorMessage={errorArray}
      />
      {!openDeleteModal ? (
        ""
      ) : (
        <DeleteDocument
          document={document}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          getExtension={getExtension}
          docTrigger={docTrigger}
          setDocTrigger={setDocTrigger}
        />
      )}
    </ListItem>
  );
}
