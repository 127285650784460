import React, { useContext, useEffect } from "react";
import { Add, AspectRatioOutlined, Remove } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material/";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import ActionItems from "../../FullPages/ActionItems";
import QuadGlobalUserDash from "../QuadGlobalUserDash";
import renderAvailablePages from "../renderAvailablePages";
import CustomNavChip from "../CustomNavChip";

export default function GlobalUserDash() {
  const {
    isLoading,
    setLoading,
    caseManagerPk,
    setUserType,
    activePath,
    accessToken,
    setPatchNotifs,
    setRefreshDialog,
    setCurrentPatchV,
    setNeedsUpdate,
    dashboardV,
    userRoles,
    dashCompLimit,
    setDashCompLimit,
  } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!caseManagerPk) {
      setLoading(true);
    } else {
      setUserType(Cookies.get("userType"));
      setLoading(false);
    }
  }, [
    accessToken,
    setUserType,
    setCurrentPatchV,
    setRefreshDialog,
    setNeedsUpdate,
    setPatchNotifs,
    setLoading,
    caseManagerPk,
    userRoles.permissions,
  ]);

  const handleNavigateTo = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      {dashboardV === "legacy" ? (
        <QuadGlobalUserDash />
      ) : (
        <>
          {" "}
          {isLoading ? (
            <h1 className="text-center my-10">Loading content...</h1>
          ) : (
            <div className="dark:bg-black flex ">
              <div className="xl:h-screen flex xl:flex-row flex-col w-[100%]">
                {userRoles.permissions.includes(
                  "casemanager.view_actionableitems"
                ) ? (
                  <div className={`flex xl:w-1/2 h-full`}>
                    <div className="p-2 pr-0 flex max-height-full w-full xl:h-full relative">
                      <ActionItems isDashboardVersion={true} />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`flex lg:flex-col flex-col-reverse w-full ${
                    userRoles.permissions.includes(
                      "casemanager.view_actionableitems"
                    )
                      ? "xl:w-1/2"
                      : "h-full overflow-auto"
                  }`}
                >
                  <div className="h-full">
                    {renderAvailablePages(userRoles).length === 0 ? (
                      <div className="h-full flex flex-col justify-center">
                        <h2 className="text-center my-4 text-[1.2rem] font-bold">
                          No available Apps
                        </h2>
                        <p className="text-center">
                          Missing content?{" "}
                          <span className="text-purple-600 font-bold underline">
                            Contact an admin
                          </span>{" "}
                          for assistance.
                        </p>
                      </div>
                    ) : (
                      <div className="flex my-2">
                        <div className="md:pl-[24px] md:w-auto w-[50px]">
                          <Tooltip title={`Navigate to ${activePath}`}>
                            <IconButton
                              onClick={() => handleNavigateTo(activePath)}
                            >
                              <AspectRatioOutlined />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                          {renderAvailablePages(userRoles)
                            .sort((a, b) => a.order - b.order)
                            .slice(0, dashCompLimit)
                            .map((path, idx) => (
                              <CustomNavChip
                                path={path.path}
                                title={path.title}
                                color={path.color}
                                key={idx}
                              />
                            ))}
                          {dashCompLimit >=
                            renderAvailablePages(userRoles).length &&
                          dashCompLimit > 5 ? (
                            <Button
                              className="normal-case text-gray-600 text-sm"
                              onClick={() => setDashCompLimit(5)}
                            >
                              Show less
                              <Remove className="text-sm" />
                            </Button>
                          ) : (
                            <Button
                              className="normal-case text-gray-600 text-sm"
                              onClick={() =>
                                setDashCompLimit(
                                  dashCompLimit +
                                    renderAvailablePages(userRoles).length
                                )
                              }
                            >
                              Show more <Add className="text-sm" />
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    {renderAvailablePages(userRoles)
                      .sort((a, b) => a.order - b.order)
                      .map((comp, idx) => {
                        let activeComp;
                        if (comp.path === activePath) {
                          activeComp = comp.component;
                        }
                        return (
                          <div key={idx} className="lg:max-h-[50vh]">
                            {activeComp}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
