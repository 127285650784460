import AccountBalance from "@mui/icons-material/AccountBalance";
import React from "react";

export default function PrintableNotes({ caseNotes, caseObj, clientLawfirm }) {
  return (
    <div style={{ margin: "1rem 0" }}>
      {!caseObj || !caseNotes ? (
        ""
      ) : (
        <>
          <h2
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            {!caseObj ? "" : caseObj.client.name}
          </h2>
          <h2>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccountBalance />
              <span style={{ display: "block" }}>
                {!clientLawfirm ? "No Assigned Law Firm" : clientLawfirm.name}
              </span>
            </div>
          </h2>
          <div
            style={{
              width: "90%",
              borderBottom: "2px solid #9333EA",
              margin: "auto",
            }}
          >
            <h3
              style={{
                fontSize: "1rem",
                fontWeight: "500",
                textAlign: "center",
                padding: "0 0 10px",
              }}
            >
              Case Notes
            </h3>
          </div>
          <div style={{ width: "80%", margin: "1rem auto" }}>
            {!caseNotes
              ? ""
              : caseNotes.map((note, idx) => (
                  <div
                    style={{
                      borderBottom: "1px solid grey",
                      marginTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                    key={idx}
                  >
                    <p>
                      <strong>Created by:</strong>{" "}
                      {!note.created_by ? "" : note.created_by.name}
                    </p>
                    <p>
                      <strong>Created at:</strong>{" "}
                      {new Date(note.created_at).toLocaleString()}
                    </p>
                    {new Date(note.updated_at).toLocaleString() ===
                    new Date(note.created_at).toLocaleString() ? (
                      ""
                    ) : (
                      <p>
                        <strong>Updated at:</strong>{" "}
                        {new Date(note.updated_at).toLocaleString()}
                      </p>
                    )}
                    <div
                      style={{ padding: "0" }}
                      className="notes"
                      dangerouslySetInnerHTML={{
                        __html: !caseNotes ? "" : note.content,
                      }}
                    ></div>
                  </div>
                ))}
          </div>
        </>
      )}
    </div>
  );
}
