import axios from "./axios";

/**
 * 
 *API Service layer
 * - handles endpoint configuration (headers and methods)
 * - abstracts api calls so the app doesn't have to  know the hows of api requests
 * - allows for ease of changing the functionality of api calls without having to find duplicate code
 * 
 */

async function sendAPIRequest(url, method, headers, data=null) {
  const response = await axios({
    method: method,
    url: url, 
    baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://api.netproapp.com",
    headers: headers,
    data
  });
  return await response.data;
}

const ApiServices =  {
getData: async (url, accessToken) =>{
  return await sendAPIRequest(
    url, 
    'GET', 
  {
    "Content-Type": "application/json",
    Authorization: `Token ${accessToken}`,
  }, 
  accessToken);
},
postData: async function postDataRequest(url, data, accessToken) {
  return await sendAPIRequest(
    url, 
    'POST',
    {
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    }, 
    data);
},
putData: async function putDataRequest(url, data, accessToken) {
  return await sendAPIRequest(
    url, 
    'PUT',
    {
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    }, 
    data);
},
patchData: async function patchDataRequest(url, accessToken) {
  return await sendAPIRequest(url, accessToken);
},
deleteData: async function deleteDataRequest(url, accessToken) {
  return await sendAPIRequest(url, accessToken);
},
optionsData: async (url, accessToken) =>{
  return await sendAPIRequest(
    url, 
    'OPTIONS', 
    {
      Authorization: `Token ${accessToken}`,
    },
    accessToken);
},
};

export default ApiServices;