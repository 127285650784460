import React, { useContext, useEffect, useState } from "react";
import ProviderListItem from "./ProviderListItem";
import {
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaginationControls from "../../../../../global/PaginationControls";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { Info } from "@mui/icons-material";

export default function NearestProvidersList({
  results,
  currentPage,
  handleChange,
  resultCount,
  loading,
  provType,
  setProvType,
  setSelectedProvider,
  resetPagination,
  outOfNetwork,
  setOutOfNetwork,
  setMaxDistance,
  maxDistance,
}) {
  const { accessToken } = useContext(DataContext);
  const [provTypeOptions, setProvTypeOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/field_options/?content_type=provider&ordering=order`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  const providerFilterUpdate = (e) => {
    setProvType(e.target.value);
    setMaxDistance(false);
    setOutOfNetwork(false);
    resetPagination();
  };

  return (
    <div>
      <div className="flex m-2">
        <FormControl className="md:w-1/2 w-full">
          <InputLabel id="demo-simple-select-label">
            Filter by Provider Type:
          </InputLabel>
          <Select
            labelId="filter"
            id="filter"
            value={provType}
            label="filter"
            onChange={(e) => {
              providerFilterUpdate(e);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            {!provTypeOptions
              ? ""
              : provTypeOptions.map((opt) => (
                  <MenuItem key={opt.order} value={opt.key}>
                    {opt.key}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      <div className="max-h-[28vh] overflow-y-auto">
        {loading ? <LoadingIndicator isLoading={loading} /> : ""}
        <List className="mx-2">
          {results.length === 0 ? (
            <div className="w-full">
              <p className="text-center my-2">
                <Tooltip title="Try using a custom address or allow Out of Network Providers to find results">
                  <Info className="text-gray-300 text-[16px]" />
                </Tooltip>
                &nbsp; No Results Found
                <br />
                <div className="ml-2">
                  <FormControlLabel
                    label={"Include out of Network Providers"}
                    control={
                      <Checkbox
                        checked={outOfNetwork}
                        onClick={(e) => {
                          setOutOfNetwork(e.target.checked);
                          setMaxDistance("400");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </div>
                <br />
                <Link
                  variant="outlined"
                  className="text-center mx-auto w-fit"
                  onClick={() => {
                    navigate("/create-provider");
                  }}
                >
                  Add Out of Network Provider
                </Link>{" "}
              </p>
            </div>
          ) : (
            results.map((prov) => (
              <ProviderListItem
                provider={prov}
                key={prov.pk}
                setSelectedProvider={setSelectedProvider}
              />
            ))
          )}
        </List>
      </div>
      <div className="w-fit mx-auto my-2">
        {" "}
        <PaginationControls
          resultCount={resultCount}
          handleChange={handleChange}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}
