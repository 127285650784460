import React, { useContext, useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import ShowMoreButton from "./ShowMoreButton";
import axios from "../api/axios";
import DataContext from "../../context/DataContext";
import { useDebouncedEffect } from "../hooks/useDebounceEffect";

export default function MultiSelectSearch({
  title,
  searchTitle,
  selectedItems,
  setActiveItems,
  activeItems,
  setSelectedItems,
  keyName,
  resetPagination,
  apiUrl,
  searchKey,
}) {
  const { accessToken } = useContext(DataContext);
  const [showForm, setShowForm] = useState(false);
  const [resLimit, setResLimit] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [responseArray, setResponseArray] = useState([]);
  const [loadingRes, setLoadingRes] = useState(false);

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  useDebouncedEffect(
    () => {
      if (!apiUrl || !showForm) {
        return;
      } else {
        setLoadingRes(true);
        let searchQuery = !searchVal
          ? ""
          : `&${!searchKey ? "search" : searchKey}=${searchVal}`;
        axios
          .get(`${apiUrl}limit=${resLimit}&ordering=name${searchQuery}`, {
            headers: { Authorization: `Token ${accessToken}` },
          })
          .then((response) => {
            let data = response.data.results;
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
            setResponseArray(data);
            setLoadingRes(false);
          });
      }
    },
    [resLimit, searchVal, showForm],
    250
  );

  const handleSelectedItem = (event, info) => {
    if (selectedItems.includes(+event.target.name)) {
      let activeItemName = keyName
        ? info[keyName]
        : !info.client
        ? info.name
        : info.client.name;
      setActiveItems(activeItems.filter((e) => e !== activeItemName));
      setSelectedItems(selectedItems.filter((e) => e !== +event.target.name));
    } else {
      setActiveItems([
        ...activeItems,
        keyName ? info[keyName] : !info.client ? info.name : info.client.name,
      ]);
      setSelectedItems([...selectedItems, +event.target.name]);
    }
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <Button
          onClick={() => setShowForm(!showForm)}
          className="text-left normal-case text-[15px] p-0"
        >
          <span className="text-black">{title}</span>
          {!showForm ? (
            <Add className="text-sky-600" />
          ) : (
            <Remove className="text-sky-600" />
          )}
        </Button>
      </div>
      {!showForm ? (
        ""
      ) : (
        <>
          <div>
            <div className="relative mt-4">
              <div className="flex items-center">
                <TextField
                  className="placeholder:text-center rounded-lg w-full text-sm"
                  placeholder={searchTitle}
                  size="small"
                  value={searchVal}
                  InputLabelProps={{ shrink: true }}
                  label={searchTitle}
                  onChange={(event) => setSearchVal(event.target.value)}
                />
              </div>
            </div>

            <FormControl
              sx={{ m: 1 }}
              className="max-h-[20vh] overflow-y-auto  w-[100%]"
            >
              {loadingRes ? (
                <CircularProgress
                  color="secondary"
                  className="block mx-auto my-4"
                />
              ) : (
                <List className="w-[100%]">
                  {!responseArray
                    ? ""
                    : responseArray.map((item) => (
                        <ListItem
                          key={!item.pk ? item.id : item.pk}
                          className="m-0 p-0"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !selectedItems
                                    ? false
                                    : selectedItems.includes(
                                        !item.pk ? item.id : item.pk
                                      )
                                    ? true
                                    : false
                                }
                                className="text-sm"
                                onClick={(event) =>
                                  handleSelectedItem(event, item)
                                }
                                name={`${!item.pk ? +item.id : +item.pk}`}
                              />
                            }
                            label={truncate(
                              keyName
                                ? item[keyName]
                                : !item.client
                                ? item.name
                                : item.client.name,
                              10,
                              15
                            )}
                          />
                          <div
                            className={`w-[15px] h-[15px] rounded-full ${
                              !item.client && item.entity
                                ? item.entity.entity_type_label ===
                                  "Client Case Management"
                                  ? "bg-purple-600"
                                  : item.entity.entity_type_label === "Law Firm"
                                  ? "bg-yellow-400"
                                  : "bg-gray-400"
                                : ""
                            }`}
                          ></div>
                        </ListItem>
                      ))}
                </List>
              )}
              <ShowMoreButton
                showMoreVisible={showMoreVisible}
                setResLimit={setResLimit}
                resLimit={resLimit}
                showMoreAmt={25}
              />
            </FormControl>
          </div>
        </>
      )}
    </div>
  );
}
