import {
  List,
  ListItemButton,
  Button,
  Dialog,
  TextField,
  ListItem,
} from "@mui/material";
import React, { useState, useContext, useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import {
  PersonOutlineOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import ModalButton from "../../../global/ModalButton";
// import { useReactToPrint } from "react-to-print";
import ProviderPrint from "./ProviderPrint";
import PaginationControls from "../../../global/PaginationControls";
import ResponseModal from "../../../global/ResponseModal";
import { Chart } from "react-chartjs-2";

const options = {
  plugins: {
    title: {
      display: true,
      text: "Appointments by Month",
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
      },
    },
  },
};

const graphBgColors = ["rgba(147, 51, 234)"];

export default function ProviderDetailFullPage() {
  const b = "Client List";
  const { setSelectedClient, accessToken, searchProvPk, setSearchProvPk } =
    useContext(DataContext);
  const [clients, setClients] = useState([]);
  const [providerInfo, setProviderInfo] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [dateYear, setDateYear] = useState("2023");
  const [trigger, setTrigger] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);

  const componentRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `${providerInfo.name} Breakdown`,
  //   // onAfterPrint: () => alert("Print Successful!")
  // });
  const containerStyle = {
    width: "100%",
    height: "50vh",
  };
  const [apptData, setApptData] = useState(
    //setting the initial state of this to the object we need to display the chart, then setting the new state using the useEffect below
    {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "# of Appts",
          data: [
            //number of clients per Provider
          ],
          backgroundColor: graphBgColors,
          borderWidth: 1,
        },
      ],
    }
  );

  function decreaseYear() {
    let increaseNum = Number(dateYear) - 1;
    setDateYear(String(increaseNum));
  }

  function increaseYear() {
    let increaseNum = Number(dateYear) + 1;
    setDateYear(String(increaseNum));
  }

  const clickingClients = (client) => {
    setSelectedClient(client.client.pk);
    navigate(`/client-detail/${client.client.pk}`);
  };

  const handleProvInfoEdit = () => {
    setOpenSuccess(!openSuccess);
  };

  const handleOpenDialog = () => {
    setOpenSuccessMessage(false);
    setOpenSuccess(false);
  };

  function sortAppts(appts) {
    let jan = [];
    let feb = [];
    let mar = [];
    let apr = [];
    let may = [];
    let jun = [];
    let jul = [];
    let aug = [];
    let sep = [];
    let oct = [];
    let nov = [];
    let dec = [];

    for (const item in appts) {
      if (
        String(appts[item].scheduled).slice(0, 2) === "01" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        jan.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "02" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        feb.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "03" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        mar.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "04" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        apr.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "05" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        may.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "06" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        jun.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "07" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        jul.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "08" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        aug.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "09" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        sep.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "10" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        oct.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "11" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        nov.push(item);
      }
      if (
        String(appts[item].scheduled).slice(0, 2) === "12" &&
        String(appts[item].scheduled).slice(6, 10) === dateYear
      ) {
        dec.push(item);
      }
    }

    getCountsForChart(
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec
    );
  }

  //   setting chart counts
  function getCountsForChart(
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec
  ) {
    let finalData = [];
    finalData.push(jan.length);
    finalData.push(feb.length);
    finalData.push(mar.length);
    finalData.push(apr.length);
    finalData.push(may.length);
    finalData.push(jun.length);
    finalData.push(jul.length);
    finalData.push(aug.length);
    finalData.push(sep.length);
    finalData.push(oct.length);
    finalData.push(nov.length);
    finalData.push(dec.length);
    setDataFunction(finalData);
  }
  //setting data
  function setDataFunction(finalData) {
    setApptData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "# of Appts",
          data: finalData,
          backgroundColor: graphBgColors,
          borderWidth: 1,
        },
      ],
    });
  }

  //Get appts for provider
  useEffect(() => {
    if (!searchProvPk && params) {
      setSearchProvPk(params.id);
    }
    axios
      .get(
        `/api/appointments/?simplified=true&scheduled__gte=${dateYear}-01-01T00%3A00%3A00%2B00%3A00&scheduled__lte=${dateYear}-12-31T00%3A00%3A00%2B00%3A00&provider=${
          !searchProvPk ? params.id : searchProvPk
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        let appts = response.data.results;
        console.log(appts);
        sortAppts(appts);
      });
    // eslint-disable-next-line
  }, [accessToken, searchProvPk, dateYear, params, setSearchProvPk]);

  // get clients by provider
  useEffect(() => {
    if (!searchProvPk && params) {
      setSearchProvPk(params.id);
    }
    axios
      .get(
        `/api/pro_cli_status/?${paginationUrl}&provider=${
          !searchProvPk ? params.id : searchProvPk
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setClients(response.data.results);
      });
  }, [accessToken, searchProvPk, paginationUrl, params, setSearchProvPk]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  useEffect(() => {
    if (!searchProvPk && params) {
      setSearchProvPk(params.id);
    }
    axios
      .get(`/api/provider/${!searchProvPk ? params.id : searchProvPk}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setProviderInfo(response.data);
        setName(response.data.name);
        setPhone(response.data.phone);
        setEmail(response.data.email === null ? "" : response.data.email);
        setType(response.data.type);
        setAddress(
          response.data.address.raw === null
            ? ""
            : String(response.data.address.raw)
        );
      });
  }, [accessToken, searchProvPk, trigger, setSearchProvPk, params]);

  const submitProvInfo = () => {
    let patchedProviderInfo = {
      ...(name === "" ? {} : { name: name }),
      ...(email === "" ? {} : { email: email }),
      ...(phone === "" ? {} : { phone: phone }),
      ...(type === "" ? {} : { type: type }),
    };
    axios
      .patch(`/api/provider/${searchProvPk}/`, patchedProviderInfo, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setOpenSuccessMessage(true);
        setTrigger(!trigger);
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
        setOpenError(!openError);
      });
  };

  const center = !providerInfo.address
    ? {
        lat: 34.03671,
        lng: -84.07817,
      }
    : {
        lat: providerInfo.address.latitude,
        lng: providerInfo.address.longitude,
      };

  return (
    <div>
      <div className="w-full text-center text-2xl text-purple-600 my-8 font-bold shadow-lg pb-4">
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {
            navigate(-1);
          }}
          className="hover:bg-red-500 hover:text-white border-none float-right mr-6"
        >
          <CloseIcon />
        </Button>
        {providerInfo.name}
        {/* <Button
          variant="outlined"
          color="success"
          onClick={handlePrint}
          className="hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 float-left ml-6"
        >
          Print
        </Button> */}
      </div>

      <div className="flex text-center overflow-auto h-screen">
        <div className="w-1/2 justify-center">
          <div className="h-[30vh] border-purple-600 border-2 rounded-md shadow-md mx-2">
            <Button
              onClick={handleProvInfoEdit}
              className="float-right block justify-center text-left p-0 w-auto text-sky-600 hover:text-white hover:bg-sky-600"
            >
              <span className="pl-5">
                <EditOutlinedIcon />
              </span>
            </Button>
            <div className="mt-4 text-lg font-bold fit-content mx-auto">
              {providerInfo.in_network === true ? (
                <span className="text-purple-600">In-Network</span>
              ) : (
                <span className="text-red-500">Non Network</span>
              )}{" "}
              - {providerInfo.type}
            </div>
            <div className="mt-4 w-fit mx-auto">
              <span className="mr-4">
                <PhoneIphoneOutlinedIcon />
              </span>
              {providerInfo.phone
                ? `${providerInfo.phone.slice(
                    0,
                    2
                  )} (${providerInfo.phone.slice(
                    2,
                    5
                  )}) ${providerInfo.phone.slice(
                    5,
                    8
                  )} - ${providerInfo.phone.slice(8, 12)}`
                : `NA`}
            </div>
            <div className="mt-4 w-fit mx-auto">
              <span className="mr-4">
                <MailOutlinedIcon />
              </span>
              {providerInfo.email === null
                ? "No Associated Email"
                : providerInfo.email}
            </div>
            <div className="mt-4 w-fit mx-auto">
              {clients.length < 1 ? (
                "No Clients"
              ) : (
                <h3>
                  <span className="mr-4">
                    <Groups2Icon />
                  </span>
                  Client Count: {clients.length}
                </h3>
              )}
            </div>
            <div className="mt-4 w-fit mx-auto">
              <span className="mr-4">
                <RoomOutlinedIcon />
              </span>
              {address === null ? "No Address" : address}
            </div>
          </div>
          <div className="mt-4 rounded-md mx-2">
            {/* <span className="font-bold">{address}</span> */}
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
            >
              <Marker
                position={center}
                // options={{
                //   icon:"https://img.icons8.com/ios-filled/40/9333EA/user.png"
                // }}
              ></Marker>
            </GoogleMap>
          </div>
        </div>

        <div className="w-1/2 h-full pb-10 overflow-hidden">
          <div className="grid grid-cols-3 text-center">
            <Button onClick={decreaseYear} className="text-black">
              <ArrowBackIosOutlined /> Year
            </Button>
            <div className="text-black font-bold">{dateYear}</div>
            <Button onClick={increaseYear} className="text-black font-bold">
              Year <ArrowForwardIosOutlined />
            </Button>
          </div>
          <div className="w-[75%] m-auto pb-6">
            {console.log(apptData)}
            <Chart
              type="bar"
              data={apptData}
              options={options}
              className="pt-1 pb-1"
            />
          </div>

          <div
            ref={componentRef}
            style={{ width: "100%", height: window.innerHeight }}
          >
            <div className="text-lg font-bold mb-6 border-b">
              {`${providerInfo.name}'s `}
              {b}
            </div>
            <div className="grid grid-cols-5 my-2 font-bold relative">
              <h3>Client Name</h3>
              <h3>Treatment Status</h3>
              <h3>Med-Docs Status</h3>
              <h3>Date Started</h3>
              <h3>Date Updated</h3>
            </div>
            <List className="overflow-auto h-[35vh]">
              {clients.map((client, idx) => {
                let isoStringCreatedAt = client.created_at;
                let isoStringUpdatedAt = client.updated_at;
                return (
                  <ListItemButton
                    className="grid grid-cols-5 bg-white shadow-lg rounded-lg uppercase mb-2 hover:text-white hover:bg-purple-600"
                    key={idx}
                    onClick={(e) => clickingClients(client)}
                  >
                    <h3 className="text-start self-start text-sm">
                      {idx + 1}.
                      <PersonOutlineOutlined className="mx-2" />
                      <span>{client.client.name}</span>
                    </h3>
                    <h3 className="text-center text-sm">
                      {client.finished_treatment === null ? (
                        <span className="text-red-500">Treating</span>
                      ) : client.finished_treatment === false ? (
                        <span className="text-yellow-500">Treating</span>
                      ) : (
                        <span className="text-green-500">Complete</span>
                      )}
                    </h3>
                    <h3 className="text-center text-sm">
                      {client.medical_documents_recieved === null ? (
                        <span className="text-red-500">Not Requested</span>
                      ) : client.medical_documents_recieved === false ? (
                        <span className="text-yellow-500">Waiting on Docs</span>
                      ) : (
                        <span className="text-green-500">Received</span>
                      )}
                    </h3>
                    <h3 className="text-center text-sm">
                      {new Date(isoStringCreatedAt).toLocaleDateString("en-US")}
                    </h3>
                    <h3 className="text-center text-sm">
                      {new Date(isoStringUpdatedAt).toLocaleDateString("en-US")}
                    </h3>
                  </ListItemButton>
                );
              })}
              <ListItem className={`justify-center py-10`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
      <Dialog open={openSuccess}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={handleProvInfoEdit}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              color: "orange",
              margin: "0 0 1rem 0",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Edit Provider Details
          </h1>
          <TextField
            style={{ width: "100%" }}
            className="w-full my-4 text-center"
            placeholder="Provider Name" //
            type="text"
            autoComplete="off"
            label="Provider Name"
            value={name}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setName(event.target.value)}
          />
          <br />
          <TextField
            style={{ width: "100%" }}
            className="w-full my-4 text-center"
            placeholder="Provider Type" //
            type="text"
            autoComplete="off"
            label="Provider Type"
            value={type}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setType(event.target.value)}
          />
          <br />
          <TextField
            style={{ width: "100%" }}
            className="w-full my-4 text-center"
            placeholder="Provider Phone" //
            type="text"
            autoComplete="off"
            label="Provider Phone"
            value={phone}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setPhone(event.target.value)}
          />
          <br />
          <TextField
            style={{ width: "100%" }}
            className="w-full my-4 text-center"
            placeholder="Provider Email" //
            type="text"
            autoComplete="off"
            label="Provider Email"
            value={email}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div style={{ paddingBottom: "1rem" }}>
          <ModalButton
            action={submitProvInfo}
            baseColor={"#1976d2"}
            content={"Update"}
          />
        </div>
      </Dialog>
      <ResponseModal
        title="Provider Info Updated"
        description="You have successfully updated the provider information"
        openBool={openSuccessMessage}
        setOpenBool={setOpenSuccessMessage}
        handleCloseFunc={handleOpenDialog}
      />
      <ResponseModal
        title="Password update failed"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
      <div className="hidden">
        <ProviderPrint
          componentRef={componentRef}
          providerInfo={providerInfo}
          clients={clients}
          address={address}
        />
      </div>
    </div>
  );
}
