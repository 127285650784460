import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useReducer,
} from "react";
import DataContext from "../../../../../../context/DataContext";
import { useParams } from "react-router-dom";
import axios from "../../../../../api/axios";
import MedicalListItem from "./MedicalListItem";
import {
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import {
  CheckCircleOutline,
  Close,
  ControlPointOutlined,
  Edit,
  Gavel,
  Warning,
} from "@mui/icons-material";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import AddMedItem from "./AddMedItem";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ResponseModal from "../../../../../global/ResponseModal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ModalButton from "../../../../../global/ModalButton";
import { provider_settlement } from "../../../../../api/endpoints/provider_settlement";
import { case_fee_schedule } from "../../../../../api/endpoints/case";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import { BankCheckReducer, initialState } from "./state/checkReducer";
import CheckDialog from "./BankCheckButton/components/CheckDialog";
import { BankCheckStateContext } from "./context/BankCheckStateContext";
import { BankCheckDispatchContext } from "./context/BankCheckDispatchContext";
import {
  CONTENT_ADD_CHECK,
  CONTENT_EDIT_CHECK,
  CONTENT_ERROR,
  CONTENT_SUCCESS,
  VIEW_CHECK_DIALOG,
  clearBankCheckForm,
  setAPIErrors,
  setCheckList,
  showComponent,
  showContent,
} from "./state/actions";
import { settlement_checks } from "../../../../../api/endpoints/settlement_checks";

export default function MedicalBreakdown({
  caseDetail,
  parentTrigger,
  setParentTrigger,
}) {
  const { casePk, getCasePk, accessToken, userRoles } = useContext(DataContext);
  const [settlementDetail, setSettlementDetail] = useState([]);
  const [typeChoices, setTypeChoices] = useState(null);
  const [settlement, setSettlement] = useState(null);
  const [feeSchedule, setFeeSchedule] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [settledOpen, setSettledOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [settlementFinalized, setSettlementFinalized] = useState(new Date());
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [state, dispatch] = useReducer(BankCheckReducer, initialState);

  const params = useParams();
  const isDisabled = userRoles.permissions.includes(
    "provider.change_providersettlement"
  )
    ? false
    : true;

  const fetchSettlementTypes = useCallback(async () => {
    try {
      const response = await provider_settlement.getSettlementType(accessToken);
      if (response) {
        let data = response;
        let typeObj = {};
        for (const item in data) {
          typeObj[data[item].value] = data[item].display_name;
        }
        setTypeChoices(typeObj);
      }
    } catch (error) {
      setOpenError(!openError);
      let res = error.response;
      let errArr = ApiRequestErrorHandler(res);
      setErrorArray(errArr);
    }
  }, [accessToken, openError]);

  const fetchProviderSettlementList = useCallback(
    async (id, accessToken) => {
      try {
        const response = await provider_settlement.retrieve(id, accessToken);
        if (response) {
          const data = response;
          setSettlement(data);
          setSettlementDetail(data.results);
          setIsLoading(false);
        }
      } catch (error) {
        setOpenError(!openError);
        let res = error.response;
        let errArr = ApiRequestErrorHandler(res);
        setErrorArray(errArr);
      }
    },
    [openError]
  );

  const fetchCaseFeeSchedule = useCallback(async (id, accessToken) => {
    if (!casePk || !params.id) {
      return;
    }
    try {
      const response = await case_fee_schedule.retrieve(id, accessToken);
      if (response) {
        const data = response;
        setFeeSchedule(data);
      }
    } catch (error) {
      setOpenError(true);
      let res = error.response;
      let errArr = ApiRequestErrorHandler(res);
      setErrorArray(errArr);
    }
  }, []);

  useEffect(() => {
    if (!caseDetail || !params) {
      return;
    } else {
      let id = !caseDetail ? params.id : caseDetail.pk;
      fetchSettlementTypes(accessToken);
      fetchProviderSettlementList(id, accessToken);
      fetchCaseFeeSchedule(casePk, accessToken);
    }
    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line no-unused-vars
  }, [accessToken, caseDetail, params, isLoading, parentTrigger]);

  // useEffect(() => {
  //   let active = true;
  //   if (active) {
  //     if (!casePk && params) {
  //       getCasePk(params.id);
  //     }
  //     if (!casePk || !params.id) {
  //       return;
  //     } else {
  //       console.log("woooo");
  //       console.log("im fetching ", isLoading);
  //       let id = !casePk ? params.id : casePk;

  //       // fetchCaseFeeSchedule(id, accessToken);
  //       if (isLoading && !typeChoices) {
  //         fetchSettlementTypes(accessToken);
  //       }
  //       if (isLoading && !settlement) {
  //         fetchProviderSettlementList(id, accessToken);
  //       }
  //     }
  //   }
  //   return () => {
  //     active = false;
  //     setIsLoading(false);
  //   };
  // }, [
  //   fetchProviderSettlementList,
  //   fetchCaseFeeSchedule,
  //   fetchSettlementTypes,
  //   feeSchedule,
  //   typeChoices,
  //   isLoading,
  //   settlement,
  //   accessToken,
  //   casePk,
  //   getCasePk,
  //   params,
  //   parentTrigger,
  //   openError,
  // ]);

  const handleSettledDate = () => {
    const patchedData = {
      settlement_finalized_on: settlementFinalized,
    };
    axios
      .patch(`/api/case/${!casePk ? params.id : casePk}/`, patchedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setSettledOpen(false);
        setOpenSuccess(!openSuccess);
        refreshDataFromAPI();
        setParentTrigger(!parentTrigger);
      })
      .catch((error) => {
        setOpenError(!openError);
        let res = error.response;
        let errArr = ApiRequestErrorHandler(res);
        setErrorArray(errArr);
      });
  };
  const refreshDataFromAPI = () => {
    setIsLoading(true);
    setTypeChoices(null);
    setFeeSchedule(null);
    setSettlement(null);
  };
  const openEditSettled = () => {
    setSettledOpen(true);
  };

  const createChecks = async (e) => {
    await settlement_checks
      .createSettlementCheck(state.newCheckForm.check, accessToken)
      .then((res) => res)
      .then((res) => {
        refreshDataFromAPI();
        showContent(dispatch, CONTENT_SUCCESS);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setAPIErrors(dispatch, errArr);
        showContent(dispatch, CONTENT_ERROR);
      });
  };

  const editChecks = async () => {
    await settlement_checks
      .updateSettlementCheck(
        state.newCheckForm.check,
        state.newCheckForm.check.id,
        accessToken
      )
      .then((res) => res)
      .then((res) => {
        refreshDataFromAPI();
        showContent(dispatch, CONTENT_SUCCESS);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setAPIErrors(dispatch, errArr);
        showContent(dispatch, CONTENT_ERROR);
      });
  };

  const handleSubmitForm = (formType) => {
    if (formType === CONTENT_ADD_CHECK) {
      createChecks();
    } else if (formType === CONTENT_EDIT_CHECK) {
      editChecks();
    } else {
      //do nothing
    }
  };

  const closeActiveComponent = () => {
    showComponent(dispatch, "");
    showContent(dispatch, "");
    clearBankCheckForm(dispatch, true);
    setCheckList(dispatch, null);
  };

  const showSettlementError = (isError) => {
    if (isError) {
      return "Error: Please Add all Checks to Finalize Settlement.";
    }
    return "";
  };

  const checkifAllChecksAdded = () => {
    if (feeSchedule.can_finalize === false) {
      return false;
    }
    return true;
  };

  const handleSettlementFinalizedButton = (e) => {
    e.preventDefault();
    if (checkifAllChecksAdded()) {
      setSettledOpen(true);
    } else {
      setAPIErrors(dispatch, ["Please Add all Checks to Finalize Settlement."]);
      showComponent(dispatch, VIEW_CHECK_DIALOG);
      showContent(dispatch, CONTENT_ERROR);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BankCheckStateContext.Provider value={state}>
        <BankCheckDispatchContext.Provider value={dispatch}>
          <CheckDialog
            showDialog={state.showComponent === VIEW_CHECK_DIALOG}
            close={closeActiveComponent}
            submitForm={handleSubmitForm}
            casePk={casePk}
          />
          {!isLoading && settlement && typeChoices ? (
            <div>
              <div className="flex justify-between">
                <div>&nbsp;</div>
                <h2 className="text-[1.5rem] font-bold pb-2 text-center">
                  Medical Breakdown
                </h2>
                <Tooltip title="Add Custom Medical Item">
                  <IconButton
                    onClick={() => setAddOpen(!addOpen)}
                    className="text-green-600"
                  >
                    <ControlPointOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="flex justify-between border-2 border-black w-full px-2">
                <div className="w-1/5 text-center">Medical</div>
                <div className="w-[15%] text-center">Initial Bill</div>
                <div className="w-1/5 text-center">Reduction</div>
                <div
                  className="w-[10%] text-center"
                  data-testid="Bank-Check-Header"
                >
                  Payment
                </div>
                <div className="w-[10%] text-center">Discount</div>
                <div className="w-1/5 text-center">Type</div>
              </div>{" "}
              <List className="max-h-[50vh] overflow-y-auto">
                {settlementDetail.length === 0 ? (
                  <ListItem className="justify-center">
                    No Information Recorded
                  </ListItem>
                ) : (
                  settlementDetail.map((detail, idx) => {
                    return (
                      <MedicalListItem
                        detail={detail}
                        key={idx}
                        parentTrigger={parentTrigger}
                        trigger={trigger}
                        setTrigger={refreshDataFromAPI}
                        disabled={
                          +settlement.total_medical_share === 0 || isDisabled
                            ? true
                            : false
                        }
                        typeChoices={typeChoices}
                      />
                    );
                  })
                )}
              </List>
              <div className="flex justify-between items-center">
                <div className="w-1/5">&nbsp;</div>
                <div className="w-1/5 text-center text-[12px] font-bold">
                  Total Medical Bills
                </div>
                <div className="w-1/4 text-center text-[12px] font-bold">
                  Total Settled Amount
                </div>
                <div className="w-1/5 text-center text-[12px] font-bold">
                  Total Medical Share
                </div>
                <div className="w-1/5 text-center text-[12px] font-bold">
                  Remaining Unclaimed
                </div>
              </div>
              <div className="flex justify-between border-2 border-black w-full px-2 items-center mb-4">
                <div className="w-1/5 text-left underline text-sm">
                  Total Medical Reduction
                </div>{" "}
                <div className="w-1/5 text-center">
                  ${Number(settlement.total_medical_bills).toFixed(2)}
                </div>
                <div className="w-1/4 text-center">
                  ${Number(settlement.total_settled_amount).toFixed(2)}
                </div>
                <div className="w-1/5 text-center">
                  {" "}
                  ${Number(settlement.total_medical_share).toFixed(2)}
                </div>
                <div className="w-1/5 text-center">
                  <span
                    className={`px-2 ${
                      +settlement.remaining_unclaimed > 0
                        ? "bg-lime-500 rounded-md font-bold"
                        : "bg-red-300 rounded-md"
                    }`}
                  >
                    {" "}
                    ${Number(settlement.remaining_unclaimed).toFixed(2)}
                  </span>
                </div>
              </div>{" "}
              <div>
                <h2 className="text-[1.2rem] font-bold py-2 text-left underline">
                  Fee Schedule Calculator
                </h2>
                {!caseDetail.actual_total_settlement_amount || !feeSchedule ? (
                  <p className="text-center font-bold text-orange-700">
                    <Warning /> Case needs a settled amount before fee data is
                    available
                  </p>
                ) : (
                  <>
                    <div className="border-2 border-black">
                      <div className="flex justify-between w-full px-2 py-1 items-center">
                        <div className="w-2/3">Settlement Amount</div>
                        <div className="w-1/3 text-center">
                          ${Number(feeSchedule.settlement_amount).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">-</div>
                      </div>
                      <div
                        className="flex justify-between w-full px-2 py-1 items-center"
                        style={{
                          borderBottom: "2px solid black",
                        }}
                      >
                        <div className="w-2/3">Gross Deductions</div>
                        <div className="w-1/3 text-center">
                          ${Number(feeSchedule.gross_deductions).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">-</div>
                      </div>

                      <div className="flex justify-between w-full px-2 py-1 items-center">
                        <div className="w-2/3">Other Deductions</div>
                        <div className="w-1/3 text-center">
                          ${Number(feeSchedule.total_deductions).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">
                          {Math.round(feeSchedule.deduction_percentage)}%
                        </div>
                      </div>
                      <div className="flex justify-between w-full px-2 pb-1 items-center">
                        <div className="w-2/3">Attorney's Fees</div>
                        <div className="w-1/3 text-center">
                          {" "}
                          ${Number(feeSchedule.law_fees).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">
                          {Math.round(feeSchedule.law_firm_percentage)}%
                        </div>
                      </div>
                      <div className="flex justify-between w-full px-2 pb-1 items-center">
                        <div className="w-2/3">Medical Fees + Fees</div>
                        <div className="w-1/3 text-center">
                          ${Number(+feeSchedule.medical_fees).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">
                          {Math.round(feeSchedule.medical_percentage)}%
                        </div>
                      </div>
                      <div className="flex justify-between w-full px-2 pb-1 items-center">
                        <div className="w-2/3">Client Payout</div>
                        <div className="w-1/3 text-center">
                          ${Number(feeSchedule.client_payout).toFixed(2)}
                        </div>
                        <div className="w-1/3 text-center">
                          {Math.round(feeSchedule.client_percentage)}%
                        </div>
                      </div>
                      <div
                        className={`flex justify-between border-t-2 border-black w-full px-2 items-center ${
                          feeSchedule.net_to_client > 0
                            ? "bg-lime-500"
                            : "bg-red-300"
                        }`}
                      >
                        <div className="w-2/3 text-left">Net To Client</div>
                        <div className="w-1/3 text-center">&nbsp;</div>
                        <div className="w-1/3 text-center">
                          ${Number(feeSchedule.net_to_client).toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto w-fit my-2">
                      {!caseDetail.settlement_finalized_on ? (
                        <div>
                          <Button
                            onClick={(e) => handleSettlementFinalizedButton(e)}
                            variant="outlined"
                            className="text-green-800 border-green-800 hover:bg-green-800 hover:text-white"
                          >
                            Finalize Settlement <Gavel />
                          </Button>
                          <div className="text-sm text-red-600">
                            {showSettlementError()}
                          </div>
                        </div>
                      ) : (
                        <p className="font-bold text-[1.2rem] border-green-800 border-2 rounded-md px-2">
                          Settlement Finalized &nbsp;
                          {new Date(
                            caseDetail.settlement_finalized_on
                          ).toLocaleDateString()}{" "}
                          <CheckCircleOutline className="text-green-800" />
                          <IconButton onClick={() => openEditSettled()}>
                            <Edit className="text-orange-400" />
                          </IconButton>
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <AddMedItem
                addOpen={addOpen}
                setAddOpen={setAddOpen}
                casePk={casePk}
                trigger={trigger}
                setTrigger={setTrigger}
              />
              <Dialog open={settledOpen}>
                <div
                  style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    <Button onClick={() => setSettledOpen(false)}>
                      <Close />
                    </Button>
                  </div>
                  <h1
                    style={{
                      fontSize: 20,
                      margin: "0 0 1rem 0",
                      textAlign: "center",
                      color: !caseDetail.settlement_finalized_on
                        ? "#65A30D"
                        : "#FFA500",
                      fontWeight: "bold",
                    }}
                  >
                    {!caseDetail.settlement_finalized_on
                      ? "Add Finalized Date"
                      : "Edit Finalized Date"}
                  </h1>
                  <DesktopDatePicker
                    className="w-full my-4 text-center"
                    label="Date Settlement Finalized"
                    value={
                      new Date(
                        !caseDetail.settlement_finalized_on
                          ? settlementFinalized
                          : caseDetail.settlement_finalized_on
                      )
                    }
                    onChange={(newValue) => {
                      setSettlementFinalized(newValue);
                    }}
                    slotProps={{ textField: { variant: "outlined" } }}
                  />
                  <div style={{ margin: "1rem 0" }}>
                    {" "}
                    <ModalButton
                      action={handleSettledDate}
                      baseColor={
                        !caseDetail.settlement_finalized_on
                          ? "#65A30D"
                          : "#FFA500"
                      }
                      content="Submit Settled Date"
                      icon={"add"}
                    />
                  </div>
                </div>
              </Dialog>
              <ResponseModal
                title={`Settlement Data Upated`}
                description="Settled date confirmed"
                openBool={openSuccess}
                setOpenBool={setOpenSuccess}
              />
              <ResponseModal
                title={`Failed to update settlement data`}
                isError={true}
                openBool={openError}
                setOpenBool={setOpenError}
                errorMessage={errorArray}
              />
            </div>
          ) : (
            <LoadingIndicator
              isLoading={isLoading && !typeChoices && !settlement}
            />
          )}
        </BankCheckDispatchContext.Provider>
      </BankCheckStateContext.Provider>
    </LocalizationProvider>
  );
}
