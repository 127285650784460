import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Alert, AlertTitle, Button, Dialog, TextField } from "@mui/material/";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useContext, useRef, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ResponseModal from "../../../global/ResponseModal";
import PlacesResponseList from "../../../global/PlacesResponseList";

export default function CreateLawfirm() {
  const { accessToken } = useContext(DataContext);
  const [creationForm, setCreationForm] = useState("NewLFInfo");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [dob, setDob] = useState(new Date());
  const [address, setAddress] = useState("");
  const [lawFirmName, setLawFirmName] = useState("");
  const [lawFirmAddress, setLawFirmAddress] = useState("");
  const [lawFirmEmail, setLawFirmEmail] = useState("");
  const [lawFirmPhone, setLawFirmPhone] = useState("");
  const [lawFirmUrl, setLawFirmUrl] = useState("");
  const [lawFirmCoordinates, setLawFirmCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [newUserName, setNewUserName] = useState();
  const [newUserPswd, setNewUserPswd] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const navigate = useNavigate();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `NetProApp - ${name} - Login Information`,
  });

  const handlePlaceSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const handleLfPlaceSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setLawFirmAddress(value);
    setLawFirmCoordinates(latLng);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setLawFirmName("");
    setLawFirmEmail("");
    setLawFirmPhone("");
    setLawFirmAddress("");
    setCoordinates("");
    setLawFirmCoordinates("");
    navigate(`/dashboard`);
  };

  const handleNewClientSubmit = (e) => {
    let lawFirmData = {
      law_firm: {
        name: lawFirmName,
        address: {
          raw: lawFirmAddress,
          latitude: lawFirmCoordinates.lat,
          longitude: lawFirmCoordinates.lng,
        },
        phone: lawFirmPhone,
        email: lawFirmEmail,
        homepage_url: lawFirmUrl,
      },
      law_firm_managed_by: {
        name: name,
        email: email,
        phone: phone,
        date_of_birth: new Date(dob).toLocaleDateString("en-CA"),
        address: {
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          raw: address,
        },
      },
    };
    axios
      .post("/api/lawfirm/", lawFirmData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
          setNewUserName(response.data.law_firm_managed_by.generated_username);
          setNewUserPswd(response.data.law_firm_managed_by.generated_password);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
        setOpenError(!openError);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className="hidden"
        ref={componentRef}
        style={{ margin: "0 2rem", fontSize: "1.2rem" }}
      >
        <div style={{ width: "200px", margin: "1rem auto" }}>
          <img alt="" src="/assets/Progression_Logo.svg" />
        </div>
        <ul style={{ margin: "1rem auto", listStyle: "none" }}>
          <li style={{ textAlign: "center", marginBottom: "10px" }}>
            <strong>{name}</strong>
          </li>
          <li style={{ textAlign: "center", marginBottom: "10px" }}>
            <strong>{phone}</strong>
          </li>
          <li style={{ textAlign: "center", marginBottom: "10px" }}>
            <strong>{email}</strong>
          </li>
          <li style={{ textAlign: "center", marginBottom: "10px" }}>
            <strong>Username:</strong> {newUserName}
          </li>
          <li style={{ textAlign: "center", marginBottom: "10px" }}>
            <strong>Temporary Password:</strong> {newUserPswd}
          </li>
        </ul>
        <p
          style={{
            fontSize: "1.2rem",
            textAlign: "center",
            margin: "0 auto 1rem",
            display: "block",
          }}
        >
          Login URL:{" "}
          <a href="https://app.netproapp.com/">https://app.netproapp.com/</a>
        </p>

        <p style={{ textAlign: "center" }}>
          The password above is only necessary for your first login. You will be
          prompted to create a custom password on your first login attempt.{" "}
        </p>
      </div>
      <div className="h-full justify-items-center overflow-auto mx-2 md:mx-auto md:w-[50%]">
        <h1
          className={`text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2 bg-white z-10`}
        >
          Law Firm Form
        </h1>
        <div className={`mt-14 mx-auto`}>
          <form className="">
            <div className="text-center ">
              {creationForm === "NewLFAdminInfo" ? (
                <div>
                  <Button
                    onClick={(e) => {
                      setCreationForm("NewLFInfo");
                    }}
                    className="hover:text-white hover:bg-gray-600 text-gray-600 rounded-md flex w-full justify-center border-solid border-gray-600 border w-fit px-8 py-1 m-auto"
                  >
                    <ArrowBackIcon /> Previous Page
                  </Button>
                  <h2 className="text-xl font-bold pt-4">
                    Law Firm Admin Information
                  </h2>
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Name (First & Last)"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Email"
                    placeholder="ex. email@email.com"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Phone"
                    placeholder="ex. 404-444-4444"
                    value={phone}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                  <DesktopDatePicker
                    className="w-full my-4 text-center"
                    label="Date of Birth"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                      // setDob(new Date(newValue).toLocaleDateString('en-US', {timeZone: 'UTC'}));
                    }}
                    slotProps={{ textField: { variant: "outlined" } }}
                  />
                  <PlacesResponseList
                    address={address}
                    setAddress={setAddress}
                    handlePlaceSelect={handlePlaceSelect}
                  />
                  {coordinates.lat ? (
                    <Button
                      className="border-black rounded-md hover:text-white hover:bg-[#1866d2] p-2"
                      onClick={(e) => {
                        handleNewClientSubmit();
                      }}
                    >
                      Create Law Firm
                    </Button>
                  ) : null}
                </div>
              ) : (
                <div className="overflow-auto">
                  <h2 className="text-xl font-bold">Law Firm Information</h2>

                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Name"
                    value={lawFirmName}
                    onChange={(event) => {
                      setLawFirmName(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Website URL"
                    value={lawFirmUrl}
                    onChange={(event) => {
                      setLawFirmUrl(event.target.value);
                    }}
                  />
                  <TextField
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Email"
                    placeholder="ex. email@email.com"
                    value={lawFirmEmail}
                    onChange={(event) => {
                      setLawFirmEmail(event.target.value);
                    }}
                  />
                  <TextField
                    required
                    className="w-full my-4 text-center"
                    id="outlined-required"
                    label="Law Firm Phone"
                    placeholder="ex. 404-444-4444"
                    value={lawFirmPhone}
                    onChange={(event) => {
                      setLawFirmPhone(event.target.value);
                    }}
                  />
                  <PlacesResponseList
                    address={lawFirmAddress}
                    setAddress={setLawFirmAddress}
                    handlePlaceSelect={handleLfPlaceSelect}
                  />
                  <div className="mb-6">
                    <Button
                      onClick={(e) => {
                        setCreationForm("NewLFAdminInfo");
                      }}
                      className="hover:text-white hover:bg-green-500 text-green-500 rounded-md flex justify-center border-solid border-green-500 border w-fit px-8 py-1 m-auto"
                    >
                      Next Page <ArrowForwardIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Create User Success</AlertTitle>
            <p>
              The user was successfully created,{" "}
              <strong>
                if you opted out of sending this user their email information,
                please be sure to download the pdf attachment with their login
                information! <br />
              </strong>
            </p>
            <p>
              {" "}
              <span style={{ color: "red" }}>
                **Users password information will not be reedemable once this
                window is closed.
              </span>
            </p>
          </Alert>
          <div style={{ display: "flex", margin: "1rem 0 0" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={handlePrint}
              style={{ width: "50%" }}
            >
              {" "}
              Print
            </Button>
            <Button
              onClick={handleOpenSuccess}
              style={{ width: "50%" }}
              variant="outlined"
            >
              Close
            </Button>
          </div>
        </Dialog>
        <ResponseModal
          title="Error adding new Law Firm"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
