import { Search } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  TextField,
} from "@mui/material/";
import qs from "query-string";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";

export default function ClientSearchMinimized() {
  const { setSelectedClient, accessToken, trigger, setTrigger } =
    useContext(DataContext);
  const [responseData, setResponseData] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  // reset filter
  const resetClients = (e) => {
    setSearch("");
  };

  // Handling client onClick
  function handleClient(client) {
    const selectedClient = Number(client.client.pk);
    setSelectedClient(client.client.pk);
    navigate(`/client-detail/${selectedClient}`);
    setTrigger(!trigger);
    setSearch("");
    return selectedClient;
  }

  const sendRequest = () => {
    axios
      .get(
        `/api/case/?${qs.stringify(
          {
            search: !search ? null : search,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(() => sendRequest(), [search], 250);

  return (
    <div className="w-full relative">
      <div className="xl:h-1/6 flex items-center shadow-lg py-5">
        <TextField
          className="w-full mx-4 placeholder:text-center rounded-lg"
          placeholder="Search"
          size="small"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          color="error"
          onClick={resetClients}
          className="w-1/8 mr-4 rounded-md hover:bg-red-500 hover:text-white"
        >
          Reset
        </Button>
      </div>
      {!responseData ? (
        ""
      ) : !search ? (
        ""
      ) : (
        <List className="p-0 absolute z-10 bg-white w-full shadow-lg rounded-b-md max-h-[50vh] overflow-y-auto">
          {responseData.map((client, index) => (
            <ListItem
              key={index}
              onClick={(e) => handleClient(client)}
              className="px-2 w-full flex bg-white rounded-lg border-gray border-b hover:bg-purple-600"
            >
              {client.client.name}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
