import React, { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";

export default function DeleteApptv2({ selectedAppt, setOpenDeleteAppt }) {
  const { accessToken, setActiveFilterButton, trigger, setTrigger } =
    useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenDeleteAppt(false);
  };
  function handleApptDelete() {
    axios
      .delete(`/api/appointments/${selectedAppt.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setActiveFilterButton("");
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  }

  useEffect(() => {
    let arrayVals = [];
    if (errorArray.detail) {
      arrayVals.push(`detail: ${errorArray.detail}`);
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  return (
    <div className=" bg-white">
      {!selectedAppt ? (
        "Please Select an Appointment to Continue"
      ) : (
        <>
          <div className="grid grid-cols-2 bg-gray-200 mt-4 rounded-md">
            <div className="my-2">
              <span
                className="underline text-lg"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                PROVIDER INFO
              </span>
              <br />
              <br />
              <div className="grid grid-cols-2 mt-4">
                <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                <span>{selectedAppt.provider.name}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                <span>{selectedAppt.provider.phone}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                <span>{selectedAppt.provider.email}</span>
              </div>
            </div>

            <div className="my-2">
              <br />
              <span
                className="underline text-lg"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                CLIENT INFO
              </span>
              <br />
              <br />
              <div className="grid grid-cols-2 mt-4">
                <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                <span>{selectedAppt.client.name}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                <span>{selectedAppt.client.email}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                <span>{selectedAppt.client.phone}</span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <br />
            <span
              className="underline text-lg font-bold"
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              APPOINTMENT DETAILS
            </span>
            <br />
            <br />
            <div className="my-4 grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Date/Time:</span>{" "}
              <span className="justify-self-start">
                {selectedAppt.scheduled}
              </span>
            </div>
            <div className="my-4 grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
              <span className="justify-self-start">{selectedAppt.status}</span>{" "}
            </div>
            <div className="my-4 grid grid-cols-2">
              {" "}
              <span style={{ fontWeight: "bold" }}>Notes:</span>{" "}
              <span
                className="justify-self-start"
                dangerouslySetInnerHTML={{ __html: selectedAppt.notes }}
              ></span>{" "}
            </div>
            <br />
            <br />
          </div>
        </>
      )}

      <h2 className="font-bold text-lg">
        <span
          className="text-xl text-red-800 underline"
          style={{ color: "red", fontWeight: "bold" }}
        >
          Warning:
        </span>{" "}
        Clicking the "Permanently Delete Appointment" button below will
        permanently remove the appointment data above from the database, after
        proceeding it <span className="text-lg underline">cannot</span> be
        recovered. Please make sure you want to delete the data before
        proceeding.
      </h2>
      <br />
      <br />

      <ModalButton
        action={handleApptDelete}
        baseColor={"red"}
        content={"Permanently Delete Appointment"}
        icon={"delete"}
      />
      <ResponseModal
        title="Appointment Deleted"
        description="Successfully Deleted Appointment"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Appointment Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
