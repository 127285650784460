import React, { useContext, useState } from "react";
import format from "date-fns/format";
import {
  TextField,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AddUserStateContext } from "../context/AddUserStateContext";
import { AddUserDispatchContext } from "../context/AddUserDispatchContext";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesResponseList from "../../../../../../global/PlacesResponseList";
import {
  setFieldData,
  setAddress,
  setSendEmail,
  setPermissionSets,
} from "../state/actions";

export default function Form({ onClickSubmit, permission_set_list }) {
  /**
   * Form Component
   *  - Renders Form Fields and passes data to Parent AddUser Component
   *  -- Loading - Renders Loading when fields are loading
   *  -- Field Init - Initiates and Renders Fields when ready
   *  -- Field Handling - Manages Field Entry/Selections and passes data to parent
   *  -- Field Validation - Validates Fields
   *  -- Form Validation - Validates whether form isValid or !isValid for submission
   *
   */
  const state = useContext(AddUserStateContext);
  const dispatch = useContext(AddUserDispatchContext);
  const [address, setFieldAddress] = useState("");
  const [dob, setDOB] = useState(new Date());

  const handleFieldOnChange = async (e) => {
    if (e.target.name === "permission_sets") {
      setPermissionSets(dispatch, e.target.value);
    } else {
      setFieldData(dispatch, {
        fieldName: e.target.name,
        data: e.target.value,
      });
    }
  };

  const handleDateOnChange = (e) => {
    setDOB(new Date(e));
    setFieldData(dispatch, {
      fieldName: "date_of_birth",
      data: new Date(e).toLocaleDateString("en-CA"),
    });
  };

  const handlePlaceSelect = async (value) => {
    setFieldAddress(value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    if (results && latLng) {
      setAddress(dispatch, {
        raw: value,
        latitude: latLng.lat,
        longitude: latLng.lng,
      });
    }
  };

  const handleSetSendEmail = (e) => setSendEmail(dispatch, e.target.checked);

  const handleSubmitForm = (e) => {
    if (
      state.newUserForm.user.email.trim() !== "" ||
      state.newUserForm.user.name.trim() !== ""
    ) {
      onClickSubmit(e);
    } else {
      //TODO: Show Error Modal or Message
    }
  };

  return (
    <div data-testid="Add-User-Form">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
          data-testid="field-input-name"
          required
          className="w-full my-4 text-center"
          id="name"
          name="name"
          label="Full Name (First & Last)"
          value={state.newUserForm.user.name}
          variant="outlined"
          onChange={(e) => handleFieldOnChange(e)}
          error={state.newUserForm.user.name === "" ? true : false}
          helperText={
            state.newUserForm.user.name === ""
              ? "This field is required. Please Enter Your Full Name."
              : ""
          }
        />
        <TextField
          data-testid="field-input-email"
          required
          className="w-full my-4 text-center"
          id="email"
          name="email"
          label="Email"
          placeholder="ex. email@email.com"
          value={state.newUserForm.user.email}
          onChange={handleFieldOnChange}
          error={state.newUserForm.user.email === "" ? true : false}
          helperText={
            state.newUserForm.user.name === ""
              ? "This field is required. Please Enter Your Email."
              : ""
          }
        />
        <TextField
          data-testid="field-input-phone"
          className="w-full my-4 text-center"
          id="phone"
          name="phone"
          label="Phone"
          placeholder="ex. +14044444444"
          value={state.newUserForm.user.phone}
          onChange={handleFieldOnChange}
        />
        {/* <DesktopDatePicker
          data-testid="field-datepicker-dob"
          className="w-full my-4 text-center"
          label="Date of Birth"
          id="date_of_birth"
          name="date_of_birth"
          views={["year", "month", "day"]}
          value={new Date(dob)}
          onChange={handleDateOnChange}
        /> */}
        <DatePicker
          data-testid="field-datepicker-dob"
          className="w-full my-4 text-center"
          label="Date of Birth"
          id="date_of_birth"
          name="date_of_birth"
          value={new Date(dob)}
          onChange={(newValue) => {
            handleDateOnChange(newValue);
          }}
          slotProps={{ textField: { variant: "outlined" } }}
        />
        <PlacesResponseList
          data-testid="field-input-address"
          address={address}
          setAddress={setFieldAddress}
          handlePlaceSelect={handlePlaceSelect}
        />
        <FormControl fullWidth>
          <InputLabel id="user-permission">User Permissions</InputLabel>
          <Select
            data-testid="field-select-user-perm"
            labelId="user-permission"
            id="user-permission"
            name="permission_sets"
            multiple
            displayEmpty
            value={state.newUserForm.permission_sets}
            label="User Permissions"
            onChange={handleFieldOnChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em></em>;
              }
              let displayNames = permission_set_list
                .filter((perm) => selected.includes(perm.group))
                .map((perm) => perm.name);
              return displayNames.join(", ");
            }}
          >
            {permission_set_list.filter((perm) => perm.shareable === true)
              .length === 0 || !permission_set_list ? (
              <MenuItem>No Results</MenuItem>
            ) : (
              permission_set_list
                .filter((perm) => perm.shareable === true)
                .map((permission) => (
                  <MenuItem key={permission.group} value={permission.group}>
                    {permission.name}
                  </MenuItem>
                ))
            )}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            data-testid="field-checkbox-sendEmail"
            name="send_email"
            label="Send Email with Login Details"
            control={
              <Checkbox
                color="success"
                checked={state.newUserForm.send_email}
                onChange={handleSetSendEmail}
              />
            }
          />
        </div>
        <Button
          data-testid="button-submit"
          onClick={(e) => handleSubmitForm(e)}
          className="hover:text-white hover:bg-sky-600 text-sky-600 rounded-md flex justify-center border-solid border-sky-600 border w-fit px-8 py-1 m-auto"
        >
          Add User
        </Button>
      </LocalizationProvider>
    </div>
  );
}
