import React, { useContext, useEffect, useState } from "react";
import { Close, Info, UploadFile } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import { useParams } from "react-router-dom";
import CustomSearchSelect from "../../../../../../global/CustomSearchSelect";
import ResponseModal from "../../../../../../global/ResponseModal";

const FileUpload = ({
  closeUploadModal,
  casePk,
  docTrigger,
  setDocTrigger,
  setViewDocuments,
}) => {
  const { accessToken, userType, caseManagerPk } = useContext(DataContext);

  const [file, setFile] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [providerPk, setProviderPk] = useState("");
  const [referralToPk, setReferralToPk] = useState("");
  const [referralFromPk, setReferralFromPk] = useState("");
  const [fileReq, setFileReq] = useState("");
  const [docValidation, setDocValidation] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    axios
      .options(`/api/documents/`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDocTypeOptions(response.data.actions.POST.document_type.choices);
      })
      .catch((error) => {
        // handle errors
      });

    axios
      .get(`/api/documents/validate`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDocValidation(response.data);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
  }, [setDocValidation, accessToken]);

  const handleFileUpload = (event) => {
    // get the selected file from the input
    event.preventDefault();
    setIsLoading(true);
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    formData.append("case", casePk);
    formData.append("document_type", documentType.value);

    if (
      providerPk &&
      docValidation.required.provider.includes(documentType.value)
    ) {
      formData.append("provider", providerPk);
    }
    if (
      referralToPk &&
      docValidation.required.referral_to.includes(documentType.value)
    ) {
      formData.append("referral_to", referralToPk);
    }
    if (
      referralFromPk &&
      docValidation.required.referral_from.includes(documentType.value)
    ) {
      formData.append("referral_from", referralFromPk);
    }
    axios
      .post(`/api/documents/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        // handle the response
        setUploadSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        setUploadFailed(true);
        setIsLoading(false);
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setUploadErrorMessage(errArr);
        setErrorStatus(error.response.status);
      });
  };

  const handleUploadSuccess = () => {
    closeUploadModal();
    clearFileInfo();
    setDocTrigger(!docTrigger);
  };
  const handleNeedsTypeFail = () => {
    if (errorStatus === 403) {
      closeUploadModal();
      clearFileInfo();
      setViewDocuments(true);
    } else {
      setUploadFailed(false);
    }
  };

  const clearFileInfo = () => {
    setFile("");
    setDocumentType();
    setProviderPk("");
    setReferralToPk("");
    setReferralToPk("");
    // setUploadDocument(false);
  };

  const handleDocumentType = (e, optObj) => {
    if (!optObj) {
      setFileReq("");
      setDocumentType();
      return;
    } else {
      setFileReq(docValidation.valid_extensions[optObj.value]);
      setDocumentType(optObj);
      if (
        providerPk &&
        !docValidation.required.provider.includes(optObj.value)
      ) {
        setProviderPk("");
      }
      if (
        referralToPk &&
        !docValidation.required.referral_to.includes(optObj.value)
      ) {
        setReferralToPk("");
      }
      if (
        referralToPk &&
        !docValidation.required.referral_from.includes(optObj.value)
      ) {
        setReferralFromPk("");
      }
    }
  };
  //!ADD PAGINATION MAAM!!!!!!!!!!!!!
  //if referral is selected, to/from are required
  //if the others, only prov required - auto pop if provider user - if not prov user, show provider dropwdown

  return (
    <div>
      <form
        onSubmit={handleFileUpload}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            margin: "10px auto",
            display: "flex",
            width: "fit-content",
            borderBottom: "1px solid #1976d2",
          }}
        >
          <Button
            component="label"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            startIcon={<UploadFile />}
          >
            {!file ? <span>&nbsp; Select File</span> : file.name}

            <input type="file" hidden onChange={handleFileSelect} />
          </Button>
          {!file ? (
            ""
          ) : (
            <Button style={{ color: "red" }} onClick={clearFileInfo}>
              <Close />
            </Button>
          )}
        </div>
        {!file && documentType === "Choose Type" ? (
          ""
        ) : (
          <div>
            <p style={{ fontSize: "12px", color: "grey", textAlign: "center" }}>
              <Info style={{ fontSize: "14px" }} />
              &nbsp; Supported File Extensions - {!fileReq ? "TBD" : fileReq}
            </p>
          </div>
        )}
        <div className="lg:w-1/2 w-full my-4 mx-auto">
          <Autocomplete
            className="w-full"
            disablePortal
            options={!docTypeOptions ? [] : docTypeOptions}
            getOptionLabel={(option) => option.display_name}
            noOptionsText="No Results"
            onChange={(e, value) => {
              handleDocumentType(e, value);
            }}
            value={!documentType ? null : documentType}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  id={option.display_name}
                  key={option.display_name}
                >
                  {option.display_name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Document Types" />
            )}
          />
        </div>
        {!docValidation || !documentType ? (
          ""
        ) : docValidation.required.provider.includes(documentType.value) ? (
          <CustomSearchSelect
            selectedVal={providerPk}
            setSelectedVal={setProviderPk}
            searchTitle="Search Client's Providers"
            title="Related Provider"
            nameKey="provider"
            apiUrl={
              userType === "Provider"
                ? `/api/provider/?managed_by=${caseManagerPk}&`
                : `/api/pro_cli_status/?client=${params.id}&`
            }
          />
        ) : (
          ""
        )}
        {!docValidation || !documentType ? "" 
        :
       <div>
        {(docValidation?.required?.referral_to.includes(documentType?.value) ||
          docValidation?.required?.referral_from.includes(documentType?.value)) ?
            <div className="mx-4 mt-1 mb-10 p-4 text-black rounded-sm bg-yellow-100 text-sm">
            <strong>NOTE: </strong>Only providers that are already assigned to the client's case can be chosen below. If the provider needed is not shown below, please add them to the case before uploading the referral file.
          </div>
          :
          ""
        }
        {docValidation.required.referral_to.includes(documentType.value) ? 
          
          <CustomSearchSelect
            selectedVal={referralToPk}
            setSelectedVal={setReferralToPk}
            searchTitle="Search Providers"
            title="Referred To"
            nameKey="provider"
            apiUrl={`/api/pro_cli_status/?client=${params.id}&`}
          />
           : ""
        }
         {docValidation.required.referral_from.includes(documentType.value) ? 
         <CustomSearchSelect
            selectedVal={referralFromPk}
            setSelectedVal={setReferralFromPk}
            searchTitle="Search Providers"
            title="Referred From"
            nameKey="provider"
            apiUrl={
              userType === "Provider"
                ? `/api/provider/?managed_by=${caseManagerPk}&`
                : `/api/pro_cli_status/?client=${params.id}&`
            }
          />
         : ""
         }
         </div>
         }
        <div style={{ display: "block", margin: "0 auto" }}>
          <Button type="submit" variant="outlined">
            {" "}
            Upload File
          </Button>
        </div>
      </form>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            background: "#FFF",
            padding: "12px 10px 8px",
            boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
            zIndex: "100",
            borderRadius: "5px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ""
      )}
      <ResponseModal
        title="Upload Success"
        description="The image/document was successfully uploaded"
        openBool={uploadSuccess}
        setOpenBool={setUploadSuccess}
        handleCloseFunc={handleUploadSuccess}
      />
      <ResponseModal
        title="Upload failed"
        isError={true}
        openBool={uploadFailed}
        setOpenBool={setUploadFailed}
        errorMessage={uploadErrorMessage}
        handleCloseFunc={handleNeedsTypeFail}
      />
    </div>
  );
};
export default FileUpload;
