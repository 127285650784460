import {
  CloseOutlined,
  ControlPointOutlined,
  GavelOutlined,
  Person,
  Search,
} from "@mui/icons-material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ModalButton from "../../../../global/ModalButton";
import ResponseModal from "../../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import ShowMoreButton from "../../../../global/ShowMoreButton";

export default function CaseAssignment({ caseObj }) {
  const { accessToken, trigger, setTrigger, userRoles } =
    useContext(DataContext);
  const [rolesList, setRolesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [assignedUser, setAssignedUser] = useState("");
  const [assignedRole, setAssignedRole] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openAssignUser, setOpenAssignUser] = useState(false);
  const [currentCaseManager, setCurrentCaseManager] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [connectedUserList, setConnectedUserList] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [connectionToDelete, setConnectionToDelete] = useState("");
  const [primaryPn, setPrimaryPn] = useState("");
  const [secondaryPn, setSecondaryPn] = useState("");
  const [primaryLf, setPrimaryLf] = useState("");
  const [secondaryLf, setSecondaryLf] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [userRemErr, setUserRemErr] = useState(false);
  const [userRemErrMess, setUserRemErrMess] = useState([]);

  useEffect(() => {
    setPrimaryPn("");
    setPrimaryLf("");
    setSecondaryPn("");
    setSecondaryLf("");
    if (!caseObj) {
      return;
    } else if (
      userRoles.permissions.includes("users.view_usertocaseassignments")
    ) {
      axios
        .get(`api/user_case/?case=${caseObj.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setConnectedUserList(data);
          setCurrentCaseManager(data);
          data.map((item) => {
            let newVal;
            if (item.role.pk === 1) {
              newVal = item;
              setPrimaryPn(item);
            }
            if (item.role.pk === 2) {
              newVal = item;
              setSecondaryPn(item);
            }
            if (item.role.pk === 3) {
              newVal = item;
              setPrimaryLf(item);
            }
            if (item.role.pk === 4) {
              newVal = item;
              setSecondaryLf(item);
            }
            return newVal;
          });
        });
    } else {
      return;
    }
  }, [accessToken, caseObj, trigger, userRoles.permissions]);

  useEffect(() => {
    if (!assignedUser) {
      return;
    } else {
      axios
        .get(
          `/api/case/${caseObj.pk}/assignable_roles/?user_id=${assignedUser}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          setRolesList(response.data.results);
        });
    }
    // eslint-disable-next-line
  }, [assignedUser, accessToken, setRolesList]);

  const searchUsers = () => {
    if (!caseObj) {
      return;
    } else {
      const searchQuery = !userSearch ? "" : `&search=${userSearch}`;
      axios
        .get(
          `/api/users/all_assignable_users/?limit=${resLimit}&ordering=name${searchQuery}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
          setUsersList(data);
        });
    }
  };

  useDebouncedEffect(() => searchUsers(), [userSearch, caseObj, resLimit], 250);

  const openDeleteModal = (userInfo) => {
    setConnectionToDelete(userInfo);
    setDeleteConfirmation(true);
  };

  const deleteRole = (pk) => {
    axios
      .delete(`/api/user_case/${pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 400) {
          setDeleteSuccess(!deleteSuccess);
          setDeleteConfirmation(false);
          setTrigger(!trigger);
          setSecondaryLf("");
          setPrimaryLf("");
          setPrimaryPn("");
          setSecondaryPn("");
        }
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setUserRemErrMess(errArr);
        setUserRemErr(true);
        setDeleteSuccess(!deleteSuccess);
        setTrigger(!trigger);
      });
  };

  const assignRole = () => {
    const assignmentData = {
      user: assignedUser,
      case: caseObj.pk,
      role: assignedRole,
    };

    if (!assignedRole || !assignedUser) {
      setError(true);
      setErrorMessage([
        "All frields required, please choose a user and a role",
      ]);
      return;
    } else {
      axios
        .post("/api/user_case/", assignmentData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        })
        .then(function (response) {
          if (response.status < 400) {
            setOpenSuccess(true);
            setDialogOpen(false);
            setAssignedUser("");
            setAssignedRole("");
            setTrigger(!trigger);
          }
        })
        .catch(function (error) {
          if (error.response) {
            let res = error.response.data;
            let errArr = [];
            for (const item in res) {
              errArr.push(item + `: ${res[item]}`);
            }
            setErrorMessage(errArr);
          }
        });
    }
  };

  const assignModalOpen = () => {
    setOpenAssignUser(true);
  };

  const closeAssignModal = () => {
    setOpenAssignUser(false);
    setAssignedRole("");
    setAssignedUser("");
    setUserSearch("");
  };

  const resetUserSearch = () => {
    setUserSearch("");
  };

  const selectUserForAssign = (user) => {
    setAssignedUser(user.pk);
    setUserSearch("");
    setSelectedUserName(user.name);
  };

  const openAssignmentList = () => {
    setDialogOpen(true);
  };

  return (
    <div className="w-full self-center">
      {" "}
      {userRoles.permissions.includes("users.view_usertocaseassignments") ? (
        <>
          {!currentCaseManager ? (
            <Tooltip title="Assign Case Manager">
              <Button
                onClick={() => openAssignmentList()}
                className="font-bold bg-sky-200 p-2 rounded-md text-black m-auto w-fit block"
                disabled={
                  userRoles.permissions.includes(
                    "users.add_usertocaseassignments"
                  )
                    ? false
                    : true
                }
              >
                ASSIGN USER TO CASE&nbsp;
                <ControlPointOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="View user case relations">
              <Button
                className="block items-center w-full rounded-md p-2 font-bold text-sm justify-evenly text-black"
                onClick={() => openAssignmentList()}
                disabled={
                  userRoles.permissions.includes(
                    "users.add_usertocaseassignments"
                  )
                    ? false
                    : true
                }
              >
                <div className="flex flex-start">
                  <span className="block w-1/2 bg-purple-300 rounded-md mb-2 mr-2 px-2 py-1 text-left">
                    {!primaryPn ? (
                      <>
                        No Primary Assigned
                        <AssignmentIndIcon />
                      </>
                    ) : (
                      <>
                        Primary: {primaryPn.user.name} <AssignmentIndIcon />
                      </>
                    )}
                  </span>
                  <span className="block w-1/2  bg-yellow-300 rounded-md mb-2 text-left px-2 py-1">
                    {!primaryLf ? (
                      <>
                        No LF Manager&nbsp;
                        <GavelOutlined className="w-[20px] mr-2" />
                      </>
                    ) : (
                      <>
                        Primary: {primaryLf.user.name}{" "}
                        <GavelOutlined className="w-[20px] mr-2" />
                      </>
                    )}
                  </span>
                </div>
                <div className="flex flex-start">
                  {!secondaryPn ? (
                    <></>
                  ) : (
                    <span className="block w-1/2 bg-purple-300 rounded-md mr-2 text-left px-2 py-1">
                      Secondary: {secondaryPn.user.name} <AssignmentIndIcon />
                    </span>
                  )}

                  {!secondaryLf ? (
                    <></>
                  ) : (
                    <span className="block w-1/2 bg-yellow-300 rounded-md text-left px-2 py-1">
                      Secondary: {secondaryLf.user.name}{" "}
                      <GavelOutlined className="w-[20px] mr-2" />
                    </span>
                  )}
                </div>
              </Button>
            </Tooltip>
          )}
        </>
      ) : (
        ""
      )}
      <Dialog open={dialogOpen}>
        <Alert
          variant="outlined"
          severity="info"
          role="alert"
          style={{ paddingRight: "40px" }}
        >
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 1rem",
            }}
          >
            Assignment
            <Button
              onClick={() => setDialogOpen(false)}
              style={{ padding: "0" }}
            >
              <CloseOutlined />
            </Button>
          </AlertTitle>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              margin: "1rem 0",
            }}
          >
            Case Related Users
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {!connectedUserList
              ? ""
              : connectedUserList.map((user, idx) => {
                  return (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={idx}
                    >
                      <p>
                        <strong>{user.role.name}</strong> : {user.user.name}
                        &nbsp;
                      </p>
                      {userRoles.permissions.includes(
                        "users.delete_usertocaseassignments"
                      ) && user.can_edit ? (
                        <Tooltip title="Delete user role">
                          <button
                            style={{
                              background: "none",
                              border: "1px solid #d32f2f",
                              borderRadius: "50%",
                              width: "15px",
                              height: "15px",
                              fontSize: "9px",
                              color: "#d32f2f",
                            }}
                            onClick={() => openDeleteModal(user)}
                          >
                            x
                          </button>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            {userRoles.permissions.includes(
              "users.add_usertocaseassignments"
            ) ? (
              <div style={{ margin: "1rem auto" }}>
                <ModalButton
                  className="block"
                  action={assignModalOpen}
                  baseColor="#1976d2"
                  icon="add"
                  content={"Assign user to case"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </Alert>
      </Dialog>
      <Dialog open={openAssignUser}>
        <Alert
          variant="outlined"
          severity="info"
          role="alert"
          style={{ paddingRight: "40px" }}
        >
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 1rem",
            }}
          >
            Assignment
            <Button onClick={() => closeAssignModal()} style={{ padding: "0" }}>
              <CloseOutlined />
            </Button>
          </AlertTitle>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              margin: "1rem 0",
            }}
          >
            Assign User To Case
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "400px",
            }}
          >
            {assignedUser ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                  border: "2px solid #1976d2",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                {" "}
                <div
                  style={{
                    fontSize: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Person />
                  <div>{selectedUserName}</div>
                </div>
                &nbsp;{" "}
                <Tooltip title="Remove Selection">
                  <button
                    style={{
                      background: "none",
                      border: "1px solid #d32f2f",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      fontSize: "9px",
                      color: "#d32f2f",
                    }}
                    onClick={() => setAssignedUser("")}
                  >
                    x
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div style={{ width: "100%", margin: "10px 0" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{ width: "100%" }}
                    placeholder="Search" //{`Search ${filter}`}
                    value={userSearch}
                    onChange={(event) => setUserSearch(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!userSearch ? (
                    ""
                  ) : (
                    <div style={{ marginLeft: "10px" }}>
                      <ModalButton
                        className="block"
                        action={resetUserSearch}
                        baseColor="#d32f2f"
                        content={"Reset"}
                      >
                        Reset
                      </ModalButton>
                    </div>
                  )}
                </div>
                <div
                  style={{ maxHeight: "100px", overflowY: "auto" }}
                  className="mt-2 w-full"
                  id="demo-simple-select"
                  label="Available Case Managers"
                  value={assignedUser}
                >
                  {!usersList
                    ? "No Results"
                    : usersList.map((user, idx) => {
                        return (
                          <MenuItem
                            value={!user.user ? user.pk : user.user.pk}
                            key={idx}
                            onClick={() => selectUserForAssign(user)}
                            style={{ justifyContent: "space-between" }}
                          >
                            {!user.user ? user.name : user.user.name}
                          </MenuItem>
                        );
                      })}
                  <ShowMoreButton
                    showMoreVisible={showMoreVisible}
                    setResLimit={setResLimit}
                    resLimit={resLimit}
                    showMoreAmt={25}
                  />
                </div>
              </div>
            )}

            {!assignedUser ? (
              ""
            ) : (
              <FormControl style={{ width: "100%", margin: "10px 0" }}>
                <InputLabel className="mt-2 font-normal">User Role</InputLabel>
                <Select
                  className="mt-2 w-full"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Available Case Managers"
                  value={assignedRole}
                >
                  {!rolesList
                    ? ""
                    : rolesList
                        .sort((a, b) => a.pk - b.pk)
                        .map((role, idx) => {
                          return (
                            <MenuItem
                              value={role.pk}
                              key={idx}
                              onClick={(e) => setAssignedRole(role.pk)}
                            >
                              {role.name}
                            </MenuItem>
                          );
                        })}
                </Select>
              </FormControl>
            )}

            {assignedUser && assignRole ? (
              <ModalButton
                className="block"
                action={assignRole}
                baseColor="#1976d2"
                icon="add"
                content={"Assign"}
                disabled={!assignedRole ? true : false}
              />
            ) : (
              ""
            )}
            <br />
          </div>
        </Alert>
      </Dialog>
      <Dialog open={deleteConfirmation}>
        <Alert
          variant="outlined"
          severity="error"
          role="error"
          style={{ paddingRight: "40px" }}
        >
          <AlertTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 1rem",
            }}
          >
            Remove{" "}
            {!connectionToDelete.user ? "" : connectionToDelete.user.name} from
            client's case
            <Button
              onClick={() => setDeleteConfirmation(false)}
              style={{ padding: "0" }}
            >
              <CloseOutlined />
            </Button>
          </AlertTitle>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              margin: "1rem 0",
            }}
          >
            Clicking below will remove this user from the case. For some users,
            it will remove their ability to access this clients information.
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <br />
            <Button onClick={() => deleteRole(connectionToDelete.id)}>
              Remove
            </Button>
          </div>
        </Alert>
      </Dialog>
      <ResponseModal
        title="User Assign Success"
        description="The user was successfully assigned to case"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
      />
      <ResponseModal
        title="User assign failed"
        isError={true}
        openBool={error}
        setOpenBool={setError}
        errorMessage={errorMessage}
      />
      <ResponseModal
        title="User Removal Success"
        description="The user was successfully removed from this case"
        openBool={deleteSuccess}
        setOpenBool={setDeleteSuccess}
      />
      <ResponseModal
        title="User Removal Failed"
        isError={true}
        openBool={userRemErr}
        setOpenBool={setUserRemErr}
        errorMessage={userRemErrMess}
      />
    </div>
  );
}
