import React, { useContext, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import PermissionWrapper from "../../../PermissionWrapper";
import SelectBasic from "../../SelectBasic";

export default function NamePrefixDropdown({
  prefix,
  setPrefixParent,
  optionsList,
}) {
  const { userRoles } = useContext(DataContext);
  const [options] = useState(optionsList);
  const [selectedPrefix, setSelectedPrefix] = useState(
    optionsList.find((g) => g.id === prefix)
  );

  const perms = {
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
  };

  const STRINGS = {
    TITLE: "Prefix",
  };
  const setSelectedPrefixState = (value) => {
    setSelectedPrefix(value);
    setPrefixParent(value.id);
  };

  return (
    <div data-testid="Prefix-Dropdown" style={{margin:'0rem 1rem'}}>
      <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
        {options.length > 0 && (
          <SelectBasic
            selectedVal={selectedPrefix}
            setSelectedVal={setSelectedPrefixState}
            placeholder={STRINGS.TITLE}
            title={STRINGS.TITLE}
            optionsData={options}
            defaultString={"No Selection"}
          />
        )}
      </PermissionWrapper>
    </div>
  );
}
