import {
  ContentCopy,
  ControlPointOutlined,
  EditOutlined,
  ExpandMore,
  Notes,
  // Print,
  PushPinOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  TextField,
  Tooltip,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import qs from "query-string";
import React, { useContext, useRef, useState } from "react";
// import { useReactToPrint } from "react-to-print";
import DataContext from "../../../../../../context/DataContext";
import "../../../../../../styles/notes.css";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import PrintableNotes from "./PrintableNotes";
import { useParams } from "react-router-dom";
import Colors from "../../../../../../styles/Colors";
import NoticeInternal from "./NoticeInternal";
import PermissionWrapper from "../../../../../global/PermissionWrapper";
import ResponseModal from "../../../../../global/ResponseModal";

export default function ClientNotes({ printable, caseObj, clientLawfirm }) {
  const { accessToken, trigger, setTrigger, casePk, userRoles } =
    useContext(DataContext);
  const [openError, setOpenError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [editNotes, setEditNotes] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newNote, setNewNote] = useState();
  const [updatedNote, setUpdatedNote] = useState();
  const [noteData, setNoteData] = useState();
  const [resLimit, setResLimit] = useState(25);
  const [noteSearch, setNoteSearch] = useState("");
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [caseNotes, setCaseNotes] = useState();
  const [caseNotesPk, setCaseNotesPk] = useState("");
  const [copiedAlert, setCopiedAlert] = useState(`Copy`);
  const [isNotePrivacySelected, setisNotePrivacySelected] = useState(false);
  const [internalOnly, setInternalOnly] = useState(false);

  const params = useParams();

  const { width } = useWindowDimensions();

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `Client Notes`,
  // });

  function handleNotesEdit(note) {
    setNoteData(note);
    setUpdatedNote(!note ? "" : note.content);
    setCaseNotesPk(note.id);
    setEditNotes(true);
  }

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: !noteSearch ? null : noteSearch,
      },
      {
        skipNull: true,
      }
    );

    if (!casePk) {
      return;
    }

    setTimeout(() => {
      axios
        .get(
          `/api/case_notes/?limit=${resLimit}&case__client=${params.id}&` +
            querytest,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
          setCaseNotes(data);
        })
        .catch((response) => {
          console.log(response);
        });
    }, "300");
  };

  useDebouncedEffect(
    () => sendRequest(),
    [
      accessToken,
      noteSearch,
      setNoteSearch,
      resLimit,
      trigger,
      setTrigger,
      casePk,
      params,
    ],
    250
  );

  const handleNotes = () => {
    let noteData = {
      case: casePk,
      content: newNote,
      ...(!userRoles.permissions.includes(
        "casemanager.edit_internal_only_status"
      )
        ? {}
        : { internal_only: internalOnly }),
    };

    axios
      .post(`/api/case_notes/`, noteData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setNewNote("");
          setOpenDialog(false);
          setisNotePrivacySelected(false);
        }
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(res[item]);
        }

        setErrorArray(errArr);
        setOpenError(!openError);
      });
  };

  const handlePin = (note) => {
    let pinnedBool = !note.pinned ? true : false;
    const noteData = {
      pinned: pinnedBool,
    };
    axios
      .patch(`/api/case_notes/${note.id}/`, noteData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setOpenDialog(false);
        }
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(res[item]);
        }

        setErrorArray(errArr);
        setOpenError(!openError);
      });
  };

  const handleCopyNotes = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedAlert(`Copied Notes`);
  };

  const handleUpdateNotes = () => {
    let patchedNotes = {
      content: updatedNote,
    };
    axios
      .patch(`/api/case_notes/${caseNotesPk}/`, patchedNotes, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setTrigger(!trigger);
          setEditNotes(false);
        }
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(res[item]);
        }

        setErrorArray(errArr);
        setOpenError(!openError);
      });
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  function onNotesPrivacySelect(selection) {
    setisNotePrivacySelected(true);
    setInternalOnly(selection);
  }
  function closeNewNote() {
    setOpenDialog(false);
    setisNotePrivacySelected(false);
    setInternalOnly(false);
  }
  function handleCloseResponseModal() {
    setTrigger(!trigger);
    setOpenSuccess(false);
  }
  let shadow = !userRoles.permissions.includes("call_log.view_calllog")
    ? "shadow-lg"
    : "";

  return (
    <>
      {printable ? (
        <div
          style={{
            border: "2px solid #9333EA",
            borderRadius: "5px",
            margin: "1rem auto",
            width: "90%",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <div
            className="m-2 notes"
            dangerouslySetInnerHTML={{ __html: caseNotes }}
          />
        </div>
      ) : (
        <div
          className={
            "rounded-md max-h-[78.5vh] overflow-auto w-[98%] mt-4 " + shadow
          }
        >
          <div className="px-3 flex justify-between flex-row-reverse border-black border-1 border-b mx-3">
            <div>
              {" "}
              {userRoles.permissions.includes("casemanager.add_casenotes") ? (
                <Tooltip title="Add Note">
                  <ControlPointOutlined
                    onClick={() => setOpenDialog(true)}
                    className="hover:bg-[#32CD30] hover:text-white text-[#32CD30] rounded-md"
                  />
                </Tooltip>
              ) : (
                ""
              )}
              {/* <Tooltip title="Print/Download PDF">
                <Print
                  onClick={() => handlePrint()}
                  className="hover:bg-[#9333EA] hover:text-white text-[#9333EA] rounded-md"
                />
              </Tooltip> */}
            </div>
            <div className="self-end rounded-t-md">
              <h2 className="w-full text-center py-1 text-lg flex items-center flex-row-reverse">
                <span className="block">Client Notes</span>{" "}
                <div className="block mr-1 mb-1">
                  <Notes />
                </div>
              </h2>
            </div>
          </div>
          <div className="relative pr-2">
            <div className="flex items-center mt-3 mx-3">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm"
                placeholder="Note Search"
                size="small"
                name="note-search"
                value={noteSearch}
                InputLabelProps={{ shrink: true }}
                label="Note Search"
                onChange={(event) => setNoteSearch(event.target.value)}
              />
            </div>
          </div>
          <div>
            {!caseNotes || caseNotes.length === 0 ? (
              <div className="text-center my-2">No notes</div>
            ) : (
              caseNotes.map((note, idx) => (
                <Accordion
                  key={note.id}
                  defaultExpanded={idx < 5 ? true : false}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: `${
                        note.internal_only ? Colors.yellow[100] : ""
                      }`,
                      ".MuiCollapse-wrapperInner": {
                        backgroundColor: `${
                          note.internal_only ? Colors.yellow[100] : ""
                        }`,
                      },
                    }}
                    expandIcon={
                      <div className="flex items-center">
                        <div
                          className={`w-[15px] h-[15px] rounded-full ${
                            !note.created_by
                              ? ""
                              : !note.created_by.entity
                              ? "bg-gray-300"
                              : note.created_by.entity.entity_type_label ===
                                "Client Case Management"
                              ? "bg-purple-300"
                              : "bg-yellow-300"
                          }`}
                        ></div>
                        <ExpandMore />
                      </div>
                    }
                    aria-controls={`panel${note.id}-content`}
                    id={`panel${note.id}-header`}
                  >
                    <div className={`self-end rounded-t-md`}>
                      <div className="w-full text-center py-1 text-lg flex items-center">
                        <div className="block text-sm font-bold">
                          <Tooltip title="Added/Updated By">
                            {!note.created_by ? "" : note.created_by.name}
                            &nbsp;&mdash;&nbsp;
                          </Tooltip>
                        </div>{" "}
                        <div className="block text-sm font-bold">
                          <div>
                            {new Date(
                              !note ? "" : note.created_at
                            ).toLocaleString("en-US")}{" "}
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="border-t border-gray-300 mx-2">
                    <div>
                      {!note.created_by ? (
                        ""
                      ) : (
                        <div className="flex float-right">
                          <PermissionWrapper
                            permission={
                              userRoles.permissions.includes(
                                "casemanager.change_casenotes"
                              ) && note.can_edit
                            }
                          >
                            <Tooltip title="Edit Client Notes">
                              <Button
                                className="hover:text-white hover:bg-sky-600 rounded-md float-right"
                                onClick={() => handleNotesEdit(note)}
                              >
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                          </PermissionWrapper>
                          <Tooltip title={copiedAlert}>
                            <Button
                              onMouseLeave={() => setCopiedAlert("Copy")}
                              className="hover:text-white hover:bg-sky-600 rounded-md float-right"
                              onClick={() =>
                                handleCopyNotes(
                                  removeTags(
                                    `Created by: ${note.created_by.name}` +
                                      ", " +
                                      `Created at: ${note.created_at}` +
                                      ", " +
                                      `Updated at: ${note.updated_at} ` +
                                      "|" +
                                      note.content
                                  )
                                )
                              }
                            >
                              <ContentCopy />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            title={note.pinned ? "Unpin Note" : "Pin Note"}
                          >
                            <Button
                              className={`rounded-md float-right ${
                                note.pinned
                                  ? "text-white hover:bg-red-600 bg-green-600"
                                  : "hover:text-white hover:bg-green-600 text-green-600"
                              }`}
                              onClick={() => handlePin(note)}
                            >
                              <PushPinOutlined />
                            </Button>
                          </Tooltip>
                        </div>
                      )}

                      <div
                        className="m-2 notes"
                        dangerouslySetInnerHTML={{ __html: note.content }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={25}
            />
          </div>
        </div>
      )}
      <Dialog open={openDialog} fullScreen={width < 640 ? true : false}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={() => closeNewNote()}>
              <CloseIcon />
            </Button>
          </div>
          {!isNotePrivacySelected &&
          userRoles.permissions.includes(
            "casemanager.edit_internal_only_status"
          ) ? (
            <div className="selectPrivacy">
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h1
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Please Select Note Type:
                </h1>
                <div
                  style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <Button
                      sx={{ margin: "20px" }}
                      variant="outlined"
                      onClick={() => onNotesPrivacySelect(true)}
                    >
                      Internal
                    </Button>
                  </div>
                  <div>
                    <Button
                      sx={{ margin: "20px" }}
                      className="m-20"
                      variant="outlined"
                      onClick={() => onNotesPrivacySelect(false)}
                    >
                      External
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="PrivacySelected">
              <h1
                style={{
                  fontSize: 20,
                  margin: "0 0 1rem 0",
                  textAlign: "center",
                  color: "#32CD30",
                  fontWeight: "bold",
                }}
              >
                Add Note
              </h1>
              {userRoles.permissions.includes(
                "casemanager.edit_internal_only_status"
              ) ? (
                <NoticeInternal
                  noteType={"New"}
                  isInternalOnly={internalOnly}
                  setisNotePrivacySelected={setisNotePrivacySelected}
                  backgroundColor={Colors.yellow[100]}
                  iconColor={Colors.orange[500]}
                />
              ) : (
                ""
              )}
              <Editor
                textareaName="content"
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                value={newNote}
                onEditorChange={(newText) => {
                  setNewNote(newText);
                }}
                init={{
                  menubar: false,
                  branding: false,
                  browser_spellcheck: true,
                  contextmenu: false,
                  plugins: ["lists"],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                }}
              />
              <br />
              <ModalButton
                action={handleNotes}
                baseColor={"#32CD30"}
                content={"Add Note"}
              />
            </div>
          )}
        </div>
      </Dialog>
      <Dialog open={editNotes} fullScreen={width < 640 ? true : false}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <Button onClick={() => setEditNotes(false)}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              margin: "0 0 1rem 0",
              textAlign: "center",
              color: "#FFA500",
              fontWeight: "bold",
            }}
          >
            Update Note
          </h1>
          {!noteData ? (
            ""
          ) : (
            <div>
              <p style={{ textAlign: "center" }}>
                <strong>Editing note by: </strong>
                {!noteData ? "" : noteData.created_by.name} <br />
                <strong>Last updated at: </strong>
                {new Date(!noteData ? "" : noteData.updated_at).toLocaleString(
                  "en-US"
                )}
              </p>
              {userRoles.permissions.includes(
                "casemanager.edit_internal_only_status"
              ) && noteData.internal_only ? (
                <NoticeInternal
                  noteType={"Edit"}
                  isInternalOnly={noteData.internal_only}
                  setisNotePrivacySelected={setisNotePrivacySelected}
                  backgroundColor={Colors.yellow[100]}
                  iconColor={Colors.orange[500]}
                />
              ) : (
                ""
              )}
              <Editor
                textareaName="content"
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                value={updatedNote}
                onEditorChange={(newText) => {
                  setUpdatedNote(newText);
                }}
                init={{
                  menubar: false,
                  branding: false,
                  browser_spellcheck: true,
                  contextmenu: false,
                  plugins: ["lists"],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                }}
              />
              <br />
              <ModalButton
                action={handleUpdateNotes}
                baseColor={"#FFA500"}
                content={"Update Note"}
              />
            </div>
          )}
        </div>
      </Dialog>
      <ResponseModal
        title="Case Note Added/Updated"
        description={"Successfully Added/Updated Case Note"}
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleCloseResponseModal}
      />
      <ResponseModal
        title="Error Updating Case Information"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
      <div className="hidden" ref={componentRef}>
        <PrintableNotes
          caseNotes={caseNotes}
          caseObj={caseObj}
          removeTags={removeTags}
          clientLawfirm={clientLawfirm}
        />
      </div>
    </>
  );
}
