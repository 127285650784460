import { Button, TextField, Tooltip } from "@mui/material";
import React from "react";

export default function InlineValueEdit({
  editVal,
  setEditVal,
  isIntake,
  title,
  inputVal,
  setInputVal,
  postFunc,
  formatter,
}) {
  return (
    <div className="flex w-fit my-2">
      {editVal === false ? (
        <Tooltip
          title={
            isIntake
              ? "You do not have permissions to edit this content"
              : `Click to edit ${title}`
          }
        >
          <span>
            <Button
              disabled={isIntake ? true : false}
              onClick={() => setEditVal(true)}
              className="cursor-pointer p-0"
            >
              <span className="font-bold text-sm">{title}:</span>
              <span className="text-sm">
                &nbsp;{!formatter ? inputVal : formatter.format(inputVal)}
              </span>
            </Button>
          </span>
        </Tooltip>
      ) : (
        <div className="flex">
          <TextField
            className="placeholder:text-center rounded-lg w-full text-sm"
            placeholder={title}
            size="small"
            name={title}
            value={inputVal}
            InputLabelProps={{ shrink: true }}
            label={title}
            onChange={(event) => setInputVal(event.target.value)}
          />
          <Button
            onClick={postFunc}
            className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md text-sm"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
