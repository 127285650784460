import { Button, IconButton, ListItem } from "@mui/material";
import React, { useContext, useState } from "react";
import HourWindowEditRow from "./HourWindowEditRow";
import { Edit } from "@mui/icons-material";
import DataContext from "../../../../../../../context/DataContext";

export default function HourDisplayRow({
  day,
  dayKey,
  defaultValues,
  provider,
  trigger,
  setTrigger,
  activeProvObj,
}) {
  const { userRoles, loggedInUser } = useContext(DataContext);

  const [toggleEdit, setToggleEdit] = useState(false);
  const [formValues, setFormValues] = useState(
    defaultValues.length === 0 ? [{ start_time: "", end_time: "" }] : []
  );

  const addFormFields = () => {
    setFormValues([...formValues, { start_time: "", end_time: "" }]);
  };

  const handleCancelEdit = () => {
    setFormValues([]);
    setToggleEdit(false);
  };

  return (
    <ListItem className="py-0 w-full">
      {!toggleEdit ? (
        <div className="flex items-center justify-between w-full border-b py-1">
          {" "}
          <div className="flex">
            <h2 className="font-bold">{day}</h2>
          </div>
          <div className="flex items-center">
            <div>
              {!defaultValues || defaultValues.length === 0
                ? "Closed"
                : defaultValues.map((item, idx) => (
                    <p key={idx}>
                      {new Date(
                        `2024-06-25T${item.start_time}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {new Date(
                        `2024-06-25T${item.end_time}`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  ))}
            </div>
            {loggedInUser.entity.entity_type_label === "Provider" &&
            provider !== activeProvObj[provider] ? (
              ""
            ) : userRoles.permissions.includes(
                "provider.add_providerofficehours"
              ) ||
              userRoles.permissions.includes(
                "provider.change_providerofficehours"
              ) ? (
              <IconButton onClick={() => setToggleEdit(true)}>
                <Edit />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="w-full mt-1 shadow-lg p-4 rounded-md mb-2">
          <div className="flex items-center justify-between w-full mx-2">
            {" "}
            <h2 className="font-bold">Edit time windows for {day}&nbsp;</h2>
            <Button
              size="small"
              className="text-red-600 border-red-600 hover:bg-red-600 hover:text-white hover:border-red-600"
              variant="outlined"
              onClick={() => handleCancelEdit()}
            >
              Done Editing
            </Button>
          </div>
          {!defaultValues ||
          defaultValues.length === 0 ||
          !userRoles.permissions.includes("provider.change_providerofficehours")
            ? ""
            : defaultValues.map((item) => (
                <HourWindowEditRow
                  activeWindow={item}
                  handleCancelEdit={handleCancelEdit}
                  setToggleEdit={setToggleEdit}
                  dayKey={dayKey}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  provider={provider}
                />
              ))}
          {/* //*Handles new value additions! */}
          {userRoles.permissions.includes("provider.add_providerofficehours")
            ? formValues.map((item, index) => (
                <HourWindowEditRow
                  index={index}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  setToggleEdit={setToggleEdit}
                  handleCancelEdit={handleCancelEdit}
                  dayKey={dayKey}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  provider={provider}
                />
              ))
            : ""}
          <Button onClick={() => addFormFields()}>New hour window</Button>
        </div>
      )}
    </ListItem>
  );
}
