import { Circle, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

export default function AccordionShell({
  title,
  customAdornment,
  children,
  defaultExpanded,
}) {
  return (
    <Accordion
      className="w-full mr-2 relative z-10 bg-white"
      defaultExpanded={!defaultExpanded ? false : defaultExpanded}
    >
      <AccordionSummary
        expandIcon={
          <div className="flex items-center">
            {customAdornment ? (
              <Circle className={`text-${customAdornment}-300`} />
            ) : (
              ""
            )}
            <ExpandMore />
          </div>
        }
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
