import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ProfileInfo from "../ProfileInfo";
import OrgPermissions from "../OrgPermissions";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import Toolbar from "./Toolbar";

export default function UserProfile({
  selectedUser,
  isDrawer,
  reloadTrigger,
  setReloadTrigger,
}) {
  const params = useParams();
  const { accessToken, userRoles } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState("");
  //testing red user pk 1290

  useEffect(() => {
    if (!params.id && !userInfo) {
      setUserInfo(selectedUser);
    } else {
      setLoading(true);
      axios
        .get(`/api/users/${params.id || userInfo.pk}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setUserInfo(data);
          setLoading(false);
          console.log(data);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
        });
    }
  }, [
    accessToken,
    setUserInfo,
    trigger,
    setLoading,
    setErrorMessage,
    params,
    selectedUser,
  ]);

  return (
    <div className="h-full">
      {loading ? (
        <LoadingIndicator isLoading={loading} />
      ) : !userInfo && errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <div className="w-full relative h-full">
          <div
            className={`xl:flex w-full h-full ${
              isDrawer ? "xl:flex-col" : "xl:flex-row"
            }`}
          >
            <ProfileInfo
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              profile={true}
              isDrawer={isDrawer}
              reloadTrigger={reloadTrigger}
              setReloadTrigger={setReloadTrigger}
            />
            <div
              className={`xl:h-full overflow-y-auto ${
                isDrawer ? "xl:w-full" : "xl:w-3/4"
              }`}
            >
              {userRoles.permissions.includes("users.change_user") ||
              userRoles.permissions.includes("users.delete_user") ? (
                <Toolbar
                  userInfo={userInfo}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  reloadTrigger={reloadTrigger}
                  setReloadTrigger={setReloadTrigger}
                  isDrawer={isDrawer}
                />
              ) : (
                ""
              )}
              <OrgPermissions
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                profile={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
