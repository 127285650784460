import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function ModalShell({
  open,
  setOpen,
  customCloseFunc,
  color,
  title,
  customWidth,
  children,
}) {
  const { width } = useWindowDimensions();
  return (
    <Dialog
      open={open}
      fullScreen={width > 765 ? false : true}
      maxWidth={customWidth ? customWidth : "lg"}
      // fullWidth={!customWidth ? true : false}
    >
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          <IconButton
            onClick={() =>
              !customCloseFunc ? setOpen(false) : customCloseFunc()
            }
          >
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            color: color,
            margin: "0 0 1rem 0",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {title}
        </h1>
        {children}
      </div>
    </Dialog>
  );
}
