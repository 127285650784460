import { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItemButton,
  InputAdornment,
} from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import ModalButton from "../../../../../global/ModalButton";
import { Info } from "@mui/icons-material";
import ResponseModal from "../../../../../global/ResponseModal";

export default function EditApptProcedurev2({ selectedBill, setOpenEditBill }) {
  const { trigger, setTrigger, selectedClient, accessToken } =
    useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [price, setPrice] = useState(selectedBill.price);
  const [signature, setSignature] = useState(selectedBill.signature);
  const [procedures, getProcedures] = useState([]);
  const [service, setService] = useState(
    !selectedBill.service ? null : selectedBill.service.pk
  );
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [filter, setFilterType] = useState("service_name");
  const [cquery, setCQuery] = useState("");
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleFilter = (event) => {
    const { value } = event.target;
    setFilterType(value);
  };

  const populateServiceInfo = (procedure) => {
    setService(procedure.pk);
    setCQuery(procedure.service_name);
    getProcedures([]);
  };

  const setQuery = (e) => {
    setCQuery(e.target.value);
    setService(null);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenEditBill(false);
    setService();
    setPrice("");
    setSignature("");
  };

  const handleApptProcedureEdit = () => {
    let patchedProcedure = !service
      ? {
          ...(price === "" ? {} : { price: price }),
          ...(signature === "" ? {} : { signature: signature }),
        }
      : {
          ...(service === "" ? {} : { service: service }),
          ...(price === "" ? {} : { price: price }),
          ...(signature === "" ? {} : { signature: signature }),
        };

    axios
      .patch(`/api/administered/${selectedBill.pk}/`, patchedProcedure, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setTrigger(!trigger);
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.service_code) {
      arrayVals.push(`procedure code: ${errorArray.service_code}`);
    }
    if (errorArray.service_name) {
      arrayVals.push(`procedure code: ${errorArray.service_name}`);
    }
    if (errorArray.price) {
      arrayVals.push(`price: ${errorArray.price}`);
    }
    if (errorArray.signature) {
      arrayVals.push(`approved by: ${errorArray.signature}`);
    }
    if (errorArray.service) {
      for (const [key, value] of Object.entries(errorArray.case)) {
        arrayVals.push(`${key}: ${value}`);
      }
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  // calling all services for provider
  useEffect(() => {
    axios
      .get(
        `/api/services/?provider=${selectedBill.administered_by.pk}&simplified=true`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((proceduresList) => {
        getProcedures(proceduresList.data.results);
      });
  }, [selectedClient, accessToken, selectedBill]);

  return (
    <div className="w-full px-20 flex-vertical justify-center bg-white">
      <div>
        {!selectedBill ? (
          "Please Select a Bill to Continue"
        ) : (
          <div className="grid grid-cols-2 bg-gray-200 mt-4 rounded-md">
            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Appointment Info
              </span>
              <div className="grid grid-cols-2 mt-4">
                <span style={{ fontWeight: "bold" }}>Provider Name:</span>{" "}
                <span>{selectedBill.administered_by.name}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Provider Phone:</span>{" "}
                <span>{selectedBill.administered_by.phone}</span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>
                  Appointment Date/Time:
                </span>{" "}
                <span>
                  {selectedBill.appointment.scheduled
                    ? new Date(
                        selectedBill.appointment.scheduled
                      ).toLocaleString()
                    : ""}{" "}
                </span>
              </div>
              <div className="grid grid-cols-2">
                <span style={{ fontWeight: "bold" }}>Appointment Notes:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedBill.appointment.notes,
                  }}
                ></span>
              </div>
            </div>

            <div className="my-2 grid grid-rows-2">
              <div>
                <span
                  className="underline text-lg"
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Client Info
                </span>
                <div className="grid grid-cols-2 mt-4">
                  <span style={{ fontWeight: "bold" }}>Client Name: </span>
                  <span>{selectedBill.administered_to.name}</span>
                </div>
                <div className="grid grid-cols-2">
                  <span style={{ fontWeight: "bold" }}>Client Email: </span>
                  <span>{selectedBill.administered_to.email}</span>
                </div>
                <div className="grid grid-cols-2">
                  <span style={{ fontWeight: "bold" }}>Client Phone: </span>
                  <span>{selectedBill.administered_to.phone}</span>
                </div>
              </div>

              <div>
                <span
                  className="underline text-lg"
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Bill Info
                </span>
                <div className="grid grid-cols-2">
                  <span style={{ fontWeight: "bold" }}>Bill Name: </span>
                  <span>
                    {!selectedBill.service
                      ? "No Service"
                      : selectedBill.service.service_name}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span style={{ fontWeight: "bold" }}>Bill Code: </span>
                  <span>
                    {!selectedBill.service
                      ? "No Service"
                      : selectedBill.service.service_code}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span style={{ fontWeight: "bold" }}>Bill Price: </span>
                  <span>{formatter.format(selectedBill.price)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <span
          className="underline text-lg font-bold"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            margin: "10px 0",
            display: "block",
            textAlign: "center",
            color: "#9333ea",
          }}
        >
          Edit Bill Details
        </span>
      </div>
      <div>
        <FormControl className="grid grid-cols-2" style={{ width: "100%" }}>
          <div className="w-full grid" style={{ margin: "1rem 0" }}>
            <InputLabel id="PF">Filter</InputLabel>
            <Select
              labelId="PF"
              id="PF"
              size="small"
              value={filter}
              label="PF"
              onChange={handleFilter}
            >
              <MenuItem value="service_name">Bill Name</MenuItem>
              <MenuItem value="service_code">Bill Code</MenuItem>
            </Select>
          </div>

          <div className="w-full grid">
            <TextField
              className="placeholder:text-center rounded-lg"
              placeholder="Search Procedures" //{`Search ${filter}`}
              label="Selected Bill"
              value={cquery}
              onChange={(event) => setQuery(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </FormControl>
        <p
          style={{
            fontSize: "10px",
            color: "gray",
            textAlign: "left",
            margin: "0 0 10px 0",
          }}
        >
          <Info style={{ fontSize: "14px" }} /> If there are no related Services
          or CPT code, leave this input blank
        </p>
      </div>

      <div>
        {!cquery ? (
          ""
        ) : (
          <div className="">
            {procedures.length !== 0 ? (
              <div className="w-full h-full p-0 mb-2">
                {procedures
                  .filter((procedure) => {
                    if (cquery === "") {
                      return procedure;
                    } else if (
                      procedure[filter]
                        .toLocaleLowerCase()
                        .includes(cquery.toLocaleLowerCase())
                    ) {
                      return procedures;
                    }
                    return false;
                  })
                  .map((procedure, index) => (
                    <List key={index} className="p-0 my-1 w-full">
                      <div className="">
                        <ListItemButton
                          onClick={(e) => populateServiceInfo(procedure)}
                          className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                        >
                          <span>{procedure.service_name}</span>
                          <span className="ml-6">{procedure.service_code}</span>
                        </ListItemButton>
                      </div>
                    </List>
                  ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <br />
      <TextField
        className="w-full my-4 text-center"
        id="Service Bill Total"
        label="Procedure Amount"
        value={price}
        onChange={(event) => {
          setPrice(event.target.value);
        }}
      />

      <br />
      <br />
      <TextField
        className="w-full my-4 text-center"
        id="Signature"
        label="Signature"
        value={signature}
        onChange={(event) => {
          setSignature(event.target.value);
        }}
      />

      <br />
      <br />
      <h2 className="font-bold text-lg">
        <span className="text-xl text-red-800 underline">Warning:</span>{" "}
        Clicking the "Update Bill Details" button below will permanently rewrite
        over the current bill data, after proceeding previous data will be
        unrecoverable. Please make sure you want the above changes to take
        effect before proceeding.
      </h2>
      <br />
      <br />

      <ModalButton
        action={handleApptProcedureEdit}
        baseColor={"#9333ea"}
        content={"Update Bill Details"}
        icon={"edit"}
      />
      <ResponseModal
        title="Bill Data"
        description="Successfully updated the bill in client's data."
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Updating Bill"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
