import React, { useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
} from "@mui/material";
import ShowMoreButton from "./ShowMoreButton";

export default function MultiSelectFilterV2({
  title,
  responseArray,
  selectedItems,
  showMoreVisible,
  resLimit,
  setResLimit,
  setActiveItems,
  activeItems,
  setSelectedItems,
  nameKey,
  valueKey,
  resetPagination,
}) {
  const [showForm, setShowForm] = useState(false);

  const handleSelectedItem = (event, info) => {
    let compVar =
      typeof event.target.name === "string"
        ? event.target.name
        : +event.target.name;
    if (selectedItems.includes(compVar)) {
      let activeItemName = info[nameKey];
      setActiveItems(activeItems.filter((e) => e !== activeItemName));
      setSelectedItems(selectedItems.filter((e) => e !== compVar));
    } else {
      setActiveItems([...activeItems, info[nameKey]]);
      setSelectedItems([...selectedItems, compVar]);
    }
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <Button
          onClick={() => setShowForm(!showForm)}
          className="text-left normal-case text-[15px] p-0"
        >
          <span className="text-black">{title}</span>
          {!showForm ? (
            <Add className="text-sky-600" />
          ) : (
            <Remove className="text-sky-600" />
          )}
        </Button>
      </div>
      {!showForm ? (
        ""
      ) : (
        <>
          {" "}
          <FormControl
            sx={{ m: 1 }}
            className="max-h-[20vh] overflow-y-auto  w-[100%]"
          >
            <List className="w-[100%]">
              {!responseArray
                ? ""
                : responseArray.map((item) => (
                    <ListItem key={item[valueKey]} className="m-0 p-0">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !selectedItems
                                ? false
                                : selectedItems.includes(item[valueKey])
                                ? true
                                : false
                            }
                            className="text-sm"
                            onClick={(event) => handleSelectedItem(event, item)}
                            name={`${item[valueKey]}`}
                          />
                        }
                        label={item[nameKey]}
                      />

                      <div
                        className={`w-[15px] h-[15px] rounded-full ${
                          !item.client && item.works_for
                            ? item.works_for === "law_firm"
                              ? "bg-yellow-400"
                              : "bg-purple-600"
                            : ""
                        }`}
                      ></div>
                    </ListItem>
                  ))}
            </List>
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={25}
            />
          </FormControl>
        </>
      )}
    </div>
  );
}
